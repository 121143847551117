import React, {useState, useEffect} from 'react'
import { Row, Col } from 'antd'
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack'
import QueueAnim from 'rc-queue-anim'
import TweenOne from 'rc-tween-one'
import SmallArrows from '@/assets/images/small_arrows.png'
import Client1 from '@/assets/images/home/client1.jpg'
import Client2 from '@/assets/images/home/client2.jpg'
import Client3 from '@/assets/images/home/client3.jpg'
import {getAnim} from "@/utils";
import { useNavigate } from 'react-router-dom'


type DataType =  {name: string, title: string, desc: string, url: string}

const Consult: React.FC = () => {
  const [data, setData] = useState<DataType[]>([
    {
      name: '企业性质：某大型民营集团化物业公司',
      title: '“智臻未来”关键人才培养训练营',
      desc: '锚定新生关键人才，打造职业发展助力器',
      url: Client1
    },
    {
      name: '企业性质：北京某大型国有黄金珠宝专营公司',
      title: '“金色传承·希望之星”高管继任',
      desc: '提升经营视野，建设继任梯队，加深人才厚度',
      url: Client2
    },
    {
      name: '企业性质：国有清洁能源上市企业',
      title: '“鸿鹄”核心人才项目',
      desc: '树立创新管理新格局，实现管理干部能力再造',
      url: Client3
    }
  ])
  const [item, setItem] = useState<DataType>()
  const [idx, setIdx] = useState(0)
  const [flag, setFlag] = useState(true)
  const navigate = useNavigate();

  useEffect(() => {
    setItem(data[0])
  }, [])
  const imgAnim: any = getAnim()

  const handleMouseEnter = (item, index) => {
    setIdx(index)
    setItem(item)
    if(idx != index) {
      setFlag(true)
    }
  }

  const handleMouseLeave = () => {
    setFlag(false)
  }

  const handlePage = (path) => {
    navigate(path)
  }

  return (
    <div className='wrap-consult'>
      <div className='home-page'>
        <div key="title" className='title'>
          客户案例
        </div>
        <OverPack playScale={0.3} component={Row} className='wrap-consult-wrap'>
          <QueueAnim
            key="text"
            type="left"
            leaveReverse
            ease={['easeOutQuad', 'easeInQuad']}
            component={Col}
            md={11}
          >
            <QueueAnim
              component="ul"
              key="ul"
              type="left"
              ease="easeOutQuad"
            >
              {
                data.map((item, i) => (
                  <div key={i}
                       className={`wrap-consult-block ${i === idx && 'wrap-consult-active'}`}
                       onMouseEnter={() => handleMouseEnter(item, i)}
                       onMouseLeave={handleMouseLeave}>
                    <div className='consult-name'>{item?.name}</div>
                    <div className='consult-title'>{item?.title}</div>
                    <div className='consult-desc'>{item?.desc}</div>
                    {i === idx && <img className='arrows-img' src={SmallArrows} alt="" onClick={() => handlePage(`/case/${i}`)}/>}
                  </div>
                ))
              }
            </QueueAnim>
          </QueueAnim>
          <TweenOne
            key="img"
            animation={imgAnim}
            resetStyle
            component={Col}
            md={12}
            style={{display: "flex", alignItems: "center"}}
          >
            <img className={`img-c ${flag ? 'img-fade' : ''}`} src={item?.url}  width="100%" alt="" />
            <div>
              {
                data.map((item, i) => (
                  <div className={`case ${i === idx ? 'case-active' : ''}`}></div>
                ))
              }
            </div>
          </TweenOne>
        </OverPack>
      </div>
    </div>
  )
}

export default Consult