import React from 'react'
import { Row, Col } from 'antd'
import BannerLeft from '@/assets/images/about/banner-left.png'
import BannerCenter from '@/assets/images/about/banner-center.png'
import BannerRight from '@/assets/images/about/banner-right.png'

const Banner: React.FC = () => {
  return (
    <Row gutter={10} className='warp-join-banner'>
      <Col md={11}>
        <img className='banner-left' src={BannerLeft} alt="" />
      </Col>
      <Col md={7}>
        <img className='banner-center' src={BannerCenter} alt="" />
      </Col>
      <Col md={6}>
        <img className='banner-right' src={BannerRight} alt="" />
      </Col>
    </Row>
  )
}
export default Banner