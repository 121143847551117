import React from 'react'
import { StudyTabs, TabPane} from '@/components/StudyTabs'
import StudyList from './StudyList'
import ThemeList from './ThemeList'
import LeagueList from './LeagueList'
import {useParams} from "react-router-dom";


const Study: React.FC = () => {
  const { id } = useParams()
  return (
    <main className='wrap-study header-top'>
      <StudyTabs activeKey={id}>
        <TabPane title="红色研学" key="1">
          <StudyList />
        </TabPane>
        <TabPane title="主题研学" key="2">
          <ThemeList />
        </TabPane>
        <TabPane title="团建拓展" key="3">
          <LeagueList />
        </TabPane>
      </StudyTabs>
    </main>
  )
}

export default Study