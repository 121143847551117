import React from 'react'
import Banner01 from './Banner01'
import Banner02 from "./Banner02";
import Partner from './Partner'
import Challenge from './Challenge'
import Merit from './Merit'
import DrillCol from './DrillCol'
import DrillRow from './DrillRow'
import Tutor from './Tutor'
import DrillTutor from './DrillTutor'
import Invite from './Invite'


const Product: React.FC = () => {

  return (
    <main className='wrap-product-mark header-top'>
      <Banner01 />
      <Partner />
      <Challenge />
      <Banner02 />
      <Merit />
      {/*无图*/}
      <DrillCol />
      {/*有图*/}
      {/*<DrillRow />*/}
      <Tutor />
      <DrillTutor />
      <Invite />
    </main>
  )
}

export default Product