import React, {useState} from 'react'
import { XcTabs, TabPane} from '@/components/XcTabs'
import List from './List'
import Data from '@/utils/dynamic'

const News: React.FC = () => {
  const [data, setData] = useState(Data)
  return (
    <main className='warp-news header-top'>
      <div className='warp-news-banner'>
        <div className='title'>新闻中心</div>
      </div>
      <div className='warp-news-tabs home-page'>
        <XcTabs activeKey='1'>
          <TabPane title="公司动态" key="1">
            <List data={data} />
          </TabPane>
          {/*<TabPane title="行业资讯" key="2">*/}
          {/*  <List data={data} />*/}
          {/*</TabPane>*/}
        </XcTabs>
      </div>
    </main>
  )
}

export default News