import React, { useState } from 'react'
import {Col} from 'antd'

type IProps = {
  changeData: (item) => void
  idx: string | undefined | number
}

const CaseRight: React.FC<IProps> = (props) => {

  const [activeIdx, setActiveIds] = useState(props.idx)

  const handleItem = (item, index) => {
    setActiveIds(index)
    props.changeData(item)
  }

  return (
    <Col className='case-right'>
      <div className='title'>全部案例</div>
      <div className='line'></div>
      <div>
        {
          props.data.map((item, index) => (
            <div className='desc-block' onClick={() => handleItem(item, index)}>
              <div className={`circle ${activeIdx == index ? 'circle-active' : ''}`}></div>
              <div className={`title-desc  ${activeIdx == index ? 'title-desc-active' : ''}`}>{item.name}</div>
            </div>
          ))
        }
      </div>
    </Col>
  )
}

export default CaseRight