import React from 'react'
import { Row, Col } from 'antd'
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack'
import QueueAnim from 'rc-queue-anim'
import TweenOne from 'rc-tween-one'
import Bg from '@/assets/images/product/enterprise/bg.png'
import IconArrow from '@/assets/images/product/enterprise/icon-arrow.png'
import IconBook from '@/assets/images/product/enterprise/icon-book.png'
import EnterpriseLogo from '@/assets/images/product/enterprise/enterprise-logo.png'
import OrganizeBg from '@/assets/images/product/enterprise/organize-bg.png'
import TacticBg from '@/assets/images/product/enterprise/tactic-bg.png'
import TalentsBg from '@/assets/images/product/enterprise/talents-bg.png'
import {getAnim} from "@/utils";

const Enterprise: React.FC = () => {

  const imgAnim: any = getAnim()

  return (
    <main className='wrap-enterprise header-top'>
      <div className='wrap-enterprise-header'>
        <img src={Bg} />
      </div>
      <Row className='wrap-enterprise-branding home-page'>
        <Col md={16}>
          <div className='title'>
            光合企学产品全景图
          </div>
          <div className='desc'>
            光合企学是熙诚教育投资旗下专注于企业学的品牌，致力于向企业提供前沿和创新课程，并通过顾问协作的模式，培训与顾问服务相结合深度参与到企业的业务和管理中，不断助力企业达全景基于“战略-组织- 人才”进行设计，以咨询到“生态领域”的组织效能。光合企学的产占与培训的方式帮助企业走向发展快车道。同时光合企学旗下两大特色产品“红色研学”与“标杆参访”为企业提供更具针对性的需求服务。
          </div>
        </Col>
        <Col>
          <img src={EnterpriseLogo} alt=""/>
        </Col>
      </Row>
      <div className='wrap-enterprise-box'>
        <OverPack playScale={0.3} component={Row} className='enterprise-page wrap-enterprise-box-row'>
          <QueueAnim
            key="text"
            type="left"
            leaveReverse
            ease={['easeOutQuad', 'easeInQuad']}
            component={Col}
          >
            <QueueAnim
              component="ul"
              key="ul"
              type="left"
              ease="easeOutQuad"
            >
              <div className='box-title'>战略层</div>
              <div className='box-desc'>战略规划 / 公司治理</div>
              <div className='box-btn flex-center'>
                <span className='box-btn-text'>企业上市辅导咨询</span>
                <img src={IconArrow} alt=""/>
              </div>
              <div className='box-btn flex-center'>
                <span className='box-btn-text'>战略咨询</span>
                <img src={IconArrow} alt=""/>
              </div>
              <div className='box-btn flex-center'>
                <span className='box-btn-text'>数字化转型咨询</span>
                <img src={IconArrow} alt=""/>
              </div>
            </QueueAnim>
          </QueueAnim>
          <TweenOne
            key="img"
            animation={imgAnim}
            resetStyle
            component={Col}
          >
            <img className='img-bg' src={TacticBg} width={'600px'} height={'223px'}/>
          </TweenOne>
        </OverPack>
      </div>
      <div className='wrap-enterprise-box'>
        <OverPack playScale={0.3} component={Row} className='enterprise-page wrap-enterprise-box-row'>
          <QueueAnim
            key="text"
            type="left"
            leaveReverse
            ease={['easeOutQuad', 'easeInQuad']}
            component={Col}
          >
            <QueueAnim
              component="ul"
              key="ul"
              type="left"
              ease="easeOutQuad"
            >
              <div className='box-title'>组织层</div>
              <div className='box-desc'>组织诊断 / 文化分析</div>
              <div className='box-btn flex-center'>
                <span className='box-btn-text'>组织管理咨询</span>
                <img src={IconArrow} alt=""/>
              </div>
              <div className='box-btn flex-center'>
                <span className='box-btn-text'>企业文化咨询</span>
                <img src={IconArrow} alt=""/>
              </div>
              <div className='box-btn flex-center'>
                <span className='box-btn-text'>组织进化工作坊</span>
                <img src={IconArrow} alt=""/>
              </div>
              <div className='box-btn flex-center'>
                <span className='box-btn-text'>价值观共创工作坊</span>
                <img src={IconArrow} alt=""/>
              </div>
              <div className='box-btn flex-center'>
                <span className='box-btn-text'>绩效改进工作坊</span>
                <img src={IconArrow} alt=""/>
              </div>
            </QueueAnim>
          </QueueAnim>
          <TweenOne
            key="img"
            animation={imgAnim}
            resetStyle
            component={Col}
          >
            <img className='img-bg' src={OrganizeBg} width={'402px'} height={'317px'} style={{marginRight: '99px'}}/>
          </TweenOne>
        </OverPack>
      </div>
      <div className='wrap-enterprise-box'>
        <OverPack playScale={0.3} component={Row} className='enterprise-page wrap-enterprise-box-row'>
          <QueueAnim
            key="text"
            type="left"
            leaveReverse
            ease={['easeOutQuad', 'easeInQuad']}
            component={Col}
          >
            <QueueAnim
              component="ul"
              key="ul"
              type="left"
              ease="easeOutQuad"
            >
              <div className='box-title'>人才层</div>
              <div className='box-desc'>人才战略 / 人才管理</div>
              <div className='box-btn flex-center'>
                <span className='box-btn-text'>领导力培养项目</span>
                <img src={IconArrow} alt=""/>
              </div>
              <div className='box-btn flex-center'>
                <span className='box-btn-text'>通用力培养</span>
                <img src={IconArrow} alt=""/>
              </div>
              <div className='box-btn flex-center'>
                <span className='box-btn-text'>专业力培养项目</span>
                <img src={IconArrow} alt=""/>
              </div>
              <div className='box-btn flex-center'>
                <span className='box-btn-text'>学习地图咨询</span>
                <img src={IconArrow} alt=""/>
              </div>
              <div className='box-btn flex-center'>
                <span className='box-btn-text'>人才管理咨询</span>
                <img src={IconArrow} alt=""/>
              </div>
              <div className='box-btn flex-center'>
                <span className='box-btn-text'>经验萃取/课程开发项目</span>
                <img src={IconArrow} alt=""/>
              </div>
            </QueueAnim>
          </QueueAnim>
          <TweenOne
            key="img"
            animation={imgAnim}
            resetStyle
            component={Col}
          >
            <img className='img-bg' src={TalentsBg} width={'592px'} height={'343px'} />
          </TweenOne>
        </OverPack>
      </div>
      <div className='wrap-enterprise-box wrap-enterprise-feature'>
        <OverPack playScale={0.3} component={Row} className='enterprise-page02 wrap-enterprise-box-row'>
          <QueueAnim
            key="text"
            type="left"
            leaveReverse
            ease={['easeOutQuad', 'easeInQuad']}
            component={Col}
          >
            <QueueAnim
              component="ul"
              key="ul"
              type="left"
              ease="easeOutQuad"
            >
              <div className='box-title m-t8'>特色产品</div>
            </QueueAnim>
          </QueueAnim>
          <TweenOne
            key="block"
            animation={imgAnim}
            resetStyle
            component={Col}
          >
            <Row gutter={27}>
              <Col>
                <div className='block-summary'>
                  <div className='block-summary-title'>红色研学</div>
                  <div className='block-summary-line'></div>
                  <div className='block-summary-text'>
                    红色基地研学 <br />
                    红色团建拓展
                  </div>
                </div>
              </Col>
              <Col>
                <div className='block-summary'>
                  <div className='block-summary-title'>标杆参访</div>
                  <div className='block-summary-line'></div>
                  <div className='block-summary-text'>
                    企业参访研学 <br />
                    高管现地研学
                  </div>
                </div>
              </Col>
            </Row>
          </TweenOne>
        </OverPack>
      </div>
      <div className='wrap-enterprise-system home-page'>
        <div className='title'>光合企学课程体系</div>
        <OverPack playScale={0.3}>
          <QueueAnim
            key="text"
            type="bottom"
            leaveReverse
            ease={['easeOutQuad', 'easeInQuad']}
            component={Row}
            gutter={[13]}
            className='enterprise-blocks'
          >
            <Col md={6} key='1'>
              <div className='enterprise-block'>
                <img src={IconBook} alt=""/>
                <div className='enterprise-block-title'>战略与商业模式</div>
                <div className='enterprise-block-desc'>
                  <div className='dot'></div>
                  <div className='text'>
                    商业模式设计
                  </div>
                </div>
                <div className='enterprise-block-desc'>
                  <div className='dot'></div>
                  <div className='text'>
                    竞对及市场分析
                  </div>
                </div>
                <div className='enterprise-block-desc'>
                  <div className='dot'></div>
                  <div className='text'>
                    战略规划
                  </div>
                </div>
                <div className='enterprise-block-desc'>
                  <div className='dot'></div>
                  <div className='text'>
                    战略解码
                  </div>
                </div>
                <div className='enterprise-block-desc'>
                  <div className='dot'></div>
                  <div className='text'>
                    战略执行
                  </div>
                </div>
                <div className='enterprise-block-desc'>
                  <div className='dot'></div>
                  <div className='text'>
                    战略复盘
                  </div>
                </div>
              </div>
            </Col>
            <Col md={6} key='2'>
              <div className='enterprise-block enterprise-double'>
                <img src={IconBook} alt=""/>
                <div className='enterprise-block-title'>数字化转型</div>
                <div className='enterprise-block-desc'>
                  <div className='dot'></div>
                  <div className='text'>
                    数字化思维
                  </div>
                </div>
                <div className='enterprise-block-desc'>
                  <div className='dot'></div>
                  <div className='text'>
                    数字化转型思路
                  </div>
                </div>
                <div className='enterprise-block-desc'>
                  <div className='dot'></div>
                  <div className='text'>
                    数字化产品
                  </div>
                </div>
                <div className='enterprise-block-desc'>
                  <div className='dot'></div>
                  <div className='text'>
                    数字化营销
                  </div>
                </div>
                <div className='enterprise-block-desc'>
                  <div className='dot'></div>
                  <div className='text'>
                    智能制造
                  </div>
                </div>
                <div className='enterprise-block-desc'>
                  <div className='dot'></div>
                  <div className='text'>
                    行业变革
                  </div>
                </div>
              </div>
            </Col>
            <Col md={6} key='3'>
              <div className='enterprise-block'>
                <img src={IconBook} alt=""/>
                <div className='enterprise-block-title'>营销</div>
                <div className='enterprise-block-desc'>
                  <div className='dot'></div>
                  <div className='text'>
                    品牌营销
                  </div>
                </div>
                <div className='enterprise-block-desc'>
                  <div className='dot'></div>
                  <div className='text'>
                    私域营销
                  </div>
                </div>
                <div className='enterprise-block-desc'>
                  <div className='dot'></div>
                  <div className='text'>
                    营销策略
                  </div>
                </div>
                <div className='enterprise-block-desc'>
                  <div className='dot'></div>
                  <div className='text'>
                    营销活动设计
                  </div>
                </div>
              </div>
            </Col>
            <Col md={6} key='4'>
              <div className='enterprise-block enterprise-double'>
                <img src={IconBook} alt=""/>
                <div className='enterprise-block-title'>产品与用户体验</div>
                <div className='enterprise-block-desc'>
                  <div className='dot'></div>
                  <div className='text'>
                    需求调研与分析
                  </div>
                </div>
                <div className='enterprise-block-desc'>
                  <div className='dot'></div>
                  <div className='text'>
                    产品设计与创新
                  </div>
                </div>
                <div className='enterprise-block-desc'>
                  <div className='dot'></div>
                  <div className='text'>
                    产品研发
                  </div>
                </div>
                <div className='enterprise-block-desc'>
                  <div className='dot'></div>
                  <div className='text'>
                    产品运营
                  </div>
                </div>
                <div className='enterprise-block-desc'>
                  <div className='dot'></div>
                  <div className='text'>
                    产品管理
                  </div>
                </div>
                <div className='enterprise-block-desc'>
                  <div className='dot'></div>
                  <div className='text'>
                    用户体验
                  </div>
                </div>
              </div>
            </Col>
          </QueueAnim>
        </OverPack>
        <OverPack playScale={0.3} className='m-t20'>
          <QueueAnim
            key="blocks"
            type="bottom"
            leaveReverse
            ease={['easeOutQuad', 'easeInQuad']}
          >
            <div className='enterprise-blocks02' key='blocks02'>
              <div className='enterprise-blocks02-header'>
                <img src={IconBook} alt=""/>
                <div className='enterprise-blocks02-header-title'>战略与商业模式</div>
              </div>
              <Row gutter={76}>
                <Col md={6}>
                  <div className='enterprise-block-desc'>
                    <div className='dot'></div>
                    <div className='text'>
                      经营与运营管理
                    </div>
                  </div>
                  <div className='enterprise-block-desc'>
                    <div className='dot'></div>
                    <div className='text'>
                      客户服务管理
                    </div>
                  </div>
                  <div className='enterprise-block-desc'>
                    <div className='dot'></div>
                    <div className='text'>
                      经销商管理
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <div className='enterprise-block-desc'>
                    <div className='dot'></div>
                    <div className='text'>
                      创新与变革管理
                    </div>
                  </div>
                  <div className='enterprise-block-desc'>
                    <div className='dot'></div>
                    <div className='text'>
                      公司治理
                    </div>
                  </div>
                  <div className='enterprise-block-desc'>
                    <div className='dot'></div>
                    <div className='text'>
                      资本运作
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <div className='enterprise-block-desc'>
                    <div className='dot'></div>
                    <div className='text'>
                      风险内控
                    </div>
                  </div>
                  <div className='enterprise-block-desc'>
                    <div className='dot'></div>
                    <div className='text'>
                      供应链 & 物流
                    </div>
                  </div>
                  <div className='enterprise-block-desc'>
                    <div className='dot'></div>
                    <div className='text'>
                      项目管理
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <div className='enterprise-block-desc'>
                    <div className='dot'></div>
                    <div className='text'>
                      组织与人才发展
                    </div>
                  </div>
                  <div className='enterprise-block-desc'>
                    <div className='dot'></div>
                    <div className='text'>
                      企业文化
                    </div>
                  </div>
                  <div className='enterprise-block-desc'>
                    <div className='dot'></div>
                    <div className='text'>
                      绩效管理与改进
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </QueueAnim>
        </OverPack>
        <OverPack playScale={0.3} component={Row} gutter={[13]} className='m-t20'>
          <QueueAnim
            key="text"
            type="left"
            leaveReverse
            ease={['easeOutQuad', 'easeInQuad']}
            component={Col}
            md={12}
          >
            <QueueAnim
              component="ul"
              key="ul"
              type="left"
              ease="easeOutQuad"
            >
              <div className='enterprise-box-left'>
                <div className='enterprise-box-header'>
                  <img src={IconBook} alt=""/>
                  <div className='enterprise-box-header-title'>专业能力培养</div>
                </div>
                <Row gutter={74}>
                  <Col md={12}>
                    <div className='left-title'>销售</div>
                    <div className='enterprise-block-desc'>
                      <div className='dot'></div>
                      <div className='text'>
                        销售技巧
                      </div>
                    </div>
                    <div className='enterprise-block-desc'>
                      <div className='dot'></div>
                      <div className='text'>
                        谈判策略
                      </div>
                    </div>
                    <div className='enterprise-block-desc'>
                      <div className='dot'></div>
                      <div className='text'>
                        大客户销售管理
                      </div>
                    </div>
                    <div className='enterprise-block-desc'>
                      <div className='dot'></div>
                      <div className='text'>
                        销售团队管理
                      </div>
                    </div>
                    <div className='enterprise-block-desc'>
                      <div className='dot'></div>
                      <div className='text'>
                        客户关系管理
                      </div>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className='left-title'>财务</div>
                    <div className='enterprise-block-desc'>
                      <div className='dot'></div>
                      <div className='text'>
                        预算管理
                      </div>
                    </div>
                    <div className='enterprise-block-desc'>
                      <div className='dot'></div>
                      <div className='text'>
                        经营分析
                      </div>
                    </div>
                    <div className='enterprise-block-desc'>
                      <div className='dot'></div>
                      <div className='text'>
                        预算管理
                      </div>
                    </div>
                    <div className='enterprise-block-desc'>
                      <div className='dot'></div>
                      <div className='text'>
                        财务管理
                      </div>
                    </div>
                    <div className='enterprise-block-desc'>
                      <div className='dot'></div>
                      <div className='text'>
                        资金管理
                      </div>
                    </div>
                  </Col>
                </Row>
                <div className='left-line'></div>
                <Row gutter={74}>
                  <Col md={12}>
                    <div className='left-title'>人力资源</div>
                    <div className='enterprise-block-desc'>
                      <div className='dot'></div>
                      <div className='text'>
                        劳动关系
                      </div>
                    </div>
                    <div className='enterprise-block-desc'>
                      <div className='dot'></div>
                      <div className='text'>
                        企业文化
                      </div>
                    </div>
                    <div className='enterprise-block-desc'>
                      <div className='dot'></div>
                      <div className='text'>
                        招聘
                      </div>
                    </div>
                    <div className='enterprise-block-desc'>
                      <div className='dot'></div>
                      <div className='text'>
                        薪酬激励
                      </div>
                    </div>
                    <div className='enterprise-block-desc'>
                      <div className='dot'></div>
                      <div className='text'>
                        培训项目设计与实施
                      </div>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className='left-title'>内训师</div>
                    <div className='enterprise-block-desc'>
                      <div className='dot'></div>
                      <div className='text'>
                        经验萃取
                      </div>
                    </div>
                    <div className='enterprise-block-desc'>
                      <div className='dot'></div>
                      <div className='text'>
                        课程开发
                      </div>
                    </div>
                    <div className='enterprise-block-desc'>
                      <div className='dot'></div>
                      <div className='text'>
                        授课技巧
                      </div>
                    </div>
                  </Col>
                </Row>
                <div className='left-line'></div>
                <Row gutter={74}>
                  <Col md={12}>
                    <div className='left-title'>其他</div>
                    <div className='enterprise-block-desc'>
                      <div className='dot'></div>
                      <div className='text'>
                        工程项目经理
                      </div>
                    </div>
                    <div className='enterprise-block-desc'>
                      <div className='dot'></div>
                      <div className='text'>
                        门店店长
                      </div>
                    </div>
                    <div className='enterprise-block-desc'>
                      <div className='dot'></div>
                      <div className='text'>
                        采购
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </QueueAnim>
          </QueueAnim>
          <TweenOne
            key="img"
            animation={imgAnim}
            resetStyle
            component={Col}
            md={12}
          >
            <div className='enterprise-box-right'>
              <div className='enterprise-box-right-one'>
                <div className='enterprise-box-header'>
                  <img src={IconBook} alt=""/>
                  <div className='enterprise-box-header-title'>管理者素质与能力</div>
                </div>
                <Row gutter={74}>
                  <Col md={12}>
                    <div className='enterprise-block-desc'>
                      <div className='dot'></div>
                      <div className='text'>
                        战略思维
                      </div>
                    </div>
                    <div className='enterprise-block-desc'>
                      <div className='dot'></div>
                      <div className='text'>
                        经营思维
                      </div>
                    </div>
                    <div className='enterprise-block-desc'>
                      <div className='dot'></div>
                      <div className='text'>
                        创新思维
                      </div>
                    </div>
                    <div className='enterprise-block-desc'>
                      <div className='dot'></div>
                      <div className='text'>
                        财务思维
                      </div>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className='enterprise-block-desc'>
                      <div className='dot'></div>
                      <div className='text'>
                        人才规划
                      </div>
                    </div>
                    <div className='enterprise-block-desc'>
                      <div className='dot'></div>
                      <div className='text'>
                        团队领导
                      </div>
                    </div>
                    <div className='enterprise-block-desc'>
                      <div className='dot'></div>
                      <div className='text'>
                        激励与辅导
                      </div>
                    </div>
                    <div className='enterprise-block-desc'>
                      <div className='dot'></div>
                      <div className='text'>
                        目标与绩效管理
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className='enterprise-box-right-two'>
                <div className='enterprise-box-header'>
                  <img src={IconBook} alt=""/>
                  <div className='enterprise-box-header-title'>通用能力培养</div>
                </div>
                <Row gutter={74}>
                  <Col md={12}>
                    <div className='enterprise-block-desc'>
                      <div className='dot'></div>
                      <div className='text'>
                        职业化素养
                      </div>
                    </div>
                    <div className='enterprise-block-desc'>
                      <div className='dot'></div>
                      <div className='text'>
                        思考与表达
                      </div>
                    </div>
                    <div className='enterprise-block-desc'>
                      <div className='dot'></div>
                      <div className='text'>
                        沟通与影像
                      </div>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className='enterprise-block-desc'>
                      <div className='dot'></div>
                      <div className='text'>
                        问题分析解决
                      </div>
                    </div>
                    <div className='enterprise-block-desc'>
                      <div className='dot'></div>
                      <div className='text'>
                        写作与汇报
                      </div>
                    </div>
                    <div className='enterprise-block-desc'>
                      <div className='dot'></div>
                      <div className='text'>
                        效能提升
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className='enterprise-box-right-three'>
                <div className='enterprise-box-header'>
                  <img src={IconBook} alt=""/>
                  <div className='enterprise-box-header-title'>其他主题</div>
                </div>
                <Row gutter={74}>
                  <Col md={12}>
                    <div className='enterprise-block-desc'>
                      <div className='dot'></div>
                      <div className='text'>
                        AI 与 GPT
                      </div>
                    </div>
                    <div className='enterprise-block-desc'>
                      <div className='dot'></div>
                      <div className='text'>
                        法律合规
                      </div>
                    </div>
                    <div className='enterprise-block-desc'>
                      <div className='dot'></div>
                      <div className='text'>
                        党建
                      </div>
                    </div>
                    <div className='enterprise-block-desc'>
                      <div className='dot'></div>
                      <div className='text'>
                        政策解读
                      </div>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className='enterprise-block-desc'>
                      <div className='dot'></div>
                      <div className='text'>
                        宏观经济
                      </div>
                    </div>
                    <div className='enterprise-block-desc'>
                      <div className='dot'></div>
                      <div className='text'>
                        国际形势
                      </div>
                    </div>
                    <div className='enterprise-block-desc'>
                      <div className='dot'></div>
                      <div className='text'>
                        历史人文
                      </div>
                    </div>
                    <div className='enterprise-block-desc'>
                      <div className='dot'></div>
                      <div className='text'>
                        产业格局
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </TweenOne>
        </OverPack>
      </div>
    </main>
  )
}

export default Enterprise