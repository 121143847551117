import React, {useState} from 'react'
import ExpandCollapse from '@/components/ExpandCollapse'
import IconTactic from '@/assets/images/product/study/icon-tactic.png'
import IconCompany from '@/assets/images/product/study/icon-company.png'
import IconFlag from '@/assets/images/product/study/icon-flag.png'
import IconTime from '@/assets/images/product/study/icon-time.png'
import IconUser from '@/assets/images/product/study/icon-user.png'
import BasicLevel from "@/assets/images/product/study/icon-basic-level.png";
import MiddleLevel from "@/assets/images/product/study/icon-middle-level.png";
import HighLevel from '@/assets/images/product/study/icon-high-level.png'


const Tactic: React.FC = () => {
  const [data, setData] = useState([{
    title: '战略',
    pic: IconTactic,
    content: [{
      title: '四渡赤水:不确定环境下的战略调整与变革',
      tips: '实地教学',
      level: [{
        pic: HighLevel,
        name: '高层管理者'
      }],
      tag: [{
        pic: IconTime,
        name: '课程时长：',
        content: '4天'
      }, {
        pic: IconUser,
        name: '学员对象：',
        content: '董事长、CEO、总裁、总监等企业经营者'
      }, {
        pic: IconFlag,
        name: '授课形式：',
        content: '现地教学体验（重庆、贵州）'
      }],
      base: [{
        title: '课程介绍',
        content: '没有经历过失败的将军，很难真正理解战争。没有经历过逆境的企业，也很难真正理解竞争。逆境是企业和企业家的成人礼。为什么有些优秀的组织反而可以在逆境中脱胎换骨，甚至把逆境变成机会？因为在逆境中，优秀的领导者可以最大程度地发挥自身与团队的潜能，从而为组织赢得生存和突破的机会，最终是为组织打下走向卓越的根基。\n' +
          '《四渡赤水—不确定环境下的战略决策与变革》实地体验战略课程耗费数年时间，由管理学家与军事科学院和国防大学资深军事专家联合开发，将军事和战争案例引入管理学从而帮助企业全面深入剖析和理解在激烈竞争环境下的领导力和执行力，本课程采用实地体验式教学。'
      },{
        title: '课程收益',
        desc: [{
          text: '体验在高度动态的竞争环境中，通过对经验的分析总结，不断调整，启发学员找到企业制胜之道'
        }, {
          text: '从认识论和方法论的高度，深入探讨正确的战略思想是如何在战争实践的经验中总结形成并付诸行动的'
        }, {
          text: '力求帮助学员打造高灵活度适应性 (Highly Adaptive Organization) 组织'
        }]
      }]
    },{
      title: '企业增长辅导四部曲',
      tips: '定制化咨询',
      level: [{
        pic: HighLevel,
        name: '高层管理者'
      }],
      tag: [{
        pic: IconTime,
        name: '项目时长：',
        content: '咨询项目的具体周期需根据企业实际情况制定'
      }, {
        pic: IconUser,
        name: '企业对象：',
        content: '中小型企业'
      }, {
        pic: IconFlag,
        name: '项目亮点：',
        content: '双导师制（业务教练与组织教练，双管齐下）'
      }],
      base: [{
        title: '项目介绍',
        content: '近年来，中国市场竞争升级，企业成本连年上涨，智能化等新技术被普遍推广和应用，商业模式不停地迭代更新，大环境的一系列变化让很多传统企业受到极大的挑战和考验：大部分传统企业丝毫没有招架能力，企业增长停滞甚至出现业绩下滑的情况。然而，市场的规律永远是不进则退，于是不少企业开始寻找出路。到底应该从哪里入手破局，才能让企业快速应对大环境的变化，找回增长曲线，是近几年来最困扰企业家的问题之一。而企业增长最好的突破口是组织核心能力的提升。'
      },{
        title: '项目收益',
        desc: [{
          text: '帮助企业找到中长期发展目标，制定企业业务飞轮，完成公司级 OKR 及资源匹配'
        }, {
          text: '找到存量业务的增长打法及核心能力提升的实施方法'
        }, {
          text: '寻找适合中小企业的创新业务打法，促进创新变现业务发展'
        }, {
          text: '在企业内部形成四会模式，形成团队文化和独特的团队语言'
        }]
      }]
    },{
      title: '进化战略',
      level: [{
        pic: MiddleLevel,
        name: '中层管理者'
      },{
        pic: HighLevel,
        name: '高层管理者'
      }],
      tag: [{
        pic: IconTime,
        name: '课程时长：',
        content: '3天'
      }, {
        pic: IconUser,
        name: '学员对象：',
        content: '企业一把手与他的核心团队、BU Leader 与直接汇报者'
      }, {
        pic: IconFlag,
        name: '授课形式：',
        content: '工作坊 / 实战研讨 / 案例教学 / 模拟练习'
      }],
      base: [{
        title: '课程介绍',
        content: 'VUCA 时代，企业如同行驶在陌生海洋上的航船，看不到海岸，没有灯塔，不知何时会遇到突如其来的暴风雨，也不知哪里会有巨浪或暗礁。在未知大于已知的情况下，如何找到正确的方向，并共创出激动人心的愿景和目标？如何制定出与复杂变化环境相适应的战略并达成真正的共识？如何在不断的调整中形成协同与合力？如何调动整个团队的积极性，创造性的完成挑战性的目标？如何形成有效的战略闭环，打造团队的学习能力，让团队在实践中不断学习成长？这些问题都是摆在 CEO 或者业务 Leader 面前的真实挑战。'
      },{
        title: '课程收益',
        content: '不同于传统的战略方法论，我们以系统论和进化论为基础，形成一套适应不确定市场环境下的、独特的战略思维方式、方法论和工具，我们称之为进化战略。它的特征是面对不确定性，以需求的确定性作为思考长期战略的基础。并根据业务发展的底层逻辑作为业务发展的框架，根据环境的变化，可以不断的发展、变化，能够把短中长目标有力的统一为一个整体。我们致力于用新的方法体系帮助组织达成真正的战略共识，并形成战略合力。力求帮助企业：',
        desc: [{
          text: '体验在高度动态的竞争环境中，通过对经验的分析总结，不断调整，启发学员找到企业制胜之道'
        }, {
          text: '从认识论和方法论的高度，深入探讨正确的战略思想是如何在战争实践的经验中总结形成并付诸行动的'
        }, {
          text: '力求帮助学员打造高灵活度适应性 (Highly Adaptive Organization) 组织'
        }]
      }]
    },{
      title: '业务复盘',
      level: [{
        pic: MiddleLevel,
        name: '中层管理者'
      },{
        pic: HighLevel,
        name: '高层管理者'
      }],
      tag: [{
        pic: IconTime,
        name: '课程时长：',
        content: '2 天 1 晚'
      }, {
        pic: IconUser,
        name: '学员对象：',
        content: '一把手及其核心团队'
      }, {
        pic: IconFlag,
        name: '授课形式：',
        content: '工作坊 / 实战研讨 / 案例教学 / 模拟练习'
      }],
      base: [{
        title: '课程介绍',
        content: '规模越大、历史越久、曾经越成功的企业越是存在巨大的组织和个体惯性。如果仅有战略目标，而不对业务问题进行复盘总结，就很难重新聚焦资源，上下同欲，横向齐心，实现转型 / 突破性发展。\n' +
          '业务复盘无法通过领导碎片化的表述及多次的单独沟通来完成，也无法通过单纯的战略宣贯会来达成，对战略思考过程应该是深度参与的信息共享，以及对战略目标的深度共识与对齐。'
      },{
        title: '课程收益',
        content: '提供一个充分沟通、高效研讨的平台；将总经理的责任变为团队共同的责任，对战略规划及执行的重点阶段及关键环节进行复盘及调整。',
        desc: [{
          text: '描述一张蓝图（Zoom out）'
        }, {
          text: '关键环节盘点（Zoom in）'
        }, {
          text: '核心问题定位与解决（Focus）'
        }]
      }]
    },{
      title: '企业战略思维',
      level: [{
        pic: MiddleLevel,
        name: '中层管理者'
      },{
        pic: HighLevel,
        name: '高层管理者'
      }],
      tag: [{
        pic: IconTime,
        name: '课程时长：',
        content: '2 天'
      }, {
        pic: IconUser,
        name: '学员对象：',
        content: '企业中战略制定者、分解者、执行者'
      }, {
        pic: IconFlag,
        name: '授课形式：',
        content: '知识讲授 / 案例教学 / 实战研讨'
      }],
      base: [{
        title: '课程介绍',
        content: '本课程从企业实际出发，大处着眼，系统思考业务发展趋势，分析行业发展规律。前瞻性的预判潜在的发展机会，包括战略的规划思考和战略的管理，并制定组织的长期目标并将其付诸实施。这是一种过程，最终目的是要找出策略，好的策略需有好的组织相配合，才可达到目标或给公司带来预期的利润。'
      },{
        title: '课程收益',
        desc: [{
          text: '战略思维与战略规划的应有认知'
        }, {
          text: '如何做战略思考，判断业务发展趋势，分析行业发展规律'
        }, {
          text: '通过行业分析，SWOT 分析对前瞻性的预判，分析出潜在的发展机会'
        }, {
          text: '学会战略管理方案与策略制定研议'
        }, {
          text: '战略管理议题分析技巧'
        }, {
          text: '掌握评估公司战略经营战略的步骤'
        }, {
          text: '提升高管制定战略的整理能力，以及落地能力'
        }, {
          text: '学会科学决策技巧'
        }]
      }]
    },{
      title: '战略解码',
      tips: '工作坊',
      level: [{
        pic: MiddleLevel,
        name: '中层管理者'
      },{
        pic: HighLevel,
        name: '高层管理者'
      }],
      tag: [{
        pic: IconTime,
        name: '课程时长：',
        content: '2 天'
      }, {
        pic: IconUser,
        name: '学员对象：',
        content: '中高层管理者'
      }, {
        pic: IconFlag,
        name: '授课形式：',
        content: '工作坊 / 实战研讨 / 案例教学 / 模拟练习'
      }],
      base: [{
        title: '课程介绍',
        content: '本课程将结合实际战略规划，战略解码，战略执行并综合战略管理场景，针对中国企业的实际问题进行演绎和讲解，让学员全面掌握从战略到执行的规划框架的核心思想、方法、流程、工具和模板。通过行动学习的方式来挑战传统思维，逐步提升领导在各方面的战略思维能力。'
      },{
        title: '课程收益',
        desc: [{
          text: '了解战略解码的内涵与逻辑（战略目标与战略规划的关系），理解自身在战略实践中的定位和职责'
        }, {
          text: '掌握战略规划分析的思路与工具，提升战略规划收集和分析内外部信息的技巧'
        }, {
          text: '洞察未来发展趋势，确定战略焦点（目标 / 举措）的技巧，明确关键任务与依赖关系'
        }, {
          text: '掌握战略目标的分解与展开、依据战略目标制定实现目标的思路、步骤和方法；制定并推动完成自身与协同目标的能力'
        }, {
          text: '执行目标，明确职责分工，确保战略执行'
        }]
      }]
    },{
      title: '战略谋划与执行',
      tips: '沙盘',
      level: [{
        pic: MiddleLevel,
        name: '中层管理者'
      },{
        pic: HighLevel,
        name: '高层管理者'
      }],
      tag: [{
        pic: IconTime,
        name: '课程时长：',
        content: '2 天'
      }, {
        pic: IconUser,
        name: '学员对象：',
        content: '中高层管理者'
      }, {
        pic: IconFlag,
        name: '授课形式：',
        content: '沙盘模拟 / 实战研讨 / 案例教学 / 模拟练习'
      }],
      base: [{
        title: '',
      },{
        title: '课程收益',
        desc: [{
          name: '对高层经理：',
        }, {
          text: '1. 掌握战略谋划的思路和流程，清晰认识战略执行中容易出现的问题，切实提高战略管理能力',
          dot: true
        }, {
          text: '2. 验证以往形成的战略管理思想，暴露自身存在的战略管理盲区；提升决策层理性战略决策的意识和能力',
          dot: true
        }, {
          name: '对中层经理：',
        }, {
          text: '1. 加深对企业战略规划的理解，系统了解企业内部价值链的关系，认识到打破狭隘的部门分割',
          dot: true
        }, {
          text: '2. 增强全局战略意识，掌握战略分析、战略制定、战略执行、战略控制的基本方法',
          dot: true
        }]
      }]
    },{
      title: '升维® 商业模式',
      tips: ['定制工作坊', '版权课'],
      level: [{
        pic: MiddleLevel,
        name: '中层管理者'
      },{
        pic: HighLevel,
        name: '高层管理者'
      }],
      tag: [{
        pic: IconTime,
        name: '课程时长：',
        content: '2 天'
      }, {
        pic: IconUser,
        name: '学员对象：',
        content: '中高层管理者、产品管理者、研发管理者'
      }, {
        pic: IconFlag,
        name: '授课形式：',
        content: '工作坊 / 实战研讨 / 案例教学'
      }],
      base: [{
        title: '课程介绍',
        content: '作为近 20 年来兴起的最重要的商业概念之一，商业模式已成为企业进行业务设计时的通用语言和研究思路。\n' +
          '本课程直击商业模式的核心逻辑，帮助学员反思商业模式的一致性和竞争力，进而完成对新型商业模式的探索，或既有商业模式的升级。以数十家国内外领先企业的实践案例，剖析商业模式设计思路和升级方向，帮助学员找到自身商业模式的破局点。'
      },{
        title: '课程收益',
        desc: [{
          name: '构建全局思维：',
          text: '跳出局部，整体理解企业经营；像 CEO 一样思考，构建战略执行能力'
        }, {
          name: '统一商业语言：',
          text: '用一套共通的商业语言描述和讨论业务问题，提高沟通经营效率'
        }, {
          name: '输出落地计划：',
          text: '学会用相应工具分析业务问题和方向，输出落地的具体措施'
        }]
      }]
    },{
      title: '升弈® BMC',
      tips: ['沙盘', '版权课'],
      level: [{
        pic: MiddleLevel,
        name: '中层管理者'
      },{
        pic: HighLevel,
        name: '高层管理者'
      }],
      tag: [{
        pic: IconTime,
        name: '课程时长：',
        content: '2 天'
      }, {
        pic: IconUser,
        name: '学员对象：',
        content: '中高层管理者、产品管理者、研发管理者'
      }, {
        pic: IconFlag,
        name: '授课形式：',
        content: '工作坊 / 实战研讨 / 案例教学'
      }],
      base: [{
        title: '课程介绍',
        content: '登高方能望远，企业管理者需要谋局而动。从聚焦于产品、业务的创始人、合伙人转变成聚焦于模式、经营的企业家；从算糊涂账、盲目烧钱到健康财务，智慧融资；从工业时代的静态思维到互联网时代的动态思维，公司的中高层管理者亟需建立起系统性的全局思维及商业模式的经营意识，才能在 VUCA 时代实现企业的加速增长。\n' +
          '《升弈 ®BMC》在商业模式画布的基础之上，结合真实市场环境和企业经营设计而成。沿袭商业模式画布的企业经营 9 要素布局，增加了量化管理、经营复盘、企业竞争等沙盘游戏元素。',
        desc: [{
          name: '产品生命周期：',
          text: '了解产品经营全周期的发展规律，界定产品当前所处的发展阶段，根据当前阶段特征确定产品打法'
        }, {
          name: '业务发展方向：',
          text: '深度理解客户的真实场景和需求，描绘价值与需求的对应关系，设计 / 迭代产品的核心价值主张'
        }, {
          name: '商业模式落地：',
          text: '设计出能够落地推进的商业模式，量化评价商业模式的价值，识别商业模式的薄弱环节'
        }]
      },{
        title: '课程收益',
        desc: [{
          name: '系统研讨新业务：',
          text: '沙盘形式导入 3 张经营画布，以实际业务主题为研讨方向，沙盘盘面就是系统研讨框架'
        }, {
          name: '掌握创新估值模型：',
          text: '以财务、市场、团队综合表现评价创新业务的价值，估值模型基于真实创业团队'
        }, {
          name: '强化人的作用：',
          text: '体现合伙人能力的推动作用，凸显成熟团队的优势和挑战'
        }]
      }]
    },{
      title: '升弈® OMC',
      tips: ['沙盘', '版权课'],
      level: [{
        pic: BasicLevel,
        name: '基层管理者'
      },{
        pic: MiddleLevel,
        name: '中层管理者'
      }],
      tag: [{
        pic: IconTime,
        name: '课程时长：',
        content: '2 天'
      }, {
        pic: IconUser,
        name: '学员对象：',
        content: '中基层管理者'
      }, {
        pic: IconFlag,
        name: '授课形式：',
        content: '沙盘模拟 / 实战研讨 / 案例教学 / 讲师点评'
      }],
      base: [{
        title: '课程介绍',
        content: '企业中层管理者往往从一线员工和基层管理者选拔而来，他们更擅长专业和管理技能，但经营意识淡薄，无法站在 CEO 角度做出商业运营决策，面临转型挑战，具体体现在懂专业不懂经营、财务基础薄弱、对商业本质规律缺乏认识等多方面，比如会出现“还好犯错的不是我的团队”“事情太多，拍脑袋决策在所难免”“做管理太复杂，要不要回一线呢”等等声音。因此企业中层管理者需要提升经营意识，形成管理者视角，才能真正发挥腰部力量。\n' +
          '《升弈 ®OMC》是在著名的商业模式画布基础之上，进行本地化设计和二次深度开发的沙盘模拟课程。该课程沿袭运营模式画布 5 要素布局，增加损益表、资产负债表等财务管理工具，通过两天时间的企业模拟经营决策、讲师复盘、反思学习等多种方式，达成 3 个培训目标：',
        desc: [{
          name: '构建全局思维：',
          text: '跳出局部思维，整体理解企业经营，帮助学员切换到经营者视角，构建战略理解能力'
        }, {
          name: '统一商业认知：',
          text: '用一套共通的商业语言提高沟通经营问题的效率'
        }, {
          name: '掌握分析工具：',
          text: '系统掌握商业模式全景图，学会用相应工具分析业务方向和问题'
        }]
      },{
        title: '课程收益',
        content: '帮助学员“过三关”，形成经营者视角：',
        desc: [{
          name: '第 1 关，从竖着看到横着看：',
          text: '不仅关注本部门利益，还关注其他部门的发展；站在公司角度综合考量业务全局'
        }, {
          name: '第 2 关，从向内看到向外看：',
          text: '看到一线客户的真实需求，能将正确的信息带回公司，让一线的声音指挥后台资源调配'
        }, {
          name: '第 3 关，从看现在到看未来：',
          text: '将短期利益和长期发展有效结合，对趋势变化保持敏锐，有效应对动态复杂性'
        }]
      }]
    },{
      title: 'Letterpress® ―灵图管理业务',
      tips: ['沙盘', '版权课'],
      level: [{
        pic: BasicLevel,
        name: '基层管理者'
      },{
        pic: MiddleLevel,
        name: '中层管理者'
      }],
      tag: [{
        pic: IconTime,
        name: '课程时长：',
        content: '1-2 天'
      }, {
        pic: IconUser,
        name: '学员对象：',
        content: '中基层管理者、后备干部、业务单元负责人'
      }, {
        pic: IconFlag,
        name: '授课形式：',
        content: '知识讲解 / 沙盘 / 课程研讨'
      }],
      base: [{
        title: '课程介绍',
        content: '您将带领一家名为“Ladder.inc”的公司起步，面对着不同的细分产品、客户细分市场、供应商、人才市场，从零开始运营。在最长两年共8个季度的运营过程中，您需要与您的团队，在公司的业务战略的指导下，在瞬息万变的市场环境下，做出一个又一个经营决策，以保证的市场竞争中处于不败之地，并争取达成自身的战略目标。因此 Ladder.inc 将发展成何等商业模式、何等规模，一切将由您和您的团队亲手决定。',
      },{
        title: '课程收益',
        desc: [{
          text: '学员可自主选择公司战略，设计需要的沙盘版图'
        }, {
          text: '学员可自己设计当前市场场景做沙盘推演'
        }, {
          text: '学员可组间互相竞争合作，更加贴合市场实际'
        }, {
          text: '学员可以此沙盘做战略沟通、跨部门沟通'
        }]
      }]
    },{
      title: '客户洞察',
      tips: '工作坊',
      level: [{
        pic: MiddleLevel,
        name: '中层管理者'
      },{
        pic: HighLevel,
        name: '高层管理者'
      }],
      tag: [{
        pic: IconTime,
        name: '课程时长：',
        content: '2 天'
      }, {
        pic: IconUser,
        name: '学员对象：',
        content: 'B2B 行业市场及销售管理人员、B2B 行业大客户销售团队、产品 / 市场 / 客户成功等团队的资深人士'
      }, {
        pic: IconFlag,
        name: '授课形式：',
        content: '工作坊 / 知识讲解 / 实战研讨 / 案例分析'
      }],
      base: [{
        content: '',
      },{
        title: '课程收益',
        desc: [{
          text: '建立和厘清对关键客户生命周期的认知，习得系统工具并从商业模式层面深度理解客户'
        }, {
          text: '基于客户和我的商业全息图，洞察到之前忽视的潜在需求并设计价值主张，拓宽业务合作的广度和深度'
        }, {
          text: '通过系统工具拉齐前、中、后台对于客户需求的认知，聚焦于为客户提供价值，减少内耗'
        }, {
          text: '研讨部分以实际业务问题展开，随学即用，带走可落地的行动方向'
        }]
      }]
    },{
      title: '爆点——用户洞察',
      tips: '工作坊',
      level: [{
        pic: BasicLevel,
        name: '基层管理者'
      },{
        pic: MiddleLevel,
        name: '中层管理者'
      }],
      tag: [{
        pic: IconTime,
        name: '课程时长：',
        content: '2 天'
      }, {
        pic: IconUser,
        name: '学员对象：',
        content: '从事业务规划、业务设计工作的业务管理者；与用户洞察工作直接相关的产品研发人员'
      }, {
        pic: IconFlag,
        name: '授课形式：',
        content: '工作坊 / 知识讲解 / 实战研讨 / 案例分析'
      }],
      base: [{
        title: '解决痛点',
        desc: [{
          name: '伪需求：',
          text: '误把自己当成典型用户，凭空想象用户需求，忽视客观的用户状态和行为'
        }, {
          name: '缺洞察：',
          text: '摸不清用户的真正想法，被用户牵着鼻子走，难以形成稳定、准确的判断'
        }, {
          name: '难取舍：',
          text: '前中后台对用户需求理解各执一词，无法判断功能优先级，在多种需求之间难以抉择'
        }]
      },{
        title: '课程收益',
        desc: [{
          name: '还原用户真实需求：',
          text: '从用户真实状况出发，系统全面地了解用户需求，规避用户需求的认知盲区'
        }, {
          name: '系统分析用户动因：',
          text: '系统了解影响用户偏好的底层动因，并能据此进行合理的价值梳理和价值创新'
        }, {
          name: '明确需求合理排序：',
          text: '量化用户满意度，依据优先级原则对用户需求合理排序，确定行动方向'
        }]
      }]
    },{
      title: '以客户为中心的产品战略制定和决策管理',
      level: [{
        pic: HighLevel,
        name: '高层管理者'
      }],
      tag: [{
        pic: IconTime,
        name: '课程时长：',
        content: '1 天'
      }, {
        pic: IconUser,
        name: '学员对象：',
        content: '高层管理者（董事长、总裁、战略主管、产品线主管、研发与创新主管）'
      }, {
        pic: IconFlag,
        name: '授课形式：',
        content: '知识讲授 / 案例教学 / 模拟练习'
      }],
      base: [{
        title: '课程介绍',
        content: '在国家创新战略的引领下，很多企业特别热衷各种创新模式的研究，如引进吸收式创新、渐进式自主创新、突破式自主创新等。可是，华为从来只讲“开放式创新”，而且提出了有争议的“以客户为中心”的创新战略。\n' +
          '华为的持续高增长证明了该战略的正确。而且，在《2016 全球百强创新机构》、《全球最具创新力公司 2016》等多个机构的排名中，华为都成为中国大陆唯一的入选者。\n' +
          '然而，为什么很多大谈自主创新的企业，却鲜有令人信服的成功创新呢？本课程将由前华为战略部部长、主管创新（预研）的技术总经理，为我们解读华为的成功密码。',
      },{
        title: '课程亮点',
        desc: [{
          text: '课程包含大量企业实践案例解剖'
        }, {
          text: '将先进的方法论与中国企业的实际相结合'
        }, {
          text: '总结提炼了产品战略制定、产品规划和创新的核心方法，既有理论高度又有实操性'
        }]
      }]
    },{
      title: '变革管理三次方™',
      tips: '版权课',
      level: [{
        pic: MiddleLevel,
        name: '中层管理者'
      },{
        pic: HighLevel,
        name: '高层管理者'
      }],
      tag: [{
        pic: IconTime,
        name: '课程时长：',
        content: '2 天'
      }, {
        pic: IconUser,
        name: '学员对象：',
        content: '中高层管理者（经理、高级经理、总监）、企业变革项目管理人员与核心人员'
      }, {
        pic: IconFlag,
        name: '授课形式：',
        content: '知识讲授 / 案例教学 / 模拟练习'
      }],
      base: [{
        title: '课程介绍',
        content: '无论是高层领导、中层经理，处在变革的过程中，都需要有开放的心态去拥抱变革，努力地走出舒适区，学习新的知识、技能，以适应新的动态平衡，体现自己独特的价值。变革管理的三次方以科特的 8 步法为框架，集众家所长，形成可应用于工作中的方法和工具；分享中外变革案例，帮助理解方法、学习借鉴；融入模拟游戏，让学员在有限的资源中，讨论最好的行动组合，强化理解相关内容。',
      },{
        title: '课程收益',
        desc: [{
          text: '帮助我们更顺利的推行目前的各种企业项目'
        }, {
          text: '掌握推动变革的 4-2-8: 四要素、两方式、八步骤'
        }, {
          text: '将变革管理的核心理念 : 目睹 - 感受 - 改变应用在项目上'
        }, {
          text: '学习应用多种变革管理的沟通与阻力化解技巧'
        }, {
          text: '学员本身更加的理解变革的紧迫感与适应改变'
        }]
      }]
    },{
      title: 'FRESH 萌新创新画布™',
      tips: ['工作坊', '版权课'],
      level: [{
        pic: BasicLevel,
        name: '基层管理者'
      },{
        pic: MiddleLevel,
        name: '中层管理者'
      }],
      tag: [{
        pic: IconTime,
        name: '课程时长：',
        content: '2 天'
      }, {
        pic: IconUser,
        name: '学员对象：',
        content: '中基层管理者及需要创新的核心员工'
      }, {
        pic: IconFlag,
        name: '授课形式：',
        content: '工作坊 / 实战研讨 / 案例教学 / 模拟练习 / 讲师点评'
      }],
      base: [{
        title: '课程介绍',
        content: '《FRESH 萌新创新画布》视觉引导创新画布，是一套系统的创新工具、完整的创新流程 . 过程中老师采用形式多样的视觉引导方式，经过四大步骤、10 大工具的实践应用，带领团队集思广义、大胆创新，从而激发团队创造热情、提高团队创造力，并帮助学会用创新的方法解决问题、开拓思路，提高工作效率。',
      },{
        title: '课程收益',
        desc: [{
          text: '学会视觉引导创新工作坊的一套设计思路，可以迅速、高效地激活团队'
        }, {
          text: '学会打破固有思维的 10 种创新思维工具画布，并掌握如何运用创新工具带领团队发散思维、解决工作相关难题等众多引导方法'
        }, {
          text: '学会带领团队针对问题清晰洞察的高效工具，并掌握快速找到问题最根本原因的聚焦方法'
        }, {
          text: '学会引领团队创变的创新流程，并掌握多种技巧和方法来提升创新思维的落地实用性'
        }]
      }]
    },{
      title: '设计思维',
      tips: ['工作坊', '版权课'],
      level: [{
        pic: BasicLevel,
        name: '基层管理者'
      },{
        pic: MiddleLevel,
        name: '中层管理者'
      }],
      tag: [{
        pic: IconTime,
        name: '课程时长：',
        content: '1-2 天'
      }, {
        pic: IconUser,
        name: '学员对象：',
        content: '中基层管理者及核心人员'
      }, {
        pic: IconFlag,
        name: '授课形式：',
        content: '知识讲授 / 实战研讨 / 案例教学 / 模拟练习'
      }],
      base: [{
        title: '课程介绍',
        content: '如果说用户思维是应用性的科学，设计思维则是是交互的灯塔。设计思维（Design Thinking）是驱动创新的思维，会影响到员工的众多重要决策。本课程向学员展示了一个清晰、科学的设计思路。设计思维绝不是一个按部就班的“流程”，更是一种精神，一种设计驱动，一种用户为中心的文化。',
      },{
        title: '课程收益',
        desc: [{
          name: '市场验证：',
          text: 'IDEO 和斯坦福经过多年实践定制的系统方法论 ‒ 设计思维五步法'
        }, {
          name: '探索问题：',
          text: '看重对于问题本质的探讨，透过有效方法，重新定义问题'
        }, {
          name: '以人为本：',
          text: '从人、用户的角度发现及解决问题，重视用户的同理心'
        }, {
          name: '动手迭代：',
          text: '重视原型的制作、测试和快速迭代，强调做中学和错中学'
        }, {
          name: '团队协作：',
          text: '三个臭皮匠胜过一个诸葛亮，利用团队大量的思想叠加'
        }]
      }]
    },]
  },{
    title: '公司治理',
    pic: IconCompany,
    content: [{
      title: '国有企业治理与三会运作',
      level: [{
        pic: HighLevel,
        name: '高层管理者'
      }],
      tag: [{
        pic: IconTime,
        name: '课程时长：',
        content: '2 天'
      }, {
        pic: IconUser,
        name: '学员对象：',
        content: '董事长、CEO、总裁、总监、高级经理等企业经营者、高端人才'
      }, {
        pic: IconFlag,
        name: '授课形式：',
        content: '知识讲解 / 案例分析 / 课程研讨'
      }],
      base: [{
        title: '课程介绍',
        content: '在公司治理层面，高层管理者经常遇到如下问题：不了解国有企业混合所有制改革对公司治理的具体要求；难以掌握现代化董事会建设的方法，如董事会评价、董事会职权的深度理解和建设；不清楚董事及外派董事的履职风险和履职策略，如外派小股东董事如何获取企业经营信息；难以摸清如何对合资企业的国有企业股东进行权利保护。本课程将对以上问题进行系统全面的逐一解答，帮助企业高层摸清国有企业治理与三会运作的关键点。'
      },{
        title: '课程收益',
        desc: [{
          text: '全面了解国有企业公司治理的内核及外延；明确知道国有企业公司治理中风险防范及纠纷解决'
        }, {
          text: '清楚投资及并购中如何应用治理方法达到控制目的'
        }, {
          text: '全面了解国有企业股东会、董事会和监事的运作与建设'
        }, {
          text: '明确卓越董事会运作的基本元素和建设原则；明确外派董事和外派监事的职责'
        }, {
          text: '如何通过三会运用进行现代化集团化企业管理'
        }]
      }]
    },{
      title: '国企混改实践',
      level: [{
        pic: HighLevel,
        name: '高层管理者'
      }],
      tag: [{
        pic: IconTime,
        name: '课程时长：',
        content: '1-2 天'
      }, {
        pic: IconUser,
        name: '学员对象：',
        content: '国有企业董事长、总经理、高管（财务总监、董秘及副总）等经营者'
      }, {
        pic: IconFlag,
        name: '授课形式：',
        content: '知识讲授 / 案例教学 / 课程研讨'
      }],
      base: [{
        title: '课程介绍',
        content: '国有企业混合所有制改革，难以脱离混改政策及宏观趋势，同时也需要结合中国实际情况进行实践落地。本课程将对国企深化改革的政策体系及混改最新文件做出深化解读，深入分析国企深改的方向、趋势与重点；在此基础上，帮助学员掌握混合所有制改革的操作实践，帮助学员引入战略投资人，学会操作国有的企业股权激励，从而更好进行国有企业公司治理和董事会建设。',
      },{
        title: '课程收益',
        desc: [{
          text: '明晰国有企业改革历史渊源与廓清混合所有制基本理念'
        }, {
          text: '明晰企业管理分析方法，确定混改操作流程'
        }, {
          text: '清楚了解混改的并购重组、整体（分拆）上市等资本化操作手段'
        }, {
          text: '清楚了解国有企业公司治理范围与卓越董事会（三会）建设与运作'
        }, {
          text: '清楚了解国有企业员工持股（股权激励）的操作方式、风险规避与基本流程'
        }]
      }]
    },{
      title: '企业投融资与资本运作',
      level: [{
        pic: HighLevel,
        name: '高层管理者'
      }],
      tag: [{
        pic: IconTime,
        name: '课程时长：',
        content: '2 天'
      }, {
        pic: IconUser,
        name: '学员对象：',
        content: '董事长、CEO、总裁、总监、高级经理等企业经营者、高端人才'
      }, {
        pic: IconFlag,
        name: '授课形式：',
        content: '知识讲授 / 案例教学 / 课程研讨'
      }],
      base: [{
        title: '课程介绍',
        content: '资本运作包含三个维度，私募、公募及并购。本课程将详细讲解并购战略设计及操作方法、上市战略的选择及实施路径、私募股权投资的操作方法、尽职调查及企业甄选。',
      },{
        title: '课程收益',
        desc: [{
          text: '资本运作三个层面：高度、广度及深度了解（私募、上市、并购）'
        }, {
          text: '股权投资的热点、股权投资的技巧、股权投资考虑财务收益的五个角度'
        }, {
          text: '上市 IPO 的最新政策、科创板、北交所、新三板的最新政策、注册制改革的全面理解'
        }, {
          text: '企业并购的基本流程、交易结构与股权架构设计、风险规避、谈判及企业控制的技巧'
        }]
      }]
    },{
      title: '企业上市及股份制改造',
      level: [{
        pic: HighLevel,
        name: '高层管理者'
      }],
      tag: [{
        pic: IconTime,
        name: '课程时长：',
        content: '1-2 天'
      }, {
        pic: IconUser,
        name: '学员对象：',
        content: '董事长、CEO、总裁、总监、高级经理等企业经营者、高端人才'
      }, {
        pic: IconFlag,
        name: '授课形式：',
        content: '知识讲授 / 案例教学 / 课程研讨'
      }],
      base: [{
        title: '课程介绍',
        content: '本课程将帮助学员全面了解中国及世界资本市场的框架，掌握企业上市的战略规划及实操方法，比如中介团队如何选择、拟上市公司股权架构如何调整、拟上市公司控制权问题如何处理。在此基础上帮助学员熟悉掌握企业上市的具体条件和财务标准，包括主板、创业板、科创板、北交所及新三板。',
      },{
        title: '课程收益',
        desc: [{
          text: '对于中国及境外主要资本市场全面而系统的了解'
        }, {
          text: '企业上市之前考虑什么？注意什么？如何进行战略规划？'
        }, {
          text: '最新的科创板是什么板块？'
        }, {
          text: '企业上市前股份制改造的基本流程和操作实务'
        }]
      }]
    },{
      title: '企业并购重组与价值重塑',
      level: [{
        pic: HighLevel,
        name: '高层管理者'
      }],
      tag: [{
        pic: IconTime,
        name: '课程时长：',
        content: '2 天'
      }, {
        pic: IconUser,
        name: '学员对象：',
        content: '董事长、CEO、总裁、总监'
      }, {
        pic: IconFlag,
        name: '授课形式：',
        content: '知识讲解 / 案例分析'
      }],
      base: [{
        title: '课程介绍',
        content: '本课程将通过两天的深入研讨，从企业并购重组的理念与流程展开，输入并购重组中方案设计的策略和技巧，从而识别并购重组中的风险并进行有效把控。',
      },{
        title: '课程收益',
        desc: [{
          text: '企业并购各流程的实操重点'
        }, {
          text: '企业并购的融资与与股权架构设计'
        }, {
          text: '企业并购的交易结构、风险规避、谈判及控制技巧'
        }, {
          text: '企业并购后整合的方法论和操作重点'
        }]
      }]
    },]
  }])

  return (
    <main className='warp-tactic header-top'>
      <div className='warp-tactic-bg flex-center'>
        战略类课程除战略规划、解码及落地外，<br />
        还提供全套公司治理、公司上市、投融资及经营课程， <br />
        <span className='l-color'>多角度赋能企业长期良性运转。</span>
      </div>
      <div className='warp-tactic-content'>
        <div className='home-page'>
          {
            data.map((item, index) => (
              <>
                <div className='warp-tactic-content-header' style={{marginTop: `${index ? '75px': '0px'}`}}>
                  <img src={item.pic} alt=""/>
                  <div className='title'>{item.title}</div>
                </div>
                <div className='warp-tactic-content-items'>
                  {
                    item.content.map(c => (
                      <div className='content-item'>
                        <div className='content-item-header'>
                          <div className='tactic-header'>
                            <div className='tactic-header-title'>{c.title}</div>
                            {
                              c?.tips ?
                              typeof c.tips == 'object' ? c.tips.map(t => (
                                <div className='tactic-header-tag flex-center'>{t}</div>
                              )): <div className='tactic-header-tag flex-center'>{c.tips}</div>
                                : null
                            }
                          </div>
                          <div className='tactic-level'>
                            {
                              c.level.map(l => (
                                <>
                                  <img src={l.pic} alt=""/>
                                  <span>{l.name}</span>
                                </>
                              ))
                            }
                          </div>
                        </div>
                        <div className='content-item-tag'>
                          {
                            c.tag.map(t => (
                              <div className='tag-block'>
                                <img src={t.pic} alt=""/>
                                <span className='dur'>{t.name}</span>
                                <span className='day'>{t.content}</span>
                              </div>
                            ))
                          }
                        </div>
                        <div className='content-item-line'></div>
                        <div className='content-item-block'>
                          {
                            c.base.splice(0, 1).map(b => (
                              <>
                                {
                                  b.title && <div className='b-title'>
                                    {b.title}
                                  </div>
                                }
                                {
                                  b.content &&
                                  <div className='b-content p-pre-wrap'>
                                    {b.content}
                                  </div>
                                }
                                {
                                  b?.desc?.length > 0 &&
                                  <div className='wrap-b-desc'>
                                    {
                                      b.desc.map(d => (
                                        <div className='b-desc'>
                                          {d.dot ? <></> : <div className='dot'></div>}
                                          <div className='text'>
                                            <span>{d.name}</span>{d.text}
                                          </div>
                                        </div>
                                      ))
                                    }
                                  </div>
                                }
                              </>
                            ))
                          }
                          <ExpandCollapse>
                            {
                              c.base.map(b => (
                                <>
                                  <div className='b-title'>
                                    {b.title}
                                  </div>
                                  {
                                    b.content &&
                                    <div className='b-content p-pre-wrap'>
                                      {b.content}
                                    </div>
                                  }
                                  {
                                    b?.desc?.length > 0 &&
                                    <div className='wrap-b-desc'>
                                      {
                                        b.desc.map(d => (
                                          <div className='b-desc'>
                                            {d.dot ? <></> : <div className='dot'></div>}
                                            <div className='text'>
                                              <span>{d.name}</span>{d.text}
                                            </div>
                                          </div>
                                        ))
                                      }
                                    </div>
                                  }
                                </>
                              ))
                            }
                          </ExpandCollapse>
                        </div>
                      </div>
                    ))
                  }
                </div>
              </>
            ))
          }
        </div>
      </div>
    </main>
  )
}

export default Tactic