import React from "react";
import {Button} from "antd";

export const handleScrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
};

export const handleOpenPage = () => {
  // pc
  window.open('https://affim.baidu.com/unique_31595219/chat?siteId=16026512&userId=31595219&siteToken=92c77315f2a15e5a22dc54dce7de6cb6', '_blank')
  // 移动
  // window.open('https://affim.baidu.com/unique_31595219/mobile/chat?siteId=16026512&userId=31595219&siteToken=92c77315f2a15e5a22dc54dce7de6cb6', '_blank')
}

export const isImg = /^http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w-./?%&=]*)?/;
export const getChildrenToRender = (item, i) => {
  let tag = item.name?.indexOf?.('title') === 0 ? 'h1' : 'div';
  tag = item.href ? 'a' : tag;
  let children = typeof item?.children === 'string' && item?.children?.match(isImg)
    ? React.createElement('img', { src: item?.children, alt: 'img' })
    : item?.children;
  if (item.name?.indexOf?.('button') === 0 && typeof item?.children === 'object') {
    children = React.createElement(Button, {
      ...item?.children
    });
  }
  if(!children) {
    return null
  }
  return React.createElement(tag, { key: i.toString(), ...item }, children);
};

export const getAnim = (flag: boolean = false) => {
  return flag ? {
    y: 30,
    opacity: 0,
    delay: 600,
    type: 'from',
    ease: 'easeOutQuad',
  } : {
    x: 30,
    opacity: 0,
    type: 'from',
    ease: 'easeOutQuad',
  }
}