import React from "react";

export const FooterDataSource = {
  wrapper: {className: 'footer-wrapper'},
  OverPack: {className: 'footer', playScale: 0.2},
  block: {
    className: 'home-page-footer',
    children: [
      {
        name: 'block0',
        xs: 24,
        md: 7,
        className: 'block',
        title: {
          className: 'logo',
          children:
            'https://lecturermap-1319224539.cos.ap-beijing.myqcloud.com/cdn/%E5%AE%98%E7%BD%91/logo.png',
        }
      },
      {
        name: 'block1',
        xs: 24,
        md: 3,
        className: 'block',
        title: {children: '产品'},
        childWrapper: {
          children: [
            {name: 'link0', href: '/product/consult', children: '企业咨询'},
            {name: 'link1', href: '/product/enterprise', children: '光合企学'},
          ],
        },
      },
      {
        name: 'block2',
        xs: 24,
        md: 3,
        className: 'block',
        title: {children: '解决方案'},
        childWrapper: {
          children: [
            {href: '/scheme', name: 'link0', children: '行业解决方案'},
            {href: '/scheme/theme', name: 'link1', children: '主题解决方案'},
          ],
        },
      },
      {
        name: 'block3',
        xs: 24,
        md: 3,
        className: 'block',
        title: {children: '关于我们'},
        childWrapper: {
          children: [
            {href: '/about', name: 'link0', children: '公司简介'},
            {href: '/about/join', name: 'link1', children: '加入我们'},
            {href: '/about/news', name: 'link2', children: '新闻中心'},
            {href: '/about/contact', name: 'link3', children: '联系我们'},
          ],
        },
      },
      {
        name: 'block4',
        xs: 24,
        md: 3,
        className: 'block',
        title: {children: '关注熙诚教育'},
        childWrapper: {
          children: [
            {
              className: 'qr-code',
              children: 'https://lecturermap-1319224539.cos.ap-beijing.myqcloud.com/cdn/%E5%AE%98%E7%BD%91/code_xcjy.png'
            },
          ]
        }
      },
      {
        name: 'block5',
        xs: 24,
        md: 3,
        className: 'block',
        title: {children: '关注光合企学'},
        childWrapper: {
          children: [
            {
              className: 'qr-code',
              children: 'https://lecturermap-1319224539.cos.ap-beijing.myqcloud.com/cdn/%E5%AE%98%E7%BD%91/code_ghqx.jpg'
            },
          ]
        }
      },
    ],
  },
  copyrightWrapper: {className: 'copyright-wrapper'},
  copyrightPage: {className: 'home-page-copyright'},
  copyright: {
    className: 'copyright',
    children: (
      <span>
        Copyright © 2020 熙诚教育. All Rights Reserved  <a href="http://beian.miit.gov.cn/" target="_blank">京ICP备20025090号-1</a> <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">京公网安备 11010202008872号</a>
      </span>
    )
  }
}