import React, { useState, useCallback, useRef } from 'react'
import { message } from 'antd'
import type { FormProps } from 'antd'
import { Modal, Button, Form, Input } from 'antd'
import Captcha from 'react-captcha-code'
import { solutionAdvisorySubmit } from '@/api/common'
import Close from '@/assets/images/close.png'
import './index.less'

const REG_PHONE =
  /^[1](([3][0-9])|([4][01456789])|([5][012356789])|([6][2567])|([7][0-8])|([8][0-9])|([9][012356789]))[0-9]{8}$/;


const modalStyles = {
  content: {
    background: '#FFFFFF',
    padding: '50px 60px 50px 50px',
    position: 'relative',
  },
};

type IProps = {
  isModalOpen: boolean
  handleCancel: () => void
}

type FieldType = {
  username?: string;
  password?: string;
  remember?: string;
};

const ModelRelation: React.FC<IProps> = (props) => {
  const [captcha, setCaptcha] = useState('')
  const captchaRef = useRef<HTMLCanvasElement>();
  const { isModalOpen, handleCancel, data} = props

  const onFinish: FormProps<FieldType>['onFinish'] = async (values) => {
    const result = await solutionAdvisorySubmit(values)
    if(result?.code === 20000) {
       message.success({content: '提交成功！'});
    }else {
      message.success({content: result?.msg});
    }
    handleCancel()
  };

  const onFinishFailed: FormProps<FieldType>['onFinishFailed'] = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const handleChange = useCallback((captcha) => {
    setCaptcha(captcha)
    console.log('captcha:', captcha);
  }, []);

  return (
    <Modal
      styles={modalStyles}
      open={isModalOpen}
      destroyOnClose={true}
      footer={null}
      closeIcon={null}
      centered={true}
      width={790}
    >
      <div className='model-header'>
        <img src={Close} onClick={handleCancel}/>
      </div>
      <div className='model-body'>
        <div className='model-body-title'>请留下您的联系方式</div>
        <div className='model-body-desc m-t4'>我们将尽快联系您，提供解决方案咨询服务</div>
        <Form
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          className='model-body-form'
        >
          <Form.Item<FieldType>
            label={
              <div className='label-name'>企业名称</div>
            }
            name="enterpriseName"
            rules={[{ required: true, message: '请输入企业名称！' }]}
          >
            <Input size="large" />
          </Form.Item>
          <Form.Item<FieldType>
            label={
              <div className='label-name'>姓名</div>
            }
            name="contactName"
            rules={[{ required: true, message: '请输入姓名！' }]}
          >
            <Input size="large" />
          </Form.Item>
          <Form.Item
            label={
              <div className='label-name'>手机号码</div>
            }
            name="phone"
            rules={[
              { required: true, message: '请输入手机号码！' },
              // { pattern: REG_PHONE, message: '手机号格式不正确！' }
            ]}
          >
            <Input size="large" />
          </Form.Item>
          <Form.Item
            label={
              <div className='label-name'>输入验证码</div>
            }
            name="validateCode"
            rules={[
              { required: true, message: '请输入验证码！' },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || value === captcha) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('验证码错误！'));
                },
              }),
            ]}
          >
            <div style={{display: 'flex'}}>
              <Input size="large" />
              <Captcha className='get-code flex-center' ref={captchaRef} charNum={6} onChange={handleChange} />
              {/*<div className='get-code flex-center'>获取验证码</div>*/}
            </div>
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 7, span: 16 }}>
            <Button type="primary" htmlType="submit" className='form-btn'>
              提交
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  )
}

export default ModelRelation