import React from 'react'
import BannerOneMark from '@/assets/images/product/banner-one-mark.png'
import TriangleIcon from '@/assets/images/product/triangle-icon.png'

const Banner01 = () => {
  return (
    <div className='banner01'>
      <img src={BannerOneMark} width='100%' alt=""/>
      <img className='triangle-icon' src={TriangleIcon} alt=""/>
    </div>
  )
}

export default Banner01