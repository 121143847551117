import React from 'react'
import {Col, Row} from 'antd'
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack'
import QueueAnim from 'rc-queue-anim'
import TweenOne from 'rc-tween-one'

const Vision: React.FC = () => {
  const imgAnim: any = false
    ? {
      y: 30,
      opacity: 0,
      delay: 600,
      type: 'from',
      ease: 'easeOutQuad',
    }
    : {
      x: 30,
      opacity: 0,
      type: 'from',
      ease: 'easeOutQuad',
    };

  return (
    <div className='warp-about-vision home-page'>
      <div className='title'>
        我们的使命与愿景
      </div>
      <OverPack playScale={0.3} component={Row} gutter={[12, 12]}>
        <QueueAnim
          key="text"
          type="left"
          leaveReverse
          ease={['easeOutQuad', 'easeInQuad']}
          component={Col}
          md={12}
        >
          <QueueAnim
            component="ul"
            key="ul"
            type="left"
            ease="easeOutQuad"
          >
            <div className='vision-left'>
              <div className='vision-left-title'>我们的使命</div>
              <div className='desc'>传递前沿思想，引领中国奋进英才，成就企业走向世界中心。</div>
            </div>
          </QueueAnim>
        </QueueAnim>
        <TweenOne
          key="img"
          animation={imgAnim}
          resetStyle
          component={Col}
          md={12}
        >
          <div className='vision-right'>
            <div className='vision-right-title'>我们的愿景</div>
            <div className='desc'>
              <div>// 短期目标</div>
              <div>为企业提供着眼于未来的、健康的、可持续发展的解决方案。在企业家培养、数字化建设、经营与资本联动等领域树立行业标准</div>
              <div>// 长期目标</div>
              <div>为世界打造中国式企业管理典范</div>
            </div>
          </div>
        </TweenOne>
      </OverPack>
    </div>
  )
}

export default Vision