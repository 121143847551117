import React from 'react'
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack'
import QueueAnim from 'rc-queue-anim'
import TweenOne from 'rc-tween-one'
import { Col, Row } from 'antd'

const Work: React.FC = ({item}) => {
  const imgAnim: any = false
    ? {
      y: 30,
      opacity: 0,
      delay: 600,
      type: 'from',
      ease: 'easeOutQuad',
    }
    : {
      x: 30,
      opacity: 0,
      type: 'from',
      ease: 'easeOutQuad',
    };

  return (
    <div className='warp-join-detail-work home-page'>
      <OverPack playScale={0.2} component={Row} style={{display: 'flex', justifyContent: 'space-between'}}>
        <QueueAnim
          key="text"
          type="left"
          leaveReverse
          ease={['easeOutQuad', 'easeInQuad']}
          component={Col}
          md={12}
        >
          <QueueAnim
            component="ul"
            key="ul"
            type="left"
            ease="easeOutQuad"
          >
            <div className='title'>{item?.name}</div>
            {/*<div className='desc'>*/}
            {/*</div>*/}
          </QueueAnim>
        </QueueAnim>
        <TweenOne
          key="message"
          animation={imgAnim}
          resetStyle
          component={Col}
          md={10}
        >
          <Row>
            <Col className='message-block'>
              <div className='message-block-title'>
                学历要求
              </div>
              <div className='message-block-desc'>
                {item?.edu}
              </div>
            </Col>
            <Col className='message-block'>
              <div className='message-block-title'>
                工作年限
              </div>
              <div className='message-block-desc'>
                {item?.workAge}
              </div>
            </Col>
          </Row>
          <Row>
            <Col className='message-block'>
              <div className='message-block-title'>
                招聘人数
              </div>
              <div className='message-block-desc'>
                {item?.num}
              </div>
            </Col>
            <Col className='message-block'>
              <div className='message-block-title'>
                发布时间
              </div>
              <div className='message-block-desc'>
                {item?.time}
              </div>
            </Col>
          </Row>
        </TweenOne>
      </OverPack>
    </div>
  )
}

export default Work