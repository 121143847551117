import React, { useState } from 'react'
import { Row, Col } from 'antd'
import play from '@/assets/images/play.png'
import BannerLeft from '@/assets/images/about/banner-left.png'
import BannerCenter from '@/assets/images/about/banner-center.png'
import BannerRight from '@/assets/images/about/banner-right.png'
import ModelVideo from "@/components/ModelVideo";

const Banner: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [data, setData] = useState({
    cosKey: 'https://lecturermap-1319224539.cos.ap-beijing.myqcloud.com/cdn/video/%E6%9D%8E%E8%80%81%E5%B8%88.mp4'
  })
  const handlePlay = () => {
    setIsModalOpen(true)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  return (
    <>
      <Row gutter={10} className='warp-about-banner'>
        <Col md={11}>
          <div className='detail-news-video'>
            <div className='detail-news-mask flex-center' onClick={handlePlay}>
              <img src={play} alt=""/>
            </div>
            <img className='banner-left' src={BannerLeft} alt="" />
          </div>
          {/*<img className='banner-left' src={BannerLeft} alt="" />*/}
        </Col>
        <Col md={7}>
          <img className='banner-center' src={BannerCenter} alt="" />
        </Col>
        <Col md={6}>
          <img className='banner-right' src={BannerRight} alt="" />
        </Col>
      </Row>
      <ModelVideo isModalOpen={isModalOpen} handleCancel={handleCancel} data={data} />
    </>
  )
}
export default Banner