import React from 'react'
import Video from '@/components/Video'
import { Modal } from 'antd'
import CloseIcon from '@/assets/images/close-icon.png'
import './index.less'

const modalStyles = {
  content: {
    background: '#252525',
    padding: '80px',
    position: 'relative',
  },
};

type IProps = {
  isModalOpen: boolean
  handleCancel: () => void
  data: {
    cosKey: string
    pic?: string
  }
}

const ModelVideo: React.FC<IProps> = (props) => {
  const { isModalOpen, handleCancel, data} = props

  return (
    <Modal
      className='full-screen-modal'
      styles={modalStyles}
      open={isModalOpen}
      destroyOnClose={true}
      footer={null}
      closeIcon={null}
      centered={true}
    >
      <div className='video-header'>
        <img src={CloseIcon} width={'24px'} height={'24px'} alt="" onClick={handleCancel}/>
      </div>
      <Video {...data} autoplay={true} />
    </Modal>
  )
}

export default ModelVideo