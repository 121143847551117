import React, {useState} from 'react'
import { Row, Col, Dropdown } from 'antd'
import { useNavigate } from 'react-router-dom'

const NavbarModel: React.FC = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false)
  const navigate = useNavigate();
  const onOpenChange = (open) => {
    setIsOpen(open)
  }
  const onClick = () => {
    setIsOpen(false)
  }
  const handlePage = (path) => {
    navigate(path)
    setIsOpen(false)
  }
  return (
    <Dropdown
      placement="bottomLeft"
      open={isOpen}
      onOpenChange={onOpenChange}
      // onClick={onClick}
      dropdownRender={() =>
        <div className='__dropdown-nav'>
          <Row className='nav-bar' gutter={100}>
            <Col md={12} className='nav-col'>
              <div className={`nav-title ${location.pathname === '/product/consult' ? 'active' : ''}`} onClick={() => handlePage('/product/consult')}>管理咨询</div>
              <div className='nav-desc'>价值再造·赋能可持续发展</div>
              <div className='nav-list'>
                {/*<div className='nav-list-block'>项泽治略</div>*/}
                <div className={`nav-list-block ${location.pathname === '/product/wealth' ? 'active' : ''}`} onClick={() => handlePage('/product/wealth')}>财赋+</div>
                <div className={`nav-list-block ${location.pathname === '/product/mark' ? 'active' : ''}`} onClick={() => handlePage('/product/mark')}>演进1号位</div>
                {/*<div className='nav-list-block'>知识管理局</div>*/}
              </div>
            </Col>
            <Col md={12} className='nav-col'>
              <div className={`nav-title ${location.pathname === '/product/enterprise' ? 'active' : ''}`}  onClick={() => handlePage('/product/enterprise')}>光合企学</div>
              <div className='nav-desc'>聚焦成功经验·让战略高效落地</div>
              <div className='nav-list'>
                <div className={`nav-list-block ${location.pathname === '/product/tactic' ? 'active' : ''}`} onClick={() => handlePage('/product/tactic')}>战略</div>
                <div className={`nav-list-block ${location.pathname === '/product/organize' ? 'active' : ''}`} onClick={() => handlePage('/product/organize')}>组织</div>
                <div className={`nav-list-block ${location.pathname === '/product/talents' ? 'active' : ''}`} onClick={() => handlePage('/product/talents')}>人才</div>
                <div className={`nav-list-block ${location.pathname === '/product/study/1' ? 'active' : ''}`} onClick={() => handlePage('/product/study/1')}>素质研学</div>
                <div className={`nav-list-block ${location.pathname === '/product/visit' ? 'active' : ''}`} onClick={() => handlePage('/product/visit')}>标杆参访</div>
              </div>
            </Col>
          </Row>
        </div>
      }
    >
      {children}
    </Dropdown>
  )
}

export default NavbarModel