import React from 'react'
import TweenOne from 'rc-tween-one'
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack'
import QueueAnim from 'rc-queue-anim'
import { Row, Col } from 'antd';
import { getChildrenToRender, isImg } from '@/utils'
import Logo from '@/assets/images/logo-white.png'

type IProps = {
  dataSource: any
}

const Footer: React.FC<IProps> = (props) => {
  const getLiChildren = (data) =>
    data.map((item, i) => {
      const { title, childWrapper, ...itemProps } = item;
      return (
        <Col key={i.toString()} {...itemProps}>
          <section {...title}>
            {typeof title.children === 'string' &&
            title.children.match(isImg) ? (
              <img src={Logo} width="100%" alt="" />
            ) : (
              title.children
            )}
          </section>
          <div {...childWrapper}>
            {childWrapper?.children?.map?.(getChildrenToRender)}
          </div>
        </Col>
      );
    });

  const { dataSource } = props;
  const childrenToRender = getLiChildren(dataSource.block.children);

  return (
    <div {...props} {...dataSource.wrapper}>
      <OverPack {...dataSource.OverPack}>
        <QueueAnim
          type="bottom"
          key="ul"
          leaveReverse
          component={Row}
          {...dataSource.block}
        >
          {childrenToRender}
        </QueueAnim>
        <TweenOne
          animation={{ y: '+=30', opacity: 0, type: 'from' }}
          key="copyright"
          {...dataSource.copyrightWrapper}
        >
          <div {...dataSource.copyrightPage}>
            <div {...dataSource.copyright}>
              {dataSource.copyright.children}
            </div>
          </div>
        </TweenOne>
      </OverPack>
    </div>
  )
}


export default Footer