import React from 'react'
import { Row, Col } from 'antd'
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack'
import QueueAnim from 'rc-queue-anim'
import IconNote from '@/assets/images/scheme/icon-note.png'
import IconFrame from '@/assets/images/scheme/icon-frame.png'
import DownArrows from '@/assets/images/scheme/down-arrows.png'

import Ebg01 from '@/assets/images/scheme/e-bg01.png'
import Ebg02 from '@/assets/images/scheme/e-bg02.png'
import Ebg03 from '@/assets/images/scheme/e-bg03.png'
import Ebg04 from '@/assets/images/scheme/e-bg04.png'
import Ebg05 from '@/assets/images/scheme/e-bg05.png'
import Ebg06 from '@/assets/images/scheme/e-bg06.png'


const ElectricList = () => {
  return (
    <div className='wrap-bank-list'>
      <div className='wrap-bank-list-header'>
        <div className='wrap-bank-list-header-box home-page'>
          <img className='icon-note' src={IconNote} />
          <div className='wrap-title'>背景与需求分析</div>
        </div>
      </div>
      <div className='wrap-bank-list-summary' style={{borderBottom: 'none'}}>
        <div className='home-page'>
          <>
            <div className='title'>
              企业要求： <br/>
              <span>电力企业转型发展状况</span>
            </div>
            <div className='desc'>
              <span>机遇与挑战并存：</span>电力企业的低碳转型目前已经取得众多的优秀实践，但仍有众多的风险与挑战；光合企学将从电力企业低碳转型的内部挑战性因素入手，注重低碳战略转型与人才发展之间的关系，以人才推动电力企业长久发展
            </div>
            <div className='electric-data'>
              <div className='electric-data-left'>
                <div className='left-text-title'>电力企业转型的成功实践</div>
                <div className='left-text-desc-strong'>
                  1、顺应绿色低碳发展趋势，持续优化企业低碳发展策略 <br />
                  2、发挥创新驱动效能，企业低碳科技创新迈上新台阶 <br />
                  3、优秀实践
                </div>
                <div>
                  中国华能甘肃陇东多能互补综合能源基地开发 <br />
                  国家电投山东诸城分布式光伏开发 <br />
                  中国大唐山东东营超临界二次再热机组项目 <br />
                  国家能源集团“火电 +”服务模式创新 <br />
                  国家电投“光伏 +”服务模式 <br />
                  ……
                </div>
              </div>
              <div className='electric-data-right'>
                <div className='right-text-title'>电力企业发展转型挑战</div>
                <Row gutter={10} style={{marginBottom: '21px'}}>
                  <Col md={10}>
                    <div className='right-text-block'>
                      <div className='right-text-block-title'>
                        外部因素
                      </div>
                      <div className='right-text-block-desc'>
                        <span className='dot'></span>
                        <span>经营稳定性不足，尤其是煤电</span>
                      </div>
                      <div className='right-text-block-desc'>
                        <span className='dot'></span>
                        <span>经济下行对用电需求的影响</span>
                      </div>
                      <div className='right-text-block-desc'>
                        <span className='dot'></span>
                        <span>定价形成机制有待完善</span>
                      </div>
                      <div className='right-text-block-desc'>
                        <span className='dot'></span>
                        <span>政策调整较频繁</span>
                      </div>
                      <div className='right-text-block-desc'>
                        <span className='dot'></span>
                        <span>……</span>
                      </div>
                      <div className='right-text-block-tips flex-center'>
                        不确定性高
                      </div>
                    </div>
                  </Col>
                  <Col md={14}>
                    <div className='right-text-block'>
                      <div className='right-text-block-title'>
                        内部因素
                      </div>
                      <div className='right-text-block-desc'>
                        <span className='dot'></span>
                        <span>绿色低碳理念需要与企业发展战略深度融合</span>
                      </div>
                      <div className='right-text-block-desc'>
                        <span className='dot'></span>
                        <span>电力低碳转型关键核心技术攻关（专业层面）</span>
                      </div>
                      <div className='right-text-block-desc'>
                        <span className='dot'></span>
                        <span>员工需要更强的转型意识及配套的管理动作（管理层面）</span>
                      </div>
                      <div className='right-text-block-desc'>
                        <span className='dot'></span>
                        <span>数字化智能化技术的内部应用需要进一步加深</span>
                      </div>
                      <div className='right-text-block-desc'>
                        <span className='dot'></span>
                        <span>企业低碳转型实践案例和管理经验的沉淀分享</span>
                      </div>
                      <img className='down-arrows' src={DownArrows} alt=""/>
                      <div className='right-text-block-tips flex-center'>
                        可控性强，持续加大管控
                      </div>
                    </div>
                  </Col>
                </Row>
                <div className='right-text-title'>加大内部管控的三大关键因素</div>
                <OverPack playScale={0.2}>
                  <QueueAnim
                    key="text"
                    type="bottom"
                    leaveReverse
                    ease={['easeOutQuad', 'easeInQuad']}
                    component={Row}
                    gutter={10}
                  >
                    <Col md={8} key='1'>
                      <div className='right-text-box'>
                        <div className='right-text-box-title'>
                          经营战略
                        </div>
                        <div>
                          加强落实“十四五发展规划”对电力企业的具体要求，将绿色低碳的概念深入企业发展战略中，加强企业人员的全局发展意识，建立可持续发展观，将电力企业转型改革的每一个关键步骤都落地到位
                        </div>
                      </div>
                    </Col>
                    <Col md={8}  key='2'>
                      <div className='right-text-box'>
                        <div className='right-text-box-title'>
                          经营团队
                        </div>
                        <div>
                          只有搭建好基、中、高不同层级的核心经营团队，电力企业的改革才能真正落到实处，确保人有所用、人有所长，持续的加强员工的基础素养、管理素养与专业素养，建设出能打胜仗的核心管理团队
                        </div>
                      </div>
                    </Col>
                    <Col md={8}  key='3'>
                      <div className='right-text-box'>
                        <div className='right-text-box-title'>
                          经营业务
                        </div>
                        <div>
                          经营思维是未来电力行业具有竞争性的员工的必备条件，只有了解电力行业企业经营运作规律，具有市场经营意识，了解最新业务发展方向，充分掌握各种技术的深度应用，才能始终助力电力企业走的更深更远
                        </div>
                      </div>
                    </Col>
                  </QueueAnim>
                </OverPack>
              </div>
            </div>
          </>
          <OverPack playScale={0.2}>
            <QueueAnim
              key="text"
              type="bottom"
              leaveReverse
              ease={['easeOutQuad', 'easeInQuad']}
              style={{minHeight: '549px'}}
            >
              <div className='title' style={{margin: '60px 0 16px'}} key='title'>
                人才发展计划：<br/>
                <span>三大发展关键要素模型</span>
              </div>
              <div className='desc' style={{marginBottom: '53px'}} key='desc'>
                光合企学从电力企业转型的内部可控影响因素入手，找到三大关键发展因素，并且基于人才战略需求，在坚持正确政治方向的前提下，根据经营战略、经营团队、经营业务三大维度提炼出电力行业培养项目的核心培养模型作为后续课程设计的参考
              </div>
              <div className='electric-content flex-center' key='img' >
                <img src={Ebg01} width='580px' height='401px' />
                <div className='electric-content-left'>
                  <span>
                    战略的发展离不开团队的经营，体系化管理进阶是必不可少的条件
                  </span>
                  市场导向的体系化管理，公司运营 <br />
                  用户中心导向 <br />
                  团队融合&跨部门协作能力 <br />
                  沟通影响力 <br />
                  ……
                </div>
                <div className='electric-content-right-t'>
                  <span>
                    电力行业业务发展视角要求具备全方位战略引领及各维度深度融合能力
                  </span>
                  战略规划与战略思维 <br />
                  战略落地与战略执行 <br />
                  全局思维 <br />
                  人才战略&梯队建设 <br />
                  ……
                </div>
                <div className='electric-content-right-b'>
                  <span>
                    具有市场经营意识，了解最新业务发展方向，充分掌握各种技术的深度应用
                  </span>
                  创新意识 <br />
                  组织活力激发 <br />
                  开放性思维 <br />
                  全球化视角 <br />
                  ……
                </div>
              </div>
            </QueueAnim>
          </OverPack>
          <OverPack playScale={0.2}>
            <QueueAnim
              key="text"
              type="bottom"
              leaveReverse
              ease={['easeOutQuad', 'easeInQuad']}
              style={{minHeight: '746px'}}
            >
              <div className='title' style={{margin: '118px 0 13px'}} key='title'>
                人才发展计划： <br />
                <span>对标战略与人才理论要求</span>
              </div>
              <div className='desc' style={{marginBottom: '32px'}} key='desc'>
                对标电力企业通用战略层面的要求及人才发展理论体系，细化电力企业内部人才培养维度，构建具有创新活力和全方位适应市场竞争的人才队伍，强化人才结构化和体系化建设。
              </div>
              <img src={Ebg02} width='1200px' height='547px'  key='img' />
            </QueueAnim>
          </OverPack>
          <OverPack playScale={0.2}>
            <QueueAnim
              key="text"
              type="bottom"
              leaveReverse
              ease={['easeOutQuad', 'easeInQuad']}
              style={{minHeight: '782px'}}
            >
              <div className='title' style={{margin: '80px 0 16px'}} key='title'>
                人才发展计划： <br />
                <span>根据模型及理论要求设计差异化人才发展计划</span>
              </div>
              <div className='desc' style={{marginBottom: '47px'}} key='desc'>
                以三个层级项目整体设计为出发点，区别不同项目立意,各层级的项目特色、内容及培养形式均有差异化体现
              </div>
              <img src={Ebg03} alt="" width='1200px' height='596px' key='img' />
            </QueueAnim>
          </OverPack>
        </div>

      </div>
      <div className='wrap-bank-list-header'>
        <div className='wrap-bank-list-header-box home-page'>
          <img className='icon-frame' src={IconFrame} />
          <div className='wrap-title'>解决思路与实施框架</div>
        </div>
      </div>
      <div className='wrap-bank-list-summary' style={{borderBottom: 'none'}}>
        <div className='home-page'>
          <OverPack playScale={0.2}>
            <QueueAnim
              key="text"
              type="bottom"
              leaveReverse
              ease={['easeOutQuad', 'easeInQuad']}
              style={{minHeight: '820px'}}
            >
              <div className='title' style={{margin: '8px 0 16px'}} key='title'>
                <span>高层管理者</span> <br/>
                项目实施框架
              </div>
              <div className='desc' style={{marginBottom: '31px'}} key='desc'>
                基于高层管理者培养项目核心设计思路与理念，形成整体初步项目实施框架图（仅供参考）
              </div>
              <img src={Ebg04} width='1200px' height='650px' key='img' />
            </QueueAnim>
          </OverPack>
          <OverPack playScale={0.2}>
            <QueueAnim
              key="text"
              type="bottom"
              leaveReverse
              ease={['easeOutQuad', 'easeInQuad']}
              style={{minHeight: '793px'}}
            >
              <div className='title' style={{margin: '70px 0 16px'}} key='title'>
                <span>中层管理者</span> <br/>
                项目实施框架
              </div>
              <div className='desc' style={{marginBottom: '28px'}} key='desc'>
                基于中层管理者培养项目核心设计思路与理念，形成整体初步项目实施框架图（仅供参考）
              </div>
              <img src={Ebg05} width='1200px' height='626px' key='img' />
            </QueueAnim>
          </OverPack>
          <OverPack playScale={0.2}>
            <QueueAnim
              key="text"
              type="bottom"
              leaveReverse
              ease={['easeOutQuad', 'easeInQuad']}
              style={{minHeight: '836px'}}
            >
              <div className='title' style={{margin: '70px 0 16px'}} key='title'>
                <span>基层管理者</span> <br/>
                项目实施框架
              </div>
              <div className='desc' style={{marginBottom: '28px'}} key='desc'>
                基于基层管理者培养项目核心设计思路与理念，形成整体初步项目实施框架图（仅供参考）
              </div>
              <img src={Ebg06} width='1200px' height='668px' key='img' />
            </QueueAnim>
          </OverPack>
        </div>
      </div>
    </div>
  )
}

export default ElectricList