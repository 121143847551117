import React, {useState} from 'react'
import ExpandCollapse from "@/components/ExpandCollapse";
import IconLead from "@/assets/images/product/study/icon-lead.png";
import IconSpecial from "@/assets/images/product/study/icon-special.png";
import HighLevel from "@/assets/images/product/study/icon-high-level.png";
import BasicLevel from "@/assets/images/product/study/icon-basic-level.png";
import IconTime from "@/assets/images/product/study/icon-time.png";
import IconUser from "@/assets/images/product/study/icon-user.png";
import IconFlag from "@/assets/images/product/study/icon-flag.png";
import MiddleLevel from "@/assets/images/product/study/icon-middle-level.png";


const Talents: React.FC = () => {
  const [data, setData] = useState([{
    title: '领导力模块',
    pic: IconLead,
    content: [{
      title: '大道至拙:曾国藩与中国式领导力',
      tips: '人气',
      level: [{
        pic: MiddleLevel,
        name: '中层管理者'
      },{
        pic: HighLevel,
        name: '高层管理者'
      }],
      tag: [{
        pic: IconTime,
        name: '课程时长：',
        content: '1天'
      }, {
        pic: IconUser,
        name: '学员对象：',
        content: '中高层管理者'
      }, {
        pic: IconFlag,
        name: '授课形式：',
        content: '知识讲授 / 案例教学 / 讲师点评 / 模拟练习'
      }],
      base: [{
        title: '课程介绍',
        content: '本课程以曾国藩为本土领导力的典型案例，将曾国藩的为人处世之道提炼为十二个字，并结合相关示例及现代管理实践展开论述，解释了曾国藩对中国情景下领导行为的启发意义。通过对曾国藩自我提升、把握人性、号召人心、知人用人、教育子女等方面事例的解剖，来帮助学员感受本土领导力的深沉厚重，领悟中国社会文化情境下领导者应该具备的关键特质。'
      },{
        title: '课程收益',
        desc: [{
          text: '了解在中国文化和社会环境中，成功领导者应该遵循的十二条基本原则'
        }, {
          text: '掌握中国情景下领导者成功的必备要素和成就伟大领导者的共同特质'
        }, {
          text: '学习传统文化中儒家理想和追求，法家的清醒与现实，道家的成熟与灵活，从而提升自我领导力'
        }]
      }]
    },{
      title: '衍化录 1.0:领导力基点',
      tips: '上新-New',
      level: [{
        pic: BasicLevel,
        name: '基层管理者'
      }],
      tag: [{
        pic: IconTime,
        name: '课程时长：',
        content: '2-3天'
      }, {
        pic: IconUser,
        name: '学员对象：',
        content: '基层管理者、成为管理员2年内但未受过系统训练的管理者'
      }, {
        pic: IconFlag,
        name: '授课形式：',
        content: '知识讲授 / 案例教学 / 课程研讨'
      }],
      base: [{
        title: '课程介绍',
        content: '基层管理是所有管理者的第一课，是否打牢了管理基础，是未来能否在管理道路上持续发展的核心。\n' +
          '从个人贡献者到团队管理者，角色转变时会面临全新挑战，“面对新角色不知所措、没有系统管理意识、管理行为随机随性、做业务和做管理无法平衡”等等问题层出不穷，本课程作为《领导力衍化录》三部曲中的第一课，基于多行业、企业实际调研，沉淀典型基层管理者的痛点场景及解决方案，聚焦“小而精”，同时通过线上线下联动的方式，进一步提升知识学习及应用效率，帮助基层管理者打牢管理基本功，完成角色蜕变。'
      },{
        title: '课程收益',
        desc: [{
          text: '认知自身，转换思维模式，理解管理角色的不同'
        }, {
          text: '掌握最经典的管理工具，并在典型场景中应用迁移，真正完成行为改变'
        }, {
          text: '通过多样演练扎实打好管理动作基础，切实提高管理技巧'
        }]
      }]
    },{
      title: '衍化录 2.0：领导力演进',
      tips: '上新-New',
      level: [{
        pic: MiddleLevel,
        name: '中层管理者'
      }],
      tag: [{
        pic: IconTime,
        name: '课程时长：',
        content: '2-3天'
      }, {
        pic: IconUser,
        name: '学员对象：',
        content: '中高层管理者、高层后备人才'
      }, {
        pic: IconFlag,
        name: '授课形式：',
        content: '知识讲授 / 案例教学 / 课程研讨'
      }],
      base: [{
        title: '课程介绍',
        content: '中层作为企业中坚，是企业是否能持续发展的重要助推力量。是企业领导力水平的集中体现。\n' +
          '机械、套路式的管理动作已无法应对当前高度不确定的商业环境。本产品基于多行业、企业的实际调研，精准定位中层管理者的成功核心，并浓缩成三大模块，通过实景案例提升管理行为效能，并聚焦管理者自我内心洞察，实现管理者思维认知的再升级。帮助中层管理者完成角色跃迁，从 managerment 向 leadership 演进，为企业塑造未来领袖的人才池。'
      },{
        title: '课程收益',
        desc: [{
          text: '瞄准企业“腰无力”，建立中层管理者面向未来的管理视角'
        }, {
          text: '全面掌握核心管理场景下的管理方法论'
        }, {
          text: '针对实际挑战的反复演练，切实催生行为转变'
        }]
      }]
    },{
      title: '职场成长加速器',
      tips: '上新-New',
      level: [{
        pic: BasicLevel,
        name: '基层管理者'
      }],
      tag: [{
        pic: IconTime,
        name: '课程时长：',
        content: '2-3天'
      }, {
        pic: IconUser,
        name: '学员对象：',
        content: '基层员工'
      }, {
        pic: IconFlag,
        name: '授课形式：',
        content: '案例分析 / 课程研讨 / 游戏体验 / 知识讲解'
      }],
      base: [{
        title: '课程介绍',
        content: '围绕基层员工主要工作任务中的九大常见挑战，从自我管理、人际管理和业务管理三大角度出发，以情境案例或体验式教学形式进行剖析，引导学员发现问题背后的奥秘，建立职场核心竞争力，做通透、高效的职场人。'
      },{
        title: '课程收益',
        desc: [{
          name: '课程内容：',
          text: '遵循二八法则，覆盖基层员工主要的工作任务'
        }, {
          name: '课程案例：',
          text: '贴近实际工作场景，更容易激发学习兴趣'
        }, {
          name: '授课形式：',
          text: '全程互动，摆脱“填鸭式”授课，打造学员更喜欢的教学方式'
        }, {
          name: '配套工具：',
          text: '易用、有效，可直接应用在日常工作中'
        }]
      }]
    },{
      title: '新员工成长加速营',
      tips: '上新-New',
      level: [{
        pic: BasicLevel,
        name: '基层管理者'
      }],
      tag: [{
        pic: IconTime,
        name: '课程时长：',
        content: '1-4 天'
      }, {
        pic: IconUser,
        name: '学员对象：',
        content: '校招新员工、入职 3 年以内、未经过系统培训的基层员工'
      }, {
        pic: IconFlag,
        name: '授课形式：',
        content: '知识讲授 / 拓展训练 / 商业即兴'
      }],
      base: [{
        title: '课程介绍',
        content: '按照奥尔德弗 ERG 需要理论，新员工初入职场有三大需要，分别为生存需要、人际关系需要和成长发展需要。《新员工成长加速营》围绕这“三大需要”对应设计了新员工所需的“三重融入”，即融入企业、融入团队和融入职场。通过多元培训手段，助力新员工加速转型、实现更好的职业成长与发展。'
      },{
        title: '课程亮点',
        desc: [{
          name: '系统性：',
          text: '全面覆盖新员工必备职场技能'
        }, {
          name: '有效性：',
          text: '落地工具有效解决新员工挑战'
        }, {
          name: '针对性：',
          text: '针对 Z 世代新员工特征设计运营'
        }, {
          name: '趣味性：',
          text: '新颖多元的教学模式全面提升参与度'
        }]
      }]
    },{
      title: '故事领导力',
      tips: '版权课',
      level: [{
        pic: MiddleLevel,
        name: '中层管理者'
      },{
        pic: HighLevel,
        name: '高层管理者'
      }],
      tag: [{
        pic: IconTime,
        name: '课程时长：',
        content: '1 天'
      }, {
        pic: IconUser,
        name: '学员对象：',
        content: '中层管理者（项目经理、新晋经理、团队主管）、高层管理者（CEO、总裁、区域总）'
      }, {
        pic: IconFlag,
        name: '授课形式：',
        content: '知识讲授 / 案例教学 / 实战研讨 / 模拟练习'
      }],
      base: [{
        content: ''
      },{
        title: '课程收益',
        content: '近十年来，讲故事的方式日渐被人们接受并广运用，让人们信服，成为学习、发展、促进变革的时尚方式。故事不仅被应用于组织战略和企业文化传承，还被广泛应用于销售，营销， 领导力等众多领域。学习本课程您将：',
        desc: [{
          text: '通过故事型组织的诠释，帮助领导者转换审视企业的视角，看到组织的全貌'
        }, {
          text: '了解故事型组织营造的流程与要素，帮助领导者营造员工自愿融入的组织环境'
        }, {
          text: '掌握领导力时刻对于领导者的意义，提升组织内领导力的效能'
        }, {
          text: '学会运用打磨领导力故事的方法，成为会讲故事的领导者，影响激励更多人'
        }, {
          text: '学习故事锦囊的结构，帮助企业建立符合文化价值观的故事宝藏'
        }]
      }]
    },{
      title: '情商领导力',
      tips: '版权课',
      level: [{
        pic: MiddleLevel,
        name: '中层管理者'
      },{
        pic: HighLevel,
        name: '高层管理者'
      }],
      tag: [{
        pic: IconTime,
        name: '课程时长：',
        content: '2 天'
      }, {
        pic: IconUser,
        name: '学员对象：',
        content: '中高层管理者'
      }, {
        pic: IconFlag,
        name: '授课形式：',
        content: '知识讲授 / 案例教学 / 实战研讨 / 模拟练习'
      }],
      base: [{
        title: '课程介绍',
        content: '情商是绩效的源动力：情商的商业案例表明，创新型的组织正在通过情商提升销售、领导力和团队绩效方面的经济价值作为管理者，有能力解读和管理自己情感与行为，同时精准地察觉他人的动机与情绪反应，对于提升团队的表现至关重要。某知名商业调查机构数据表明：善用情绪作为资源的管理者在打造高效团队的四个维度（人员流动、生产效率、客户满意度及盈利能力），都远远高于不善用情绪作为资源的管理者。\n' +
          '本课程源于美国 6 秒钟情商机构研发成果。将当今人类在高速发展社会中的识别情绪，引导思考，作出最佳决策并推动执行的能力定义为情商能力。情商是一种行动力，《情商领导力》将这种行动力创造性地整合出三大维度八项能力。识别情绪（清楚地认识自己的感觉和行为。情绪是数据，所以要准确地搜集这些信息）、选择情绪（有意识表达自己行为。不是“自动导航”式的被动反应，而是停下来经过选择后的主动回应）、超越情绪（为一个理由而做。将你的愿景和使命付诸于日常的行动之中，赋予目的感和正能量）。'
      },{
        title: '课程收益',
        desc: [{
          text: '了解和学习 EQ 的基础理论知识'
        }, {
          text: '通过情商领导力客制化发展指南，了解自己的情商现状'
        }, {
          text: '通过情商工具了解自己在管理中有挑战性的模式，深入自我反思'
        }, {
          text: '通过情商领导力客制化发展指南中的工具，练习并掌握如何调整和改变模式的方法（内在层面）'
        }, {
          text: '通过情商经理人客制化发展指南和工具帮助深入了解自我驱动来源，和掌握了解他人驱动来源的方法'
        }, {
          text: '通过整合 6 秒钟的 8 项能力，成就更优秀的领导者'
        }]
      }]
    },{
      title: '领越® 领导力',
      tips: '版权课',
      level: [{
        pic: MiddleLevel,
        name: '中层管理者'
      },{
        pic: HighLevel,
        name: '高层管理者'
      }],
      tag: [{
        pic: IconTime,
        name: '课程时长：',
        content: '2 天'
      }, {
        pic: IconUser,
        name: '学员对象：',
        content: '中高层管理者'
      }, {
        pic: IconFlag,
        name: '授课形式：',
        content: '知识讲授 / 案例分析 / 模拟练习 / 讲师点评'
      }],
      base: [{
        title: '课程介绍',
        content: '本课程通过多样式教学形式，探讨卓越领导者的五项行动 ：以身作则、共启愿景、挑战现状、促成行动、激励人心，从而剖析领导力的内涵，把企业的文化进一步行为化，帮助管理者掌握可操作的领导技能。'
      },{
        title: '课程收益',
        desc: [{
          text: '全面认识并找到自己当前阶段领导力的长短板，有针对性地提高领导能力，掌握领导力持续提升的方法、途径'
        }, {
          text: '建立领导者的信誉、使自己的行动与共同团队理念保持一致， 为团队树立榜样'
        }, {
          text: '提升领导者的前瞻能力，掌握如何更好传播团队基本价值观和理念，激发他人与自己分享共同愿景，增强团队执行力'
        }, {
          text: '培养领导者追求卓越精神，不断更新工作流程，寻找机会，带来团队发展壮大，并承担必要的风险'
        }, {
          text: '提升领导者带领团队的高效执行力，掌握整合体系的方法，推动组织目标'
        }, {
          text: '提升领导者激励他人、构建人才体系能力，增强团队凝聚力'
        }, {
          text: '将卓越领导者五种习惯行为应用到实际工作中，以应对具体的挑战并带领团队走向卓越'
        }]
      }]
    },{
      title: '克服团队协作的五种障碍',
      tips: '版权课',
      level: [{
        pic: MiddleLevel,
        name: '中层管理者'
      },{
        pic: HighLevel,
        name: '高层管理者'
      }],
      tag: [{
        pic: IconTime,
        name: '课程时长：',
        content: '2 天'
      }, {
        pic: IconUser,
        name: '学员对象：',
        content: '组织中真实团队、领导者组成的团队'
      }, {
        pic: IconFlag,
        name: '授课形式：',
        content: '工作坊 / 知识讲授 / 实战研讨 / 模拟练习'
      }],
      base: [{
        title: '课程介绍',
        content: '拥有一个高凝聚力、高战斗力、高绩效的团队是每个领导者的梦想和治理方向。但是打造高绩效的团队的关键是什么呢？关键取决于核心团队的高效协作能力！如何做才能使团队成员凝聚在一起，进而使他们各自发挥优势，从而打造一个既聪明又健康的团队，继而创造奇迹呢？信息时代，团队的高效协作已成为唯一的一项可持续、有竞争力的优势！团队协作并不代表团队的一种美德，也不是让你感觉舒服的循循善诱或让你温暖的冬日甜点，而是一种战略选择！是一种由组织来做出的有目的的决策。两天的《克服团队协作的五种障碍》工作坊，系统地研讨涉及到提升团队协作的五大策略，通过大量的练习 , 让学员达成以下目标：',
        desc: [{
          text: '分析团队协作的现状'
        }, {
          text: '了解团队协作的必要条件，以及提高团队协作能力的途径与方法'
        }, {
          text: '在团队信任、冲突管理、承诺、责任、结果方面得到改进'
        }, {
          text: '掌握团队协作技能与工具，为持续提升团队协作能力打下基础'
        }]
      },{
        title: '课程收益',
        desc: [{
          text: '透过针对性的训练帮助团队成员在五个基本要素方面加以改善：信任、冲突、承诺、责任和结果'
        }, {
          text: '帮助学员深刻理解团队协作的要素，更能够引导他们掌握技能和工具，学习在工作中克服障碍'
        }, {
          text: '通过学习，团队成员能够增强凝聚力，提升工作绩效'
        }, {
          text: '提升成员的敬业度、组织健康度，进而建立一支高凝聚力的领导团队'
        }]
      }]
    },{
      title: '贝尔宾打造高绩效团队',
      tips: '人气',
      level: [{
        pic: MiddleLevel,
        name: '中层管理者'
      },{
        pic: HighLevel,
        name: '高层管理者'
      }],
      tag: [{
        pic: IconTime,
        name: '课程时长：',
        content: '2 天'
      }, {
        pic: IconUser,
        name: '学员对象：',
        content: '中高层管理者'
      }, {
        pic: IconFlag,
        name: '授课形式：',
        content: '知识讲授 / 案例教学 / 模拟练习 / 实战研讨 / 讲师点评'
      }],
      base: [{
        title: '课程介绍',
        content: '贝尔宾团队角色可以提供一种系统性的工具方法来帮助管理者识别个体优势和偏好，并讨论工作中的需求和发展领域。本课程将讲述个人与团队发展之间的关系，帮助管理者学习如何打造一支高绩效团队，并为其制定行动计划，从而有效的解决团队发展各阶段的问题。',
      },{
        title: '课程收益',
        desc: [{
          text: '帮助管理者探索自我认知'
        }, {
          text: '增进人际间沟通了解，从“物理反应”到“化学反应”'
        }, {
          text: '聚焦业务，建立共同的组织语言'
        }, {
          text: '分析团队构建与目标的关系、分析团队优劣势，制定发展计划'
        }]
      }]
    },{
      title: '影响力密码© 打开协作中的锁',
      tips: '版权课',
      level: [{
        pic: BasicLevel,
        name: '基层管理者'
      },{
        pic: MiddleLevel,
        name: '中层管理者'
      }],
      tag: [{
        pic: IconTime,
        name: '课程时长：',
        content: '1-2 天'
      }, {
        pic: IconUser,
        name: '学员对象：',
        content: '中层管理者、基层管理者、需要发挥影响力的非管理岗位'
      }, {
        pic: IconFlag,
        name: '授课形式：',
        content: '知识讲授 / 视频教学 / 案例分析 / 模拟练习'
      }],
      base: [{
        title: '课程介绍',
        content: '在工作中你是否经常遇到以下问题：\n' +
          '无法得到团队的支持？无法影响合作伙伴更好配合？他人不愿意支持你的工作 ? 得不到跨部门的有效支持？\n' +
          '高影响力人士能够高效地运用关键影响力的技巧来影响他人，让工作目标得以实现。他们能够让被影响者看到他们自己在需要配合工作中能够得到的价值和收益，找到关键点，事半功倍推动工作进展，充分掌握并灵活运用多种影响力策略来影响不同的人。《影响力密码》是解决“如何在团队内部或者跨部门，跨层级，发挥影响力，有效推动工作进展、项目执行、战略落地”的影响力工具。',
      },{
        title: '课程收益',
        desc: [{
          name: '项目细节把控：',
          text: '复杂项目中对执行细节掌控和推动能力'
        }, {
          name: '项目执行中抓重点攻难点的能力：',
          text: '制定明确目标，定位推动工作关键人、关键点和关键环节，识别克服推动阻力'
        }, {
          name: '提升工作效率：',
          text: '解决工作中反复出现的“钉子”问题，不在复杂的协作环境中拖沓时间'
        }, {
          name: '人际交往能力：',
          text: '在项目中逐渐提升合作的技巧和能力'
        }, {
          name: '拓宽职业前景：',
          text: '在跨部门协作中最大化发挥个人影响力'
        }]
      }]
    },{
      title: '跨部门协作与影响',
      level: [{
        pic: BasicLevel,
        name: '基层管理者'
      },{
        pic: MiddleLevel,
        name: '中层管理者'
      }],
      tag: [{
        pic: IconTime,
        name: '课程时长：',
        content: '1-2 天'
      }, {
        pic: IconUser,
        name: '学员对象：',
        content: '中基层管理者、骨干员工'
      }, {
        pic: IconFlag,
        name: '授课形式：',
        content: '知识讲授 / 案例教学 / 模拟练习'
      }],
      base: [{
        title: '课程介绍',
        content: '在大多数组织中，跨部门协作中的障碍都是大家期待化解的问题。 部门墙为何存在，这其中的深层次原因是什么？ 在跨部门项目中，如何才能找到双方的共同利益，促成共同目标的达成？ 在协作中，如何综合利用多种策略影响参与者，而不是仅靠制度、规定来要求他人？ 本课程将围绕上述问题展开探讨。 本课程不会单纯聚焦于人际沟通与影响的技巧，而是希望帮助学员深入理解组织中协作障碍产生的原因，增加组织敏感度及全局意识，综合运用多种策略促进跨部门协作的成功。',
      },{
        title: '课程收益',
        desc: [{
          name: '心态：',
          text: '理解跨部门协作中的壁垒背后的原因，建立主动积极影响他人、推动结果达成的意识'
        }, {
          name: '技能：',
          text: '掌握跨部门影响与沟通策略'
        }, {
          name: '应用：',
          text: '可以根据不同的情境及资源选择适合的影响与沟通策略，达成目标'
        }]
      }]
    },{
      title: '故事思维',
      tips: '版权课',
      level: [{
        pic: BasicLevel,
        name: '基层管理者'
      },{
        pic: MiddleLevel,
        name: '中层管理者'
      }],
      tag: [{
        pic: IconTime,
        name: '课程时长：',
        content: '2 天'
      }, {
        pic: IconUser,
        name: '学员对象：',
        content: '中基层管理者、骨干员工'
      }, {
        pic: IconFlag,
        name: '授课形式：',
        content: '知识讲授 / 案例教学 / 模拟练习'
      }],
      base: [{
        title: '课程介绍',
        content: '站在众人面前说话，是走向成功的第一步。尽管拥有很多经验和知识，演讲就是不精彩不吸引人；因为没掌握沟通技巧，而在生活或商务谈判时被迫一让再让；因为沟通不到位而错过机会、损失金钱、人际关系紧张；说明你需要形成自己的一套故事思维，说故事并非与生俱来的天赋，本课程可以学习和逐步提高的思维模式，提高个人故事影响力。',
      },{
        title: '课程收益',
        desc: [{
          text: '掌握如何构建不同工作场景的故事'
        }, {
          text: '学会如何让故事打动人心'
        }, {
          text: '迅速掌握讲好故事的秘籍'
        }, {
          text: '用故事思维提升个人的沟通影响力'
        }]
      }]
    },{
      title: '优势思维——问题分析与解决',
      level: [{
        pic: BasicLevel,
        name: '基层管理者'
      },{
        pic: MiddleLevel,
        name: '中层管理者'
      }],
      tag: [{
        pic: IconTime,
        name: '课程时长：',
        content: '2 天'
      }, {
        pic: IconUser,
        name: '学员对象：',
        content: '中基层管理者、骨干员工'
      }, {
        pic: IconFlag,
        name: '授课形式：',
        content: '知识讲解 / 实战研讨 / 案例分析 / 讲师点评'
      }],
      base: [{
        title: '课程介绍',
        content: '跳出传统的线性思维通用套路；突破表面现象，深挖问题背后的问题；在传统的流程中创新性的采用行动学习技术，帮助学员掌握最先进使用方法论。',
      },{
        title: '课程收益',
        desc: [{
          text: '形势变换下冷静掌控业务的思路'
        }, {
          text: '利用五步问题分析法形成高效解决问题的习惯'
        }, {
          text: '掌握实用的问题细分方法和问题解决技巧'
        }, {
          text: '掌握原因比较、决策选择、危机预测和创新思路'
        }]
      }]
    },{
      title: '从管理者到经营者的财务思维',
      level: [{
        pic: MiddleLevel,
        name: '中层管理者'
      },{
        pic: HighLevel,
        name: '高层管理者'
      }],
      tag: [{
        pic: IconTime,
        name: '课程时长：',
        content: '1-2 天'
      }, {
        pic: IconUser,
        name: '学员对象：',
        content: '中基层管理者、管理者后备'
      }, {
        pic: IconFlag,
        name: '授课形式：',
        content: '知识讲授 / 案例教学'
      }],
      base: [{
        title: '课程介绍',
        content: '企业的管理者和经营者有什么不同，作为企业高管，你是否知道老板看中什么指标？公司到底如何才能赚钱？账上有利润，为什么现金在减少？本课程将从管理者到经营者的转型出发，围绕着经营者所需掌握的重点财务知识，提升管理者的财务思维。',
      },{
        title: '课程收益',
        desc: [{
          text: '转变：管理者到经营者'
        }, {
          text: '与老板（领导）同频思维'
        }, {
          text: '获得业务决策必备的财务思维'
        }, {
          text: '对资金与资金成本产生敏感度'
        }]
      }]
    },{
      title: '人人都是创新官 ―提升创新思维与创新能力',
      level: [{
        pic: BasicLevel,
        name: '基层管理者'
      },{
        pic: MiddleLevel,
        name: '中层管理者'
      }],
      tag: [{
        pic: IconTime,
        name: '课程时长：',
        content: '2 天'
      }, {
        pic: IconUser,
        name: '学员对象：',
        content: '企业中基层管理者及员工（包括产品、服务、生产、运营、营销及其他职能团队管理者及员工，项目经理及项目成员），创业者，以及其他任何希望借助创新能力实现工作绩效及职业发展突破的人'
      }, {
        pic: IconFlag,
        name: '授课形式：',
        content: '知识讲授 / 案例教学 / 实战研讨 / 模拟练习'
      }],
      base: [{
        title: '课程介绍',
        content: '随着市场竞争持续加剧和多变的客户需求，企业经营面临空前压力。员工仅仅能够常规化完成工作任务，已经不能满足企业应对外部激烈市场竞争和内部新型企业管理与运营的要求。因此未来的优秀企业，都在积极帮助员工开启创新心智，强化培养各岗位员工的创新能力。\n' +
          '本课程在设计思维方法论的基础上，融入脑科学、认知心理学、创新心理学、培训与引导技术，结合原理与理念讲授、案例分析、小组研讨、练习体验等多种培训手段，全面解析创新的心法与技法，一步到位的全面系统地帮助学习者建立以客户为中心的创新认知，掌握能操作可落地的流程化的创新工具与方法，能够在日常工作中根据需要自行或带领团队开展创新活动。',
      },{
        title: '课程收益',
        desc: [{
          text: '理清创新相关的概念，打通创新认知体系，有效指导创新行动，提升创新意识与自信'
        }, {
          text: '掌握系统的创新流程、方法和工具，理清活动关键节点和里程碑，让创新成为有路径可遵循、有原则标准指导的过程'
        }, {
          text: '掌握基于情境的客户需求分析模型，提升基于工作任务的创新意识与需求洞察力'
        }, {
          text: '掌握经典实用的创意思维能力强化训练方法，突破思维定势，提升创意会议效率与质量'
        }, {
          text: '掌握快速原型法，在创新早期测试关键建设，敏捷迭代方案，降低风险与成本'
        }, {
          text: '运用系统与生态思维，探寻方案落地模式，制定试点测试计划，让创新从方案变行动'
        }, {
          text: '建立用户视角，突破组织筒仓效应，汇聚多元视角，促进跨部门的沟通和共识，提升内部协作水平'
        }]
      }]
    },{
      title: 'VUCA 时代，打造稳定的情绪资本',
      level: [{
        pic: BasicLevel,
        name: '基层管理者'
      },{
        pic: MiddleLevel,
        name: '中层管理者'
      }],
      tag: [{
        pic: IconTime,
        name: '课程时长：',
        content: '1-2 天'
      }, {
        pic: IconUser,
        name: '学员对象：',
        content: '中基层管理者、骨干'
      }, {
        pic: IconFlag,
        name: '授课形式：',
        content: '知识讲授 / 案例教学 / 模拟练习'
      }],
      base: [{
        title: '课程介绍',
        content: 'VUCA 时代已经到来，不确定、复杂、模糊的环境总是能引起情绪的变化。世界瞬息万变，人们很容易产生焦虑、担忧、恐惧、易激惹和抑郁的情绪，一旦调整不好，都会影响到个人的行为。同时，企业的发展前景、管理者的工作状态、同事之间的人际关系、工作压力、职位晋升、薪酬状况及在企业中的安全感，甚至个人家庭生活的和谐与否、父母的身体状况等，都会给在组织中的成员带来压力。一般来说，处于情绪低潮的员工工作效率将会大大下降，暴躁的情绪还会造成企业员工的内耗，抵触情绪的滋生会导致工作效率降低。 稳定的情绪管理已经成为企业在复杂多变的竞争环境中，开始不断珍视的重要资本。它是个人达成绩效、团队成员间相互信任、高效协作的基石。该课程的研发基于认知行为理论、危机干预的精彩案例总结与实践，借助英国情绪管理工具“Blobtree”、美国heartmath 心能研究所情绪检测仪等可视化情绪管理工具，从而用健康的情绪推动组织绩效的成长，更新领导者的思维模式，激发员工的创造力，更好地适应模糊、多变的商业环境。',
      },{
        title: '课程特色',
        content: '课程让学员深刻意识到压力管理和情绪管理的重要性，并主动关注自己的情绪健康以及面对压力的应对能力，掌握持续有效的自我减压技术，养成良好的情绪压力转换习惯，阻断不良情绪及不良压力对自己的影响，进而积蓄积极的情绪能量，选择积极的沟通协调方式，为整个组织提升绩效。\n' +
          '与市面上常规的压力管理课程不同的是，我们的课程更注重具体控制情绪的方法，调控情绪的重要方法将用课程 50% 以上的时间来介绍和练习。',
        desc: [{
          text: '全球领先的情绪与压力管理研究成果'
        }, {
          text: '可视化情绪反馈仪器辅助，能让调节技巧的效果立竿见影（一天及以上版本的课程才会使用）'
        }, {
          text: '体验游戏互动的感悟和分享'
        }, {
          text: '视频情景案例学习 + 模拟体验 + 讨论 + 讲授 + 练习'
        }]
      }]
    },{
      title: '点时成金——时间与目标管理',
      tips: ['沙盘', '版权课'],
      level: [{
        pic: BasicLevel,
        name: '基层管理者'
      },{
        pic: MiddleLevel,
        name: '中层管理者'
      }],
      tag: [{
        pic: IconTime,
        name: '课程时长：',
        content: '2 天'
      }, {
        pic: IconUser,
        name: '学员对象：',
        content: '中基层管理者、骨干'
      }, {
        pic: IconFlag,
        name: '授课形式：',
        content: '知识讲授 / 案例教学 / 实战研讨 / 模拟练习'
      }],
      base: [{
        title: '课程介绍',
        content: '你是一个企业的中层经理，管理着大约 10 人的团队，向上对总监汇报。虽然公司和你个人都倡导并期望“Work--Life Balance”，但是每天的日常事务实在让你自顾不暇。为了改变现状，你决心给自己的时间及事件进行规划，限期六个月。这六个月中，你会遇到一个个的诱惑及干扰，结果看似清晰，却又有些遥不可及……\n' +
          '“点时成金”沙盘游戏，旨在说明每个员工在公司内部都有一个身份和角色，而身份角色背后就是每个人所需要完成的日常任务或者关键任务。那么如何选择这些关键任务的优先级，就成了一个企业人最重要的能力。\n' +
          '其设计理念，建立在如下几个经典元素的基础之上：1、个人平衡计分卡的四个衡量要素； 2、马斯洛需求理论，促动自我激励； 3、经典电影《In Time 时间规划局》。本沙盘模拟包含“任务类别、任务事项、计划、实施、资源、收益、衡量指标”等几大关键要素，使每个人都能更清楚的看到自己的工作全局，主动的管理好宝贵的时间，把普通化为神奇，点时成金。',
      },{
        title: '课程收益',
        desc: [{
          text: '自我激励，并规划自己的未来发展'
        }, {
          text: '掌握任务归类的思路和方法'
        }, {
          text: '掌握制定计划的方法与工具'
        }, {
          text: '理解“计划没有变化快，但是没有计划就没有变化”的基本理念'
        }, {
          text: '理性决策，注重投入与收益的比率'
        }, {
          text: '全面认知工作绩效指标和收益之间的关系'
        }]
      }]
    },{
      title: '绩效管理',
      level: [{
        pic: BasicLevel,
        name: '基层管理者'
      },{
        pic: MiddleLevel,
        name: '中层管理者'
      }],
      tag: [{
        pic: IconTime,
        name: '课程时长：',
        content: '1 天'
      }, {
        pic: IconUser,
        name: '学员对象：',
        content: '基层员工适用基础篇、管理者适用进阶篇'
      }, {
        pic: IconFlag,
        name: '授课形式：',
        content: '知识讲授 / 案例教学 / 模拟练习 / 实战研讨'
      }],
      base: [{
        title: '课程介绍',
        content: '通过课程帮助学员把握绩效管理的特点，加深对绩效管理基本概念和思想的认识 ；掌握企业常用绩效管理工具；掌握如何有效运作绩效管理的全过程（绩效指标制定、绩效实施、绩效考核及反馈、绩效结果运用）。',
      },{
        title: '课程亮点',
        desc: [{
          name: '实战教练指导：',
          text: '自我激励，并规划自己的未来发展'
        }, {
          name: '浓缩精华智慧：',
          text: '掌握任务归类的思路和方法'
        }, {
          name: '师生互动升华：',
          text: '掌握制定计划的方法与工具'
        }]
      }]
    },{
      title: '复盘工作坊',
      tips: '工作坊',
      level: [{
        pic: BasicLevel,
        name: '基层管理者'
      },{
        pic: MiddleLevel,
        name: '中层管理者'
      }],
      tag: [{
        pic: IconTime,
        name: '课程时长：',
        content: '2 天'
      }, {
        pic: IconUser,
        name: '学员对象：',
        content: '中基层管理者、高潜后备、骨干员工、OD、HR 管理者'
      }, {
        pic: IconFlag,
        name: '授课形式：',
        content: '知识讲授 / 实战研讨 / 案例教学 / 模拟练习 / 讲师点评'
      }],
      base: [{
        title: '课程亮点',
        desc: [{
          text: '与真实企业实际业务结合，贴合实战'
        }, {
          text: '工具模型的学习体验：群智共创、举一反三、现场实操'
        }, {
          text: '先操作体验、后工具复盘拆解、再抽象深化理论概念'
        }]
      },{
        title: '课程收益',
        desc: [{
          text: '掌握团队项目课题复盘的概念、原则以及核心方法论流程'
        }, {
          text: '掌握复盘方法论框架中的核心工具和模型'
        }, {
          text: '掌握目标管理的基本原则来制定合理的业务目标'
        }, {
          text: '经验教训的分析、萃取与迭代改善更加深入落地'
        }, {
          text: '增强复盘的意识，促进复盘的工作模式和文化的形成'
        }]
      }]
    },]
  },{
    title: '专业力模块',
    pic: IconSpecial,
    content: [{
      title: '战略人力资源管理',
      level: [{
        pic: MiddleLevel,
        name: '中层管理者'
      },{
        pic: HighLevel,
        name: '高层管理者'
      }],
      tag: [{
        pic: IconTime,
        name: '课程时长：',
        content: '1 天'
      }, {
        pic: IconUser,
        name: '学员对象：',
        content: '非人力资源部门的中高层管理者'
      }, {
        pic: IconFlag,
        name: '授课形式：',
        content: '知识讲授 / 案例教学'
      }],
      base: [{
        title: '课程介绍',
        content: '企业领导者必须关注对企业最为重要的资产―― 员工，并帮助员工充分发挥个人优势，从而提升整体组织绩效。战略性人力资源管理是组织为达到战略目标，系统地对人力资源各种部署和活动进行计划和管理的模式，是组织战略不可或缺的有机组成部分，是人力资源管理最重要组成部分之一。'
      },{
        title: '课程收益',
        desc: [{
          text: '了解管理者自身的人力管理角色'
        }, {
          text: '掌握自身培育下属的重要性与掌握多种培育下属的途径与方法'
        }, {
          text: '学习科学的绩效管理规范流程与掌握科学的绩效考核指标的方法与工具'
        }, {
          text: '具备科学的现代人力资源管理理念与实战技能'
        }]
      }]
    },{
      title: '业务领导者的人才管理课',
      level: [{
        pic: MiddleLevel,
        name: '中层管理者'
      },{
        pic: HighLevel,
        name: '高层管理者'
      }],
      tag: [{
        pic: IconTime,
        name: '课程时长：',
        content: '2 天'
      }, {
        pic: IconUser,
        name: '学员对象：',
        content: '非人力资源部门的中高层管理者'
      }, {
        pic: IconFlag,
        name: '授课形式：',
        content: '知识讲授 / 案例教学'
      }],
      base: [{
        title: '课程介绍',
        content: '管理大师拉姆 . 查兰说过：对利润负责的经理，需要亲自做过人力资源工作或者负责过人力资源部门。企业的经营包含两件事情：经营客户（战略经营）和经营员工（个人战略）。企业的各级业务领导者是企业经营的第一责任人，如果业务领导者不懂人才经营的方式和方法是不称职的。在企业管理中公司一把手总觉得中高层的团队不得力、不放心，可也没有有效的改进策略，同时觉得自己精力不够用，而中高层经理总觉得老板要求高，跟不上老板的思路，一时也不知道怎么提升；同时直觉下属团队能力不足、老化或者过于年轻，总是感觉是一个人拖着整个团队往前走，累心，但一时又找不到应对策略。本课程在此背景下开发，旨在帮助业务领导者建设体系化人才管理知识。'
      },{
        title: '课程收益',
        desc: [{
          text: '让经理人明确自己的第一角色是团队的人力资源经理，带人出活是本职工作之一'
        }, {
          text: '掌握人员选拔和团队组建的技巧，通过面试过程深入挖掘候选人的特质和技能水平'
        }, {
          text: '了解工作目标和工作计划的关系，在目标拆解的同时把控好资源配置，审核员工的关键行动措施是否靠谱到位，把控目标计划的闭环'
        }, {
          text: '基于能不能、愿不愿区分员工的工作状态，然后根据支持、指令两种工作方式把握好工作委派和辅导的分寸，快牛慢牛都用好'
        }, {
          text: '基于公司的激励逻辑做好下属的“打鸡血”工作，抛弃激励只靠涨工资、发奖金的老套路'
        }, {
          text: '给任务、拿结果，基于下属的职业生涯发展方向和 KPI 达成情况，给员工做绩效辅导与面谈'
        }]
      }]
    },{
      title: 'U 师 U 课：成果导向的课程开发',
      tips: '版权课',
      level: [{
        pic: BasicLevel,
        name: '基层管理者'
      },{
        pic: MiddleLevel,
        name: '中层管理者'
      },{
        pic: HighLevel,
        name: '高层管理者'
      }],
      tag: [{
        pic: IconTime,
        name: '课程时长：',
        content: '2 天'
      }, {
        pic: IconUser,
        name: '学员对象：',
        content: '企业内部培训师、管理或业务专家、各级管理者'
      }, {
        pic: IconFlag,
        name: '授课形式：',
        content: '知识讲授 / 实战研讨 / 案例交流学 / 模拟练习 / 讲师点评'
      }],
      base: [{
        title: '课程介绍',
        content: '在企业课程开发领域，常见一些误区，导致无论内训师讲的多精彩，内容都不能帮助学员解决问题，更不能带来绩效提升。实用的企业内训课首先需要明确的是解决什么问题达成什么成果，在此基础上萃取知识，形成课程体系。本课程构建了一个完整、系统，又简单实用的课程体系。帮助企业理解知识生产、加工和传递的内在过程，从而不断生产知识，传承知识，打造学习型团队和组织。'
      },{
        title: '课程收益',
        desc: [{
          text: '能够开发一门课程'
        }, {
          text: '能够诊断待解决的培训问题'
        }, {
          text: '能够定义培训成果'
        }, {
          text: '能够萃取知识'
        }, {
          text: '能够设计教学活动'
        }, {
          text: '能够制作教学课件'
        }]
      }]
    },{
      title: '直播培训师训练营',
      tips: '线上课',
      level: [{
        pic: MiddleLevel,
        name: '中层管理者'
      },{
        pic: HighLevel,
        name: '高层管理者'
      }],
      tag: [{
        pic: IconTime,
        name: '课程时长：',
        content: '4 次线上直播式学习（每次 100 分钟），1 次线上直播考核'
      }, {
        pic: IconUser,
        name: '学员对象：',
        content: '企业各层级培训管理者、企业内训师、需要进行线上直播授课或辅导的培训师'
      }, {
        pic: IconFlag,
        name: '授课形式：',
        content: '在线学习 / 知识讲授 / 案例教学 / 课后考核'
      }],
      base: [{
        content: ''
      },{
        title: '课程收益',
        desc: [{
          text: '了解在线互动式直播教学的特点'
        }, {
          text: '掌握直播授课和辅导的相关流程、方法和工具'
        }, {
          text: '灵活设计和运用在线教学的各种类型内容'
        }, {
          text: '应用所学技能，现场设计和实施直播课程'
        }, {
          text: '直播培训师课程包（课程海报、课程逻辑设计表、课程 PPT、直播脚本 )'
        }]
      }]
    },{
      title: '案中说法：从典型素材到企业案例',
      level: [{
        pic: MiddleLevel,
        name: '中层管理者'
      },{
        pic: HighLevel,
        name: '高层管理者'
      }],
      tag: [{
        pic: IconTime,
        name: '课程时长：',
        content: '2 天'
      }, {
        pic: IconUser,
        name: '学员对象：',
        content: '企业培训与学习发展相关人员、企业内部专职训师、一线业务专家、各层级管理者'
      }, {
        pic: IconFlag,
        name: '授课形式：',
        content: '知识讲授 / 实战研讨 / 案例教学 / 模拟练习 / 讲师点评'
      }],
      base: [{
        title: '课程介绍',
        content: '课程通过案例开发步骤，有效萃取和梳理专家经验，使人和经验分离。并通过现场编写案例手册呈现专家经验，用案例生动形象呈现工作任务中常犯的问题以及解决办法，帮助员工避免犯类似的错误，提升员工辅导和成长的效率。'
      },{
        title: '课程收益',
        desc: [{
          text: '掌握案例开发的方法'
        }, {
          text: '能够对实践进行深度反思'
        }, {
          text: '能够将反思成果开发一个案例'
        }]
      }]
    },{
      title: '将培训转化为成果',
      level: [{
        pic: MiddleLevel,
        name: '中层管理者'
      }],
      tag: [{
        pic: IconTime,
        name: '课程时长：',
        content: '1.5 天'
      }, {
        pic: IconUser,
        name: '学员对象：',
        content: '企业内部培训负责人、培训规划及实施相关工作者'
      }, {
        pic: IconFlag,
        name: '授课形式：',
        content: '工作坊 / 实战研讨 / 模拟练习'
      }],
      base: [{
        title: '课程介绍',
        content: '在一个典型的培训课程之后，有百分之几的参与者能够足够好地、足够长时间地应用他们所学的内容以改进自己的工作？\n' +
          '如果我告诉你，经过大量调研后得出的答案是 16%，您的第一反应是？在今天这个一切围绕价值创造的 VUCA 时代，关于常规培训多大程度上支撑了业务结果，质疑声有增无减。只有当培训、在岗辅导、文化和绩效完美匹配时，企业学习项目才能真正发挥作用，培训部门的价值取决于其在绩效方面的效率和效果，而不是培训的数量、种类和技术。我们要以终为始培训目标要直指商业结果，提升绩效。'
      },{
        title: '课程收益',
        desc: [{
          name: '坚持结果导向：',
          text: '基于商业结果的思维改变、行为改变、绩效改变'
        }, {
          name: '精准培训内容：',
          text: '基于真实场景的业务场景、辅导场景、问题场景'
        }, {
          name: '落地培训效果：',
          text: '基于转化应用的、应用关联、效果评估'
        }]
      }]
    },{
      title: '互联网与大数据时代的营销变革',
      level: [{
        pic: MiddleLevel,
        name: '中层管理者'
      },{
        pic: HighLevel,
        name: '高层管理者'
      }],
      tag: [{
        pic: IconTime,
        name: '课程时长：',
        content: '2 天'
      }, {
        pic: IconUser,
        name: '学员对象：',
        content: '营销团队管理者、业务部门中高管'
      }, {
        pic: IconFlag,
        name: '授课形式：',
        content: '知识讲授 / 案例教学'
      }],
      base: [{
        title: '课程介绍',
        content: '随着移动互联网、社会化媒体等新技术的发展，海量的结构化与非结构化数据大量涌现，为企业提供了更加精准的营销手段。这个新的世界受到以客户为中心的推动正在创造新的商业模式，挑战着现有的商业模式并永久地改变了我们一直以来做生意的方式 。本课程主要讲述网络商业化的到来、消费者行为的变化、创新、创造力以及社会商业是如何共同作用，从而组成了一个新的商业模式。'
      },{
        title: '课程收益',
        desc: [{
          text: '掌握打造和传递客户价值的关键点的方法'
        }, {
          text: '了解互联网、大数据背景下，相关企业的营销实践和营销的本质变化以及企业数字化转型实践的核心和关键因素'
        }, {
          text: '理解目前我国众多企业在营销方面存在的观念误区和短视问题，企业营销转型的必要性和紧迫性'
        }]
      }]
    },{
      title: '打造客户价值创造的营销管理与变革',
      level: [{
        pic: MiddleLevel,
        name: '中层管理者'
      },{
        pic: HighLevel,
        name: '高层管理者'
      }],
      tag: [{
        pic: IconTime,
        name: '课程时长：',
        content: '1 天'
      }, {
        pic: IconUser,
        name: '学员对象：',
        content: '企业董事长、CEO、营销副总裁、销售总监、产品经理、交付经理、企管部总监等'
      }, {
        pic: IconFlag,
        name: '授课形式：',
        content: '知识讲授 / 案例教学'
      }],
      base: [{
        title: '课程介绍',
        content: '本课程以华为市场打法和营销体系建设为案例，解析如何以客户为中心，分享标杆企业营销体系变革的演进，系统性构建业务管理体系，打造一套适配企业端到端业务流程的营销管理体系。'
      },{
        title: '课程收益',
        desc: [{
          text: '能够阐述端到端的营销体系建设的关键步骤'
        }, {
          text: '能够分析标杆企业营销体系变革带来的启示'
        }]
      }]
    },{
      title: '解决方案式销售',
      level: [{
        pic: MiddleLevel,
        name: '中层管理者'
      }],
      tag: [{
        pic: IconTime,
        name: '课程时长：',
        content: '2 天'
      }, {
        pic: IconUser,
        name: '学员对象：',
        content: '中高级销售人员的技能优化和专业升级，尤其适用于 B2B（企业对企业）业务类型领域销售'
      }, {
        pic: IconFlag,
        name: '授课形式：',
        content: '知识讲授 / 实战研讨 / 案例教学 / 模拟练习'
      }],
      base: [{
        title: '课程介绍',
        content: '本课程为致力于解决方案深度营销的从业人员提供一套全面、系统的行动指南，实战解析六个核心阶段―― 市场机会分析，客户需求调查，产品方案制定，订单交易达成，实施过程管理及客户关系维护，提供解决方案式销售的路径图。'
      },{
        title: '课程收益',
        desc: [{
          text: '突破传统销售思维和行为模式，变产品推销者为问题解决者，提高成交率'
        }, {
          text: '学会提前收集和研判客户业务问题，准备预案和构想，做建设性拜访'
        }, {
          text: '掌握 SPIN 顾问技术，在销售访谈中引导和帮助客户发现问题，讨论对策'
        }, {
          text: '应用 3 类人需求模型，多维度分析客户的深层需求与关注，界定商机'
        }, {
          text: '基于 3+5 利益法则，精准提供产品、服务和解决方案，并在竞争中胜出'
        }, {
          text: '通过对客户关键人员的深度研判，制定前瞻性的销售策略和行动计划'
        }]
      }]
    },{
      title: '大客户开发与管理实战',
      level: [{
        pic: BasicLevel,
        name: '基层管理者'
      },{
        pic: MiddleLevel,
        name: '中层管理者'
      }],
      tag: [{
        pic: IconTime,
        name: '课程时长：',
        content: '2 天'
      }, {
        pic: IconUser,
        name: '学员对象：',
        content: '销售部经理 / 主管、高级客户经理、客户经理等'
      }, {
        pic: IconFlag,
        name: '授课形式：',
        content: '知识讲授 / 实战研讨 / 案例教学 / 模拟练习'
      }],
      base: [{
        title: '课程介绍',
        content: '大客户销售，是凭着一股热情，靠努力就可以做成吗？本课程将告诉我们：大客户销售应当依照遵循客户的购买思路和流程、分析竞争的形势、考虑我方的资源，审时度势步步为营，用严谨的思维，清晰的大局观，合理的应对，才有可能获取成功。'
      },{
        title: '课程收益',
        desc: [{
          text: '建立规范的销售流程'
        }, {
          text: '建立和客户关键人的信任及合作关系'
        }, {
          text: '掌握挖掘大客户需求的技能'
        }, {
          text: '制定有竞争力的成功策略，赢得并保有大客户'
        }]
      }]
    },]
  }])

  return (
    <main className='warp-tactic header-top'>
      <div className='warp-tactic-bg flex-center'>
        对标领导力梯队匹配不同层级领导力课程，<br />
        并有一整套领导力沙盘课程， <br />
        <span className='l-color'>同时通用类职场技能培训可为人才发展添砖加瓦。</span>
      </div>
      <div className='warp-tactic-content'>
        <div className='home-page'>
          {
            data.map((item, index) => (
              <>
                <div className='warp-tactic-content-header' style={{marginTop: `${index ? '75px': '0px'}`}}>
                  <img src={item.pic} alt=""/>
                  <div className='title'>{item.title}</div>
                </div>
                <div className='warp-tactic-content-items'>
                  {
                    item.content.map(c => (
                      <div className='content-item'>
                        <div className='content-item-header'>
                          <div className='tactic-header'>
                            <div className='tactic-header-title'>{c.title}</div>
                            {
                              c?.tips ?
                                typeof c.tips == 'object' ? c.tips.map(t => (
                                  <div className='tactic-header-tag flex-center'>{t}</div>
                                )): <div className='tactic-header-tag flex-center'>{c.tips}</div>
                                : null
                            }
                          </div>
                          <div className='tactic-level'>
                            {
                              c.level.map(l => (
                                <>
                                  <img src={l.pic} alt=""/>
                                  <span>{l.name}</span>
                                </>
                              ))
                            }
                          </div>
                        </div>
                        <div className='content-item-tag'>
                          {
                            c.tag.map(t => (
                              <div className='tag-block'>
                                <img src={t.pic} alt=""/>
                                <span className='dur'>{t.name}</span>
                                <span className='day'>{t.content}</span>
                              </div>
                            ))
                          }
                        </div>
                        <div className='content-item-line'></div>
                        <div className='content-item-block'>
                          {
                            c.base.splice(0, 1).map(b => (
                              <>
                                {
                                  b.title && <div className='b-title'>
                                    {b.title}
                                  </div>
                                }
                                {
                                  b.content &&
                                  <div className='b-content p-pre-wrap'>
                                    {b.content}
                                  </div>
                                }
                                {
                                  b?.desc?.length > 0 &&
                                  <div className='wrap-b-desc'>
                                    {
                                      b.desc.map(d => (
                                        <div className='b-desc'>
                                          {d.dot ? <></> : <div className='dot'></div>}
                                          <div className='text'>
                                            <span>{d.name}</span>{d.text}
                                          </div>
                                        </div>
                                      ))
                                    }
                                  </div>
                                }
                              </>
                            ))
                          }
                          <ExpandCollapse>
                            {
                              c.base.map(b => (
                                <>
                                  <div className='b-title'>
                                    {b.title}
                                  </div>
                                  {
                                    b.content &&
                                    <div className='b-content p-pre-wrap'>
                                      {b.content}
                                    </div>
                                  }
                                  {
                                    b?.desc?.length > 0 &&
                                    <div className='wrap-b-desc'>
                                      {
                                        b.desc.map(d => (
                                          <div className='b-desc'>
                                            {d.dot ? <></> : <div className='dot'></div>}
                                            <div className='text'>
                                              <span>{d.name}</span>{d.text}
                                            </div>
                                          </div>
                                        ))
                                      }
                                    </div>
                                  }
                                </>
                              ))
                            }
                          </ExpandCollapse>
                        </div>
                      </div>
                    ))
                  }
                </div>
              </>
            ))
          }
        </div>
      </div>
    </main>
  )
}

export default Talents