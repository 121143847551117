import React, {useState} from 'react'
import {Row, Col} from 'antd'
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack'
import QueueAnim from 'rc-queue-anim'
import RateIcon from '@/assets/images/rate-icon.png'
import ContendIcon from '@/assets/images/contend-icon.png'
import TensionIcon from '@/assets/images/tension-icon.png'
import FinancingIcon from '@/assets/images/financing-icon.png'
import AppearIcon from '@/assets/images/appear-icon.png'

const Challenge: React.FC = () => {
  const [data, setData] = useState([
    {
      name: '增长模式失灵',
      pic: RateIcon
    },
    {
      name: '市场竞争加剧',
      pic: ContendIcon
    },
    {
      name: '现金资源紧张',
      pic: TensionIcon
    },
    {
      name: '融资成本高昂',
      pic: FinancingIcon
    },
    {
      name: '上市路径迷茫',
      pic: AppearIcon
    },
  ])
  return (
    <div className='warp-challenge home-page'>
      <div className='title'>2023 企业增长面临的挑战</div>
      <OverPack playScale={0.3} >
        <QueueAnim
          type="bottom"
          key="block"
          leaveReverse
          component={Row}
          className='warp-challenge-items'
        >
          {
            data.map((item, i) => (
              <Col key={i.toString()} className='challenge-item'>
                <img src={item.pic} alt=""/>
                <div className='title-img'>{item.name}</div>
              </Col>
            ))
          }
        </QueueAnim>
      </OverPack>
    </div>
  )
}

export default Challenge