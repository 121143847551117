import React from 'react'
import {Row, Col} from 'antd'
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack'
import QueueAnim from 'rc-queue-anim'
import Bg from '@/assets/images/product/consult/bg.png'
import TacticBg from '@/assets/images/product/consult/tactic-bg.png'
import FinanceBg from '@/assets/images/product/consult/finance-bg.png'
import IconBook from "@/assets/images/product/consult/icon-book.png"
import IconDev from "@/assets/images/product/consult/icon-dev.png"
import IconGrade from "@/assets/images/product/consult/icon-grade.png"
import IconUser from "@/assets/images/product/consult/icon-user.png"

const Consult: React.FC = () => {

  return (
    <main className='warp-consult header-top'>
      <div className='warp-consult-header'>
        <img src={Bg} alt=""/>
      </div>
      <div className='warp-consult-manage'>
        <div className='home-page'>
          <div className='title'>管理咨询业务<span>·战略咨询</span></div>
          <div className='desc'>定位问题·价值再造·赋能可持续发展</div>
          <div className='tactic-bg'>
            <img src={TacticBg} alt=""/>
          </div>
          <OverPack playScale={0.3}>
            <QueueAnim
              type="bottom"
              key="blocks"
              leaveReverse
              className='warp-consult-blocks'
              component={Row}
              gutter={[15, 15]}
            >
              <Col key='1' md={8}>
                <div className='warp-consult-block'>
                  <div className='consult-index'>01</div>
                  <div className='consult-title'>调研访谈</div>
                  <div className='consult-desc'>高管访谈、明确发展规划核心班子访谈，澄清战略落地卡点</div>
                  <div className='consult-tips'>
                    <div className='dot'></div>
                    <div className='text'>
                      《战略调研报告》
                    </div>
                  </div>
                </div>
              </Col>
              <Col key='2' md={8}>
                <div className='warp-consult-block'>
                  <div className='consult-index'>02</div>
                  <div className='consult-title'>方法论导入</div>
                  <div className='consult-desc'>筛选明确战略落地核心成员<br />集中培训导入战略落地方法论</div>
                  <div className='consult-tips'>
                    <div className='dot'></div>
                    <div className='text'>
                      《战略落地工具手册》
                    </div>
                  </div>
                </div>
              </Col>
              <Col key='3' md={8}>
                <div className='warp-consult-block'>
                  <div className='consult-index'>03</div>
                  <div className='consult-title'>共创工作坊</div>
                  <div className='consult-desc'>基于方法论，引导核心团队进行战略分解共识卡点及落地计划</div>
                  <div className='consult-tips'>
                    <div className='dot'></div>
                    <div className='text'>
                      《年度硬仗及行动计划》
                    </div>
                  </div>
                </div>
              </Col>
              <Col key='4' md={8}>
                <div className='warp-consult-block'>
                  <div className='consult-index'>04</div>
                  <div className='consult-title'>落地辅导</div>
                  <div className='consult-desc'>
                    部门策略拆解辅导 <br />
                    部门绩效目标设定及管理辅导
                  </div>
                  <div className='consult-tips'>
                    <div className='dot'></div>
                    <div className='text'>
                      《部门绩效目标承诺书》
                    </div>
                  </div>
                </div>
              </Col>
              <Col key='5' md={8}>
                <div className='warp-consult-block'>
                  <div className='consult-index'>05</div>
                  <div className='consult-title'>阶段复盘</div>
                  <div className='consult-desc'>
                    部门周会、月会的会议辅导 <br />
                    里程碑节点复盘  战略执行纠偏
                  </div>
                  <div className='consult-tips'>
                    <div className='dot'></div>
                    <div className='text'>
                      《战略执行进度表》
                    </div>
                  </div>
                </div>
              </Col>
              <Col key='6' md={8}>
                <div className='warp-consult-block'>
                  <div className='consult-index'>06</div>
                  <div className='consult-title'>项目收尾</div>
                  <div className='consult-desc'>
                    战略执行结果收集 <br />
                    战略辅导落地总结
                  </div>
                  <div className='consult-tips'>
                    <div className='dot'></div>
                    <div className='text'>
                      《项目总结报告及改进建议》
                    </div>
                  </div>
                </div>
              </Col>
            </QueueAnim>
          </OverPack>
        </div>
      </div>

      <OverPack playScale={0.3} className='warp-consult-finance'>
        <QueueAnim
          type="bottom"
          key="finance"
          leaveReverse
          className='home-page'
        >
          <div className='title' key='title'>管理咨询业务<span>·财务咨询</span></div>
          <img className='home-page' key='img' src={FinanceBg} alt=""/>
        </QueueAnim>
      </OverPack>
      <OverPack playScale={0.3} className='warp-consult-talents'>
        <QueueAnim
          type="bottom"
          key="talents"
          leaveReverse
          className='home-page'
        >
          <div className='title' key='title'>管理咨询业务<span>·人才管理咨询</span></div>
          <div className='desc'  key='desc'>
            帮助企业进行战略性人才管理体系建设。基于企业核心战略，规划人力资源发展路径；<br />
            建立全通路的人才供应链，提升人才梯队厚度，以敏捷应对多变的商业环境。
          </div>
          <QueueAnim
            type="bottom"
            key="blocks"
            leaveReverse
            component={Row}
            gutter={[13]}
            className='talents-blocks'
          >
            <Col md={6} key='1'>
              <div className='talents-block'>
                <img src={IconUser} alt=""/>
                <div className='talents-block-title'>人才供给</div>
                <div className='talents-block-desc'>
                  <div className='dot'></div>
                  <div className='text'>
                    企业战略解读
                  </div>
                </div>
                <div className='talents-block-desc'>
                  <div className='dot'></div>
                  <div className='text'>
                    企业价值链梳理
                  </div>
                </div>
                <div className='talents-block-desc'>
                  <div className='dot'></div>
                  <div className='text'>
                    关键能力确定
                  </div>
                </div>
                <div className='talents-block-desc'>
                  <div className='dot'></div>
                  <div className='text'>
                    人才战略策划
                  </div>
                </div>
              </div>
            </Col>
            <Col md={6} key='2'>
              <div className='talents-block'>
                <img src={IconBook} alt=""/>
                <div className='talents-block-title'>人才任用</div>
                <div className='talents-block-desc'>
                  <div className='dot'></div>
                  <div className='text'>
                    企业战略解读
                  </div>
                </div>
                <div className='talents-block-desc'>
                  <div className='dot'></div>
                  <div className='text'>
                    企业价值链梳理
                  </div>
                </div>
                <div className='talents-block-desc'>
                  <div className='dot'></div>
                  <div className='text'>
                    关键能力确定
                  </div>
                </div>
                <div className='talents-block-desc'>
                  <div className='dot'></div>
                  <div className='text'>
                    人才战略策划
                  </div>
                </div>
              </div>
            </Col>
            <Col md={6} key='3'>
              <div className='talents-block'>
                <img src={IconDev} alt=""/>
                <div className='talents-block-title'>人才发展</div>
                <div className='talents-block-desc'>
                  <div className='dot'></div>
                  <div className='text'>
                    企业战略解读
                  </div>
                </div>
                <div className='talents-block-desc'>
                  <div className='dot'></div>
                  <div className='text'>
                    企业价值链梳理
                  </div>
                </div>
                <div className='talents-block-desc'>
                  <div className='dot'></div>
                  <div className='text'>
                    关键能力确定
                  </div>
                </div>
                <div className='talents-block-desc'>
                  <div className='dot'></div>
                  <div className='text'>
                    人才战略策划
                  </div>
                </div>
              </div>
            </Col>
            <Col md={6} key='4'>
              <div className='talents-block'>
                <img src={IconGrade} alt=""/>
                <div className='talents-block-title'>人才品控</div>
                <div className='talents-block-desc'>
                  <div className='dot'></div>
                  <div className='text'>
                    企业战略解读
                  </div>
                </div>
                <div className='talents-block-desc'>
                  <div className='dot'></div>
                  <div className='text'>
                    企业价值链梳理
                  </div>
                </div>
                <div className='talents-block-desc'>
                  <div className='dot'></div>
                  <div className='text'>
                    关键能力确定
                  </div>
                </div>
                <div className='talents-block-desc'>
                  <div className='dot'></div>
                  <div className='text'>
                    人才战略策划
                  </div>
                </div>
              </div>
            </Col>
          </QueueAnim>
        </QueueAnim>
      </OverPack>
    </main>
  )
}

export default Consult