import React, {useState} from 'react'
import {Col, Row} from 'antd'
import ExpandCollapse from '@/components/ExpandCollapse'
import ThemeBg01 from '@/assets/images/product/study/theme-bg01.jpg'
import ThemeBg02 from '@/assets/images/product/study/theme-bg02.jpg'
import ThemeBg03 from '@/assets/images/product/study/theme-bg03.jpg'
import ThemeBg04 from '@/assets/images/product/study/theme-bg04.jpg'
import ThemeBg05 from '@/assets/images/product/study/theme-bg05.jpg'
import ThemeBg06 from '@/assets/images/product/study/theme-bg06.jpg'
import BasicLevel from '@/assets/images/product/study/icon-basic-level.png'
import HighLevel from '@/assets/images/product/study/icon-high-level.png'
import MiddleLevel from '@/assets/images/product/study/icon-middle-level.png'
import IconCircle from '@/assets/images/product/study/icon-circle.png'
import IconFlag from '@/assets/images/product/study/icon-flag.png'
import IconTime from '@/assets/images/product/study/icon-time.png'
import IconUser from '@/assets/images/product/study/icon-user.png'

const ThemeList: React.FC = () => {
  const [data, setData] = useState([{
    title: '茶马古道 古今汇聚',
    desc: '行迢迢商道上漫漫长路求索磨砺意志\n于千古文化中触摸历史群像体悟人生',
    pic: ThemeBg01,
    level: [{
      pic: BasicLevel,
      name: '基层管理者'
    }, {
      pic: MiddleLevel,
      name: '中层管理者'
    }, {
      pic: HighLevel,
      name: '高层管理者'
    }],
    tag: [{
      pic: IconTime,
      name: '研学时长：',
      content: '7天'
    }, {
      pic: IconFlag,
      name: '项目形式：',
      content: '主题研学、徒步穿行、骑马行路'
    }],
    base: [{
      title: '项目介绍',
      content: '茶马古道是以川藏道、滇藏道与青藏道（甘青道）三条大道为主线，辅以众多的支线、附线，构成的一个庞大的交通网络。古至唐宋、今至当代，茶马古道是千百年来各朝代、各民族，商旅贸易、文化传播的重要节点，铭刻了众民族、多文化的文化遗产和精神内涵。在这里您可以纵马、骑行、漫步，体悟多元文化与民族风情，在古今融汇中产生新的体悟。',
    }]
  },{
    title: '敦煌艺术文化研修―指尖上的敦煌',
    desc: '增强学员求知探索精神\n体验敦煌艺术文化精髓，用趣味学习打动学员发挥主动创造性',
    pic: ThemeBg02,
    level: [{
      pic: BasicLevel,
      name: '基层管理者'
    }, {
      pic: MiddleLevel,
      name: '中层管理者'
    }, {
      pic: HighLevel,
      name: '高层管理者'
    }],
    tag: [{
      pic: IconTime,
      name: '研学时长：',
      content: '5天4晚'
    }, {
      pic: IconFlag,
      name: '项目形式：',
      content: '主题研学'
    }],
    base: [{
      title: '敦煌与莫高窟',
      content: '中国五千年文明就像是人类的大舞台，一朝一代的走过来，每朝每代都给人类留下丰富的文化遗产。敦煌石窟就像一个小舞台，不同的朝代，不同的民族，不同的服饰，不同的文化，衬托出共同的信仰与追求，无数的能工巧匠，善男信女，无论高低贵贱，共同演奏着一曲赞美神的交响乐，融贯东西，气势磅礴，历经千年，为人类留下传神的文化。',
      title2: '项目价值',
      desc: [{
        name: '历史价值：',
        content: '历经一千年创造的敦煌石窟艺术，某种意义上表现了一千年古代社会的生活，展示了内涵丰富的文化'
      }, {
        name: '艺术价值：',
        content: '绵延千年，内容丰富，继承了本土艺术传统，吸收南北朝和唐宋美术艺术流派的风格，又不断接受、改造、融合域外印度、 中亚、西亚的艺术风格，向人们展示了一部佛教美术艺术史及其中国化的渐进历程。又是中国艺术与西域艺术往来交流的历史记载。 对研究中国美术史和世界美术史都有重要的意义'
      }, {
        name: '科技价值：',
        content: '最先进的农耕工具图像数据、保存了中国为世界交通工具做出独有贡献的独轮车、马套挽具（胸带挽具和肩套挽具）、马蹬、马蹄钉掌等珍贵的图像数据'
      }]
    }]
  },{
    title: '云冈石窟 国潮来袭',
    desc: '在深度体验中学习工匠精神，孜孜不倦、勇于坚持完成目标\n揭开云冈石窟的神秘面纱，传承中华传统文化，弘扬爱国主义精神',
    pic: ThemeBg03,
    level: [{
      pic: BasicLevel,
      name: '基层管理者'
    }, {
      pic: MiddleLevel,
      name: '中层管理者'
    }, {
      pic: HighLevel,
      name: '高层管理者'
    }],
    tag: [{
      pic: IconTime,
      name: '研学时长：',
      content: '5天'
    }, {
      pic: IconFlag,
      name: '项目形式：',
      content: '主题研学'
    }],
    base: [{
      title: '项目介绍',
      content: '云冈石窟、地处平城、是历代兵家必争之地，更是历朝佛迹瑰宝陈列之处。在这里，来访者可以见证北魏的雄浑壮美、遇见辽金的民族共融、体味明清的九边重镇、近距离感受、挖掘云冈石窟文化内涵。于古建筑、佛造像之间体会厚重古韵，感悟渺渺人生。',
      title2: '项目亮点',
      desc: [{
        content: '实地参观 11、12、13 三个非开放洞窟，跟随资深专家进入洞窟实景教学。深入 18 窟，伫立于大佛脚下，由研究员带您身临其境，探寻石窟开凿背后的隐秘历史。'
      }, {
        content: '名家荟萃，为您带来多场专题学术讲座。张焯研究员的《东方佛教第一圣地》，从佛教东传的波浪式轨迹、云冈石窟开凿的历史因缘、东方佛教艺术的旷世绝唱、平城时代对中国佛教的重要贡献等四大方面，对云冈石窟的发展历程进行解析。赵昆雨副研究员的《谁雕凿了云冈》，从工匠来源、工匠体系构建等方面着手，结合丰富的北朝史料，全面系统地分析了云冈石窟工匠的构成。'
      }, {
        content: '走进壁画修复中心，由高级壁画修复师解读壁画修复的奥秘，亲自指导壁画修复实操，切身体验云冈工匠精神。'
      },{
        content: '文博专家带您参观鲜为人知的北朝壁画墓，为您实景介绍辽金古建知识，精讲海内遗珍 � 天宫楼阁”小木作。'
      },{
        content: '云冈研究院杭侃院长，莅临结业典礼，增设学术答问环节，为学员答疑解惑，并颁发研修班结业证书。'
      }]
    }]
  },{
    title: '荒野求生―户外生存训练营',
    desc: '在求生境况里充分发挥问题分析解决能力，学会独立判断，敏锐观察\n锻炼学员意志力，充分发挥个人主观能动性，提升吃苦耐劳精神',
    pic: ThemeBg04,
    level: [{
      pic: BasicLevel,
      name: '基层管理者'
    }, {
      pic: MiddleLevel,
      name: '中层管理者'
    }, {
      pic: HighLevel,
      name: '高层管理者'
    }],
    tag: [{
      pic: IconTime,
      name: '赛事时长：',
      content: '2天1晚/5天4晚'
    }, {
      pic: IconCircle,
      name: '赛事里程：',
      content: '108 公里'
    },{
      pic: IconFlag,
      name: '项目形式：',
      content: '沙漠、雨林、海岛探索'
    },{
      pic: IconUser,
      name: '学员对象：',
      content: '需要凝练意志、加深羁绊、创造共识的团队'
    }],
    base: [{
      title: '项目介绍',
      content: '户外生存训练营起源于英国《荒野生存》节目，课程体系来自英国原版《贝尔生存训练营》，于2015年进 入中国团建市场。熙诚教育（光和企学）和专业户外达人合作参与主导设计了国内所有生存课程，目前为国内最专业的户外生存训练营内容。户外生存训练营核心要素：走出舒适圈拥抱未知，在不确定性中寻找确定性；物质越少人性越多，有限的资源条件下如何获取最多资源；突破自我达到团队价值最大化，野外生存与企业生存完美结合…\n' +
        '课程根据地域地貌的区别将求生细分为荒野求生、荒岛求生、荒漠求生、城市生存。'
    }]
  },{
    title: '罗布泊 108 徒步挑战赛',
    desc: '增强身心素质，大幅提升学员抗压能力\n体会团队力量，完成个人与集体的完美融合',
    pic: ThemeBg05,
    level: [{
      pic: BasicLevel,
      name: '基层管理者'
    }, {
      pic: MiddleLevel,
      name: '中层管理者'
    }, {
      pic: HighLevel,
      name: '高层管理者'
    }],
    tag: [{
      pic: IconTime,
      name: '赛事时长：',
      content: '5天4晚'
    }, {
      pic: IconCircle,
      name: '赛事里程：',
      content: '108 公里'
    },{
      pic: IconFlag,
      name: '项目形式：',
      content: '隔壁穿越、沙漠徒步'
    },{
      pic: IconUser,
      name: '学员对象：',
      content: '需要凝练意志、加深羁绊、创造共识的团队'
    }],
    base: [{
      title: '赛事介绍',
      desc: [{
        content: '沙漠是人类生存环境中最为极限的场景之一。'
      },{
        content: '这里终年少雨、沙暴横行；冬季极寒、夏季酷热。严苛的环境使得沙漠人迹罕至；但在一系列现代设备和专业人员的保障下，人们得以安全的深入大漠，亲身感受自然地严酷磨炼。'
      },{
        content: '参赛队员将深入罗布泊，征服盐碱地、雅丹魔鬼城、湿地、戈壁、沙漠绿洲、沙漠边缘等多种复杂地貌，在风沙的雕琢下，不畏艰险、携手共进的团队品质也会被磨砺的愈发强韧。'
      }]
    }]
  },{
    title: '林海雪原徒步赛',
    desc: '个人蜕变，突破舒适区\n集体交融，团队协作与资源整合',
    pic: ThemeBg06,
    level: [{
      pic: BasicLevel,
      name: '基层管理者'
    }, {
      pic: MiddleLevel,
      name: '中层管理者'
    }, {
      pic: HighLevel,
      name: '高层管理者'
    }],
    tag: [{
      pic: IconTime,
      name: '赛事时长：',
      content: '6天5夜'
    }, {
      pic: IconCircle,
      name: '赛事里程：',
      content: '48 公里'
    },{
      pic: IconFlag,
      name: '项目形式：',
      content: '雪原徒步，各企业家组成队伍竞技，每队10~20人'
    },{
      pic: IconUser,
      name: '学员对象：',
      content: '中国先锋企业家、高管团队'
    }],
    base: [{
      title: '赛事介绍',
      desc: [{
        content: '林海雪原是指黑龙江省东南部长白山支脉张广才岭及周边地区，年平均积雪厚度达 2 米，常年保持原始生态。当走在寂静的山林，踏入及膝的雪径，冬季的徒步圣地非它莫属。'
      }, {
        content: '每年 12 月 31 日至 1 月 3 日，来自全国各地的企业家在中国最北端的徒步路线上，一同踏过 4 天 3 夜 48 公里的雪原徒步挑战。在银装素裹的莽莽雪原之中，您将在此，与天地交融，奋勇向前，探寻最真实的自己。'
      }]
    }]
  }])

  return (
    <div className='warp-theme-list'>
      {
        data.map(item => (
          <div className='warp-theme-list-block home-page'>
            <Row>
              <Col md={13} className='warp-theme-content warp-theme-list-block-left'>
                <div className='title'>{item.title}</div>
                <div className='level'>
                  {
                    item.level.map(l => (
                      <>
                        <img src={l.pic} alt=""/>
                        <span>{l.name}</span>
                      </>
                    ))
                  }
                </div>
                <div className='tag'>
                  {
                    item.tag.map(t => (
                      <div className='tag-block'>
                        <img src={t.pic} alt=""/>
                        <span className='dur'>{t.name}</span>
                        <span className='day'>{t.content}</span>
                      </div>
                    ))
                  }
                </div>
                <div className='desc p-pre-wrap'>
                  {item?.desc}
                </div>
              </Col>
              <Col md={11} style={{textAlign: 'right'}}>
                <img className='theme-bg' src={item.pic} width="100%" alt="" />
              </Col>
            </Row>
            <Row style={{width: '100%', marginTop: '21px'}}>
              <ExpandCollapse>
                {
                  item.base.map(d => (
                    <div className='warp-theme-bottom'>
                      <div className='b-title'>
                        {d.title}
                      </div>
                      {
                        d.content &&
                        <div className='b-content p-pre-wrap'>
                          {d.content}
                        </div>
                      }
                      {
                        d?.title2 && <div className='b-title'>
                          {d?.title2}
                        </div>
                      }
                      <div className='wrap-b-desc'>
                        {
                          d?.desc?.length > 0 &&
                          d.desc.map(dd => (
                            <div className='b-desc'>
                              <div className='dot'></div>
                              <div className='text'>
                                <span>{dd.name}</span>{dd.content}
                              </div>
                            </div>
                          ))
                        }
                      </div>
                    </div>
                  ))
                }
              </ExpandCollapse>
            </Row>
          </div>
        ))
      }
    </div>
  )
}

export default ThemeList