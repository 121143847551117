import axios from 'axios'

axios.defaults.baseURL = process.env.API_BASE_URL + process.env.BASE_PATH
axios.defaults.headers.post['Content-Type']="application/json;charset=utf8"


axios.interceptors.response.use(
  response=>{
    return response?.data
  },
  error=>{
    console.error(error)
  }
);
export default axios