import React, {useState, useEffect} from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Banner from '@/pages/About/Join/Banner'
import Work from './Work'
import Duty from './Duty'
import Contacts from './Contacts'
import BackIcon from '@/assets/images/back-icon.png'

const JoinDetail: React.FC = () => {
  const [item, setItem] = useState({})
  const [data, setData] = useState([
    {
      name: '销售总监（北京）',
      desc: '',
      edu: '本科及以上学历',
      workAge: '8年以上',
      num: '--',
      time: '2024年9月24日',
      duty: '1.根据年度销售责任目标，负责所辖事业组企业培训业务市场营销策略的制定，并按照年度目标完成教育产品销售任务； \n' +
        '2.熟悉顾问式营销，能进行客户分析，挖掘用户培训需求，开发新的客户和新的市场领域； \n' +
        '3.制订和执行与企业、院校的合作计划，负责客户合同的谈判与签订工作； \n' +
        '4.带领事业组组员完成公司本事业组销售目标； \n' +
        '5.负责领导安排的其他工作。',
      status: '1.本科及以上学历； \n' +
        '2.具有8年以上企业培训、管理咨询行业销售工作经验。'
    },
    {
      name: '销售经理（大客户经理-北京）',
      desc: '',
      edu: '本科及以上学历',
      workAge: '3年以上',
      num: '--',
      time: '2024年9月24日',
      duty: '1.结果导向：负责管理培训与管理咨询产品的销售，推进年度目标达成； \n' +
        '2.流程管理：熟悉顾问式营销的流程，负责项目的开发、对接、跟进、提案、签约、实施、回款全过程的主把控； \n' +
        '3.客户开发：根据年度销售计划开展客户的开发工作； \n' +
        '4.信息反馈：收集市场客户及竞争产品相关市场信息及动态，并提出合理化建议。',
      status: '1.统招本科及以上学历（特别优秀人员可放宽学历条件），专业不限，具有3年以上企业培训、管理咨询行业销售工作经验；经济与金融、教育、企业管理、营销推广、人力资源等相关专业背景优先。'
    },
    {
      name: '销售总监（上海）',
      desc: '',
      edu: '本科及以上学历',
      workAge: '8年以上',
      num: '--',
      time: '2024年9月24日',
      duty: '1.根据年度销售责任目标，负责所辖事业组企业培训业务市场营销策略的制定，并按照年度目标完成教育产品销售任务； \n' +
        '2.熟悉顾问式营销，能进行客户分析，挖掘用户培训需求，开发新的客户和新的市场领域； \n' +
        '3.制订和执行与企业、院校的合作计划，负责客户合同的谈判与签订工作； \n' +
        '4.带领事业组组员完成公司本事业组销售目标； \n' +
        '5.负责领导安排的其他工作。 ',
      status: '1.本科及以上学历； \n' +
        '2.具有8年以上企业培训、管理咨询行业销售工作经验。'
    },
    {
      name: '销售总监（深圳）',
      desc: '',
      edu: '本科及以上学历',
      workAge: '8年以上',
      num: '--',
      time: '2024年9月24日',
      duty: '1.根据年度销售责任目标，负责所辖事业组企业培训业务市场营销策略的制定，并按照年度目标完成教育产品销售任务； \n' +
        '2.熟悉顾问式营销，能进行客户分析，挖掘用户培训需求，开发新的客户和新的市场领域； \n' +
        '3.制订和执行与企业、院校的合作计划，负责客户合同的谈判与签订工作； \n' +
        '4.带领事业组组员完成公司本事业组销售目标； \n' +
        '5.负责领导安排的其他工作。 ',
      status: '1.本科及以上学历； \n' +
        '2.具有8年以上企业培训、管理咨询行业销售工作经验。'
    },
    {
      name: '销售经理（大客户经理-成都）',
      desc: '',
      edu: '本科及以上学历',
      workAge: '3年以上',
      num: '--',
      time: '2024年9月24日',
      duty: '1.结果导向：负责管理培训与管理咨询产品的销售，推进年度目标达成； \n' +
        '2.流程管理：熟悉顾问式营销的流程，负责项目的开发、对接、跟进、提案、签约、实施、回款全过程的主把控； \n' +
        '3.客户开发：根据年度销售计划开展客户的开发工作； \n' +
        '4.信息反馈：收集市场客户及竞争产品相关市场信息及动态，并提出合理化建议。',
      status: '1.统招本科及以上学历（特别优秀人员可放宽学历条件），专业不限，具有3年以上企业培训、管理咨询行业销售工作经验；经济与金融、教育、企业管理、营销推广、人力资源等相关专业背景优先。'
    }
  ])
  const navigate = useNavigate()
  const { id } = useParams()
  useEffect(() => {
    setItem(data[id])
  }, [])
  const handlePage = () => {
    navigate('/about/join')
  }

  return (
    <main className='warp-join-detail header-top'>
      <Banner />
      <Work item={item} />
      <Duty item={item} />
      <Contacts />
      <div className='go-page home-page' onClick={handlePage}>
        <img src={BackIcon} alt=""/>
        <span>返回上一页</span>
      </div>
    </main>
  )
}

export default JoinDetail