import React, { useState, useEffect, useRef, memo } from "react"
// @ts-expect-error
import DPlayer from 'dplayer'
import './index.less'

type IProps = {
  autoplay: boolean
  theme: string
  loop: boolean
  lang: string
  screenshot: boolean
  hotkey: boolean
  preload: string
  volume: number
  mutex: boolean
  cosKey: string
  playbackSpeed: number[]
}

const Video: React.FC<IProps> = (props) => {
  const [dp, setDp] = useState(null)
  const videoRef = useRef(null)

  useEffect(() => {
    initDPlayer()
    return () => {
      if(dp) {
        dp?.destroy?.()
      }
    }
  }, [])

  const initDPlayer = () => {
    const options = {
      container: videoRef.current,
      autoplay: props.autoplay,
      theme: props.theme,
      loop: props.loop,
      lang: props.lang,
      screenshot: props.screenshot,
      hotkey: props.hotkey,
      preload: props.preload,
      volume: props.volume,
      playbackSpeed: props.playbackSpeed,
      mutex: props.mutex,
      video: {
        url: props?.cosKey,
        pic: props?.pic
      },
    }
    const dp = new DPlayer(options)
    setDp(dp)
  }
  return (
    <div className='video-clip' ref={videoRef}></div>
  )
}

Video.defaultProps = {
  // 是否自动播放
  autoplay: false,
  // 主题色
  theme: '#C30D23',
  // 视频是否循环播放
  loop: false,
  // 语言(可选值: 'en', 'zh-cn', 'zh-tw')
  lang: 'zh-cn',
  // 是否开启截图(如果开启，视频和视频封面需要允许跨域)
  screenshot: false,
  // 是否开启热键
  hotkey: true,
  // 视频是否预加载(可选值: 'none', 'metadata', 'auto')
  preload: 'auto',
  // 可选的播放速率，可以设置成自定义的数组
  playbackSpeed: [1, 1.5],
  // 默认音量
  volume: 0.7,
  // 视频链接
  cosKey: '',
  // 视频封面
  pic: '',
  // 阻止多个播放器同时播放，当前播放器播放时暂停其他播放器
  mutex: true
}

export default memo(Video)