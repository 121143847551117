import React, {useRef, useState} from 'react'
import {Carousel, Row, Col} from 'antd'
import VisitBg from '@/assets/images/product/visit/visit-bg.png'
import HighLevel from '@/assets/images/product/study/icon-high-level.png'
import MiddleLevel from '@/assets/images/product/study/icon-middle-level.png'
import IconFlag from '@/assets/images/product/study/icon-flag.png'
import IconTime from '@/assets/images/product/study/icon-time.png'
import IconUser from '@/assets/images/product/study/icon-user.png'
import Bg01 from '@/assets/images/product/visit/bg01.png'
import Bg02 from '@/assets/images/product/visit/bg02.png'
import Bg03 from '@/assets/images/product/visit/bg03.png'
import Bg04 from '@/assets/images/product/visit/bg04.png'
import Bg05 from '@/assets/images/product/visit/bg05.png'
import Bg06 from '@/assets/images/product/visit/bg06.png'
import Bg07 from '@/assets/images/product/visit/bg07.png'
import AssumeBg from '@/assets/images/product/visit/assume-bg.png'
import Inspect01 from '@/assets/images/product/visit/inspect-bg01.png'
import Inspect02 from '@/assets/images/product/visit/inspect-bg02.png'
import Inspect03 from '@/assets/images/product/visit/inspect-bg03.png'
import Inspect04 from '@/assets/images/product/visit/inspect-bg04.png'
import MoreInspect01 from '@/assets/images/product/visit/more-inspect01.png'
import MoreInspect02 from '@/assets/images/product/visit/more-inspect02.png'
import Slideshow01 from '@/assets/images/product/visit/slideshow01.png'
import Slideshow02 from '@/assets/images/product/visit/slideshow02.png'
import Slideshow03 from '@/assets/images/product/visit/slideshow03.png'

const Visit: React.FC = () => {
  const carouselRef = useRef(null)
  const [num, setNum] = useState(0)
  const [items, setItems] = useState([{
    pic: Slideshow01,
  },{
    pic: Slideshow02,
  },{
    pic: Slideshow03,
  }])
  const [data, setData] = useState([{
    title: '走进小米：爆品缔造之旅',
    tips: '标杆参访',
    desc: '深度理解小米的爆品制造方法论，掌握如何深入挖掘客户需求，快速迭代抢占市场\n' +
      '了解小米的粉丝经济，如何通过产品 - 口碑 - 品牌飞轮撬动增长',
    level: [{
      pic: MiddleLevel,
      name: '中层管理者'
    }, {
      pic: HighLevel,
      name: '高层管理者'
    }],
    tag: [{
      pic: IconTime,
      name: '课程时长：',
      content: '0.5天'
    }, {
      pic: IconUser,
      name: '学员对象：',
      content: '企业一把手与他的核心团队、BU Leader 与直接汇报者、企业核心中层'
    }, {
      pic: IconFlag,
      name: '授课形式：',
      content: '展厅参观 / 高层深度交流 / 现场体验'
    }],
    base: {
      title01: '参访企业介绍',
      content01: '创业仅7年时间，小米的年收入就突破了千亿元人民币，成功在香港主板上市，，创造了香港史上最大规模科技股IPO。“和用户交朋友，做用户心中最酷的公司”的愿景都在驱动着我们努力创新，不断追求极致的产品和效率，成就了一个不断缔造成长奇迹的小米。让我们一同走进小米，深入剖析小米如何挖掘用户需求，打造爆品；探究小米超强的客户黏性是如何建立起来的。',
      title02: '参访特色',
      desc02: [{
        name: '[小米科技园]：',
        text: '小米集团 52 亿打造全球总部，融合小米科技、研发、实验室、管理、文化、全球化'
      }, {
        name: '[全球总部店]：',
        text: '小米唯一全球总部店，1600 平米超大展厅，涵盖小米手机历史博物馆、IOT 智慧家庭展示、5G 手机展示体验、 米粉文化博物馆等'
      }, {
        text: '包含 AI 技术、数字化与智能化、开放式创新在内的前沿技术洞悉'
      }, {
        text: 'VP 级亲自分享，高屋建瓴'
      }],
      title03: '参访亮点',
      pic03: [{
        pic: Bg01,
        text: '参访小米全球总店\n深入了解小米的爆款产品及其背后的故事',
      }, {
        pic: Bg02,
        text: '施耐德智能工厂参观',
      }]
    }
  },{
    title: '走进施耐德：未来制造之旅',
    tips: '标杆参访',
    desc: '了解工业 4.0 时代的智能制造工厂如何运转\n' +
      '深入理解智能化和精益生产在制造业如何提高生产效率，降低成',
    level: [{
      pic: MiddleLevel,
      name: '中层管理者'
    }, {
      pic: HighLevel,
      name: '高层管理者'
    }],
    tag: [{
      pic: IconTime,
      name: '课程时长：',
      content: '1-2 天'
    }, {
      pic: IconUser,
      name: '学员对象：',
      content: '企业一把手与他的核心团队、BU Leader 与直接汇报者、企业核心中层'
    }, {
      pic: IconFlag,
      name: '授课形式：',
      content: '展厅参观 / 高层深度交流 / 现场体验'
    }],
    base: {
      title01: '参访企业介绍',
      content01: '施耐德电气是法国的工业先锋之一，世界 500 强企业，同时也是全球能效管理领域的领导者。位于天津的施耐德标杆数字化工厂保持了连续 18 年的销售增长，同时保持每年工业效率 10% 提升。在这里，精益生产、智能制造和数字化完美融合于一体。通过参访该工厂，参访者可以深入理解智能制造与数字化是如何共同作用于制造业的效率提升，精益生产和供应链管理如何帮助工厂大幅节省成本，助力企业发展。',
      title02: '参访特色',
      desc02: [{
        text: '施耐德全球标杆智能制造工厂，深入了解数字化与智能制造技术的前沿应用场景'
      }, {
        text: '丰富活动，参与体验精益生产和价值流如何赋能工业效率提升'
      }, {
        text: '相关技术及领域负责人亲自分享，高屋建瓴'
      }],
      title03: '参访亮点',
      pic03: [{
        pic: Bg03,
        text: '施耐德智能工厂参观',
      }, {
        pic: Bg04,
        text: '精益生产体验',
      }]
    }
  },{
    title: '走进金风：双碳独角兽的快速成长之旅',
    tips: '标杆参访',
    desc: '了解全球减碳趋势与中国减碳领域的龙头企业金风科技\n' +
      '深入理解低碳技术在各个行业的应用',
    level: [{
      pic: MiddleLevel,
      name: '中层管理者'
    }, {
      pic: HighLevel,
      name: '高层管理者'
    }],
    tag: [{
      pic: IconTime,
      name: '课程时长：',
      content: '0.5天'
    }, {
      pic: IconUser,
      name: '学员对象：',
      content: '企业一把手与他的核心团队、BU Leader 与直接汇报者、企业核心中层'
    }, {
      pic: IconFlag,
      name: '授课形式：',
      content: '展厅参观 / 现场体验'
    }],
    base: {
      title01: '参访企业介绍',
      content01: '金风科技深度聚焦能源开发、能源装备、能源服务、能源应用四大领域，以强大科研创新和最佳业务实践，将可再生能源的效率提升至新高度。作为在深交所、港交所两地上市的公司，金风科技多次入选“气候领袖企业”、“亚洲地区最受尊敬公司”，并荣登“全球最具创新能力企业 50 强”、 “《财富》中国 500 强”等多个影响力榜单。走入中国首个可再生能源“碳中和”智慧园区，了解可再生能源背后的黑科技。',
      title02: '参访特色',
      desc02: [{
        text: '国内少有的介绍双碳达标趋势及技术的展厅'
      }, {
        text: '实地了解风力发电机'
      }],
      title03: '参访亮点',
      pic03: [{
        pic: Bg05,
        text: '深入了解风力发电机',
      }]
    }
  },{
    title: '走进京东：“生态圈”进化之旅',
    tips: '标杆参访',
    desc: '深入了解京东的商业模式“生态圈”是如何构建和发展的\n' +
      '探究京东的核心竞争力是如何形成的',
    level: [{
      pic: MiddleLevel,
      name: '中层管理者'
    }, {
      pic: HighLevel,
      name: '高层管理者'
    }],
    tag: [{
      pic: IconTime,
      name: '课程时长：',
      content: '0.5天'
    }, {
      pic: IconUser,
      name: '学员对象：',
      content: '企业一把手与他的核心团队、BU Leader 与直接汇报者、企业核心中层'
    }, {
      pic: IconFlag,
      name: '授课形式：',
      content: '展厅参观 / 现场体验 / 深度交流'
    }],
    base: {
      title01: '参访企业介绍',
      content01: '从 2013 到 2020 年的 7 年间，京东集团的净服务收入年均复合增长率达到 70%。2021 年《财富》世界 500 强京东跃升至 59 位。2020年，京东物流形成了一体化供应链解决方案。是全球唯一拥有六大物流网络的智能供应链企业。\n' +
        '自 1998 年成立以来，京东经历了前所有为的快速发展，构建了与阿里完全不同的“生态圈”商业模式。京东的战略发展之路是如何决策的？独特的“生态圈”商业模式又是如何设计的？让我们一起走进京东，了解互联网巨头的进化之路。',
      title02: '参访特色',
      desc02: [{
        text: '参观京东集团总部，体验京东无人机物流、无人超市，探寻京东最前沿的产品和服务方向'
      }, {
        text: '与京东高管深度交流，了解京东发展历程中的困惑与突破，探究京东独特的商业模式如何构建'
      }],
      title03: '参访亮点',
      pic03: [{
        pic: Bg06,
        text: '深入参观京东总部',
      }, {
        pic: Bg07,
        text: '了解京东共同奋斗文化',
      }]
    }
  }])

  const goToSlide = (index: number) => {
    if (carouselRef.current) {
      setNum(index)
      carouselRef.current.goTo(index)
    }
  };

  const onChange = (currentSlide: number) => {
    setNum(currentSlide)
  };

  return (
    <main className='warp-visit header-top'>
      <div className='warp-visit-content'>
        <div className='title'>找到标杆，学习标杆，超越标杆</div>
        <img className='visit-bg' src={VisitBg} alt=""/>
        <div className='home-page warp-visit-content-items'>
          {
            data.map(item => (
              <div className='content-item'>
                <div className='content-item-header'>
                  <div className='visit-header'>
                    <div className='visit-header-title'>{item.title}</div>
                    <div className='visit-header-tag flex-center'>{item.tips}</div>
                  </div>
                  <div className='visit-level'>
                    {
                      item.level.map(l => (
                        <>
                          <img src={l.pic} alt=""/>
                          <span>{l.name}</span>
                        </>
                      ))
                    }
                  </div>
                </div>
                <div className='content-item-tag'>
                  {
                    item.tag.map(t => (
                      <div className='tag-block'>
                        <img src={t.pic} alt=""/>
                        <span className='dur'>{t.name}</span>
                        <span className='day'>{t.content}</span>
                      </div>
                    ))
                  }
                </div>
                <div className='content-item-desc p-pre-wrap'>
                  {item.desc}
                </div>
                <div className='content-item-line'></div>
                <div className='content-item-block'>
                  <div className='b-title'>
                    {item.base.title01}
                  </div>
                  <div className='b-content p-pre-wrap'>
                    {item.base.content01}
                  </div>
                  <div className='b-title'>
                    {item.base.title02}
                  </div>
                  <div className='wrap-b-desc'>
                    {
                      item.base.desc02.map(d => (
                        <div className='b-desc'>
                          <div className='dot'></div>
                          <div className='text'>
                            <span>{d.name}</span>{d.text}
                          </div>
                        </div>
                      ))
                    }
                  </div>
                  <div className='b-title'>
                    {item.base.title03}
                  </div>
                  <div className='b-wrap-pic'>
                    {
                      item.base.pic03.map(p => (
                        <div className='b-wrap-pic-block'>
                          <img src={p.pic} alt=""/>
                          <div className='pic-text p-pre-wrap'>
                            {p.text}
                          </div>
                        </div>
                      ))
                    }
                  </div>
                </div>
              </div>
            ))
          }
          <div className='content-item'>
            <div className='content-item-header'>
              <div className='visit-header'>
                <div className='visit-header-title'>走进国际化企业系列</div>
                <div className='visit-header-tag flex-center'>标杆参访</div>
              </div>
              <div className='visit-level'>
                <img src={MiddleLevel} alt=""/>
                <span>中层管理者</span>
                <img src={HighLevel} alt=""/>
                <span>高层管理者</span>
              </div>
            </div>
            <div className='content-item-tag'>
              <div className='tag-block'>
                <img src={IconTime} alt=""/>
                <span className='dur'>考察时长：</span>
                <span className='day'>根据考察国家不同进行定制</span>
              </div>
              <div className='tag-block'>
                <img src={IconFlag} alt=""/>
                <span className='dur'>项目形式：</span>
                <span className='day'>国际化公司参访考察</span>
              </div>
            </div>
            <div className='content-item-line'></div>
            <div className='content-item-block'>
              <div className='b-title'>
                系列介绍
              </div>
              <div className='b-content p-pre-wrap'>
                光合企学为企业家和中高管群体提供海外商务考察项目，业务覆盖金融、科技、地产、区块链等不同行业。在海外商务考察项目中，
                不仅可以游学参访海外行业标杆企业，还能走进海外名校参与短期研修、私董会、酒会、当地创业项目路演等，从而帮助客户更全面、 深入地学习海外企业成功模式、寻找国内市场空白先机、了解海外市场现状等。
              </div>
              <div className='b-title'>
                项目特点
              </div>
              <div className='wrap-b-desc'>
                <div className='b-desc'>
                  <div className='dot'></div>
                  <div className='text'>
                    <span>专业化定制服务：</span>全球拥有合作资源，可以组织海外标杆企业访问、安排名企名校项目培训、设计深度商务考察之旅等，全程陪 同保障安全
                  </div>
                </div>
                <div className='b-desc'>
                  <div className='dot'></div>
                  <div className='text'>
                    <span>全方位资源整合：</span>可以搭建参会人员出行的专属机票实时预定平台以及统一支付系统，解决客户出行的各项票务难题，同时各国 签证保证效率
                  </div>
                </div>
                <div className='b-desc'>
                  <div className='dot'></div>
                  <div className='text'>
                    <span>高效率落地执行：</span>拥有金牌国际精英团队，具有大型研学项目操办经验，可以保证项目有效落地执行
                  </div>
                </div>
                <div className='b-desc'>
                  <div className='dot'></div>
                  <div className='text'>
                    <span>最大化降低成本：</span>与国内外的各大酒店以及航空公司具有长期深入的合作关系，最大程度地争取到优惠合作价
                  </div>
                </div>
              </div>
              <div className='content-item-line'></div>
              <div className='b-release'>
                <div className='b-release-title'>
                  01. 走进日本
                </div>
                <div className='b-content p-pre-wrap'>
                  日本是一个几乎没有任何天然资源的岛国，人力成本又极其的昂贵。然而经过日本人几十年的不懈努力，如今 Made in Japan 已是价
                  格经济、品质优良的代名词，并且出现了一批引领世界制造业的日本企业，在这样一个每一块钢板、每一个塑料配件都需要进口的国 度里，造出了风靡全球、深得消费者亲睐的产品。
                </div>
                <div className='b-title'>
                  聚焦问题
                </div>
                <div className='b-content-weight p-pre-wrap'>
                  日本制造的高品质为什么深入人心? <br/>
                  为什么日本产生了如此多的百年企业? <br/>
                  精益生产为什么在日本可以取得巨大成效? <br/>
                  日本的城市化如何在很短时间完成?......
                </div>
                <div className='b-title'>
                  考察机构一览
                </div>
                <img className='assume-bg' src={AssumeBg} alt=""/>
                <div className='b-title'>
                  考察主题
                </div>
                <Row gutter={[16, 16]} className='b-release-blocks'>
                  <Col md={8}>
                    <div className='b-release-block flex-center'>
                      精益管理交流分享
                    </div>
                  </Col>
                  <Col md={8}>
                    <div className='b-release-block flex-center'>
                      百年民企观摩学习
                    </div>
                  </Col>
                  <Col md={8}>
                    <div className='b-release-block flex-center'>
                      日本导师海外课堂
                    </div>
                  </Col>
                  <Col md={8}>
                    <div className='b-release-block flex-center'>
                      日本智慧城市探秘
                    </div>
                  </Col>
                  <Col md={8}>
                    <div className='b-release-block flex-center'>
                      医疗产业都市考察
                    </div>
                  </Col>
                  <Col md={8}>
                    <div className='b-release-block flex-center'>
                      日本特色小镇考察
                    </div>
                  </Col>
                </Row>
              </div>
              <div className='content-item-line'></div>
              <div className='b-release'>
                <div className='b-release-title'>
                  02. 走进英国
                </div>
                <div className='wrap-b-desc'>
                  <div className='b-desc'>
                    英国在金融行业中的地位一直处于上升趋势，连续数次蝉联全球前 20 大金融中心排名的冠军。 通过英国实地商务考察，您可以:
                  </div>
                  <div className='b-desc'>
                    <div className='dot'></div>
                    <div className='text'>
                      参加海外课堂主题学习，真正感受世界顶级大学的风采
                    </div>
                  </div>
                  <div className='b-desc'>
                    <div className='dot'></div>
                    <div className='text'>
                      全面了解英国金融市场，学习英国独立理财运营模式
                    </div>
                  </div>
                  <div className='b-desc'>
                    <div className='dot'></div>
                    <div className='text'>
                      考察英国房地产市场与英国保险行业运作
                    </div>
                  </div>
                  <div className='b-desc'>
                    <div className='dot'></div>
                    <div className='text'>
                      与家族办公室高管面对面交流，了解富有家族管理财富
                    </div>
                  </div>
                  <div className='b-desc'>
                    <div className='dot'></div>
                    <div className='text'>
                      了解英国投资市场，为未来投资英国做好铺垫
                    </div>
                  </div>
                </div>
                <div className='b-title m-b18'>
                  考察机构一览
                </div>
                <div className='b-release-box'>
                  <img src={Inspect01} alt=""/>
                  <div className='b-release-right'>
                    <div className='b-release-box-text'>
                      世邦魏理仕是全球地产咨询业的龙头老大。伦敦市场的一大特点，就是市场成熟度很高，很透明。通过拜访世邦魏理仕，了解英国房地产市场的投 资规则，学习如何在英国进行商业房产和住宅房投资。
                    </div>
                    <div className='b-release-box-name'>
                      考察分享:《英国房地产投资的注意事项以及在英国进行商业房产和住宅房投资的流程》
                    </div>
                  </div>
                </div>
                <div className='b-release-box'>
                  <img src={Inspect02} alt=""/>
                  <div className='b-release-right'>
                    <div className='b-release-box-text'>
                      Fleming & Partners 是由英国贵族所创立的家族办公室，目前由弗莱明家族第四代传人，马修·弗莱明 (Matthew Fleming) 所领导。集团提供各种业务，从长远的战略
                      规划、投资管理、企业融资到法律咨询 等内容。为富裕家庭、个人、慈善基金会等提供投资建议。
                    </div>
                    <div className='b-release-box-name'>
                      考察分享:《家族办公室:为富有家族管理财富的私人机构》
                    </div>
                  </div>
                </div>
                <div className='b-release-box'>
                  <img src={Inspect03} alt=""/>
                  <div className='b-release-right'>
                    <div className='b-release-box-text'>
                      伦敦证卷交易所成立于 1773 年，是世界四大证券交易所之一。在保持伦敦的领先地位方面，伦敦证券 交易所扮演着中心角色，它运作世界上国际最强的股票市场，其外国股票的交易超过其它任何证交所。
                    </div>
                    <div className='b-release-box-name'>
                      考察分享:《探访英国金融市场，了解金融体系》
                    </div>
                  </div>
                </div>
                <div className='b-release-box'>
                  <img src={Inspect04} alt=""/>
                  <div className='b-release-right'>
                    <div className='b-release-box-text'>
                      巴克莱银行是全球规模最大的银行及金融机构之一，是英国最古老的银行，具有逾 300 年历史，是全 世界第一家拥有 ATM 机的银行，发行了全英第一张信用卡与借记卡。巴克莱银行以英国为中心，提供
                      金融服务，主要业务是银行业及投资业。如果以资产来衡量，巴克莱银行是英国最大的金融银行之一。
                    </div>
                    <div className='b-release-box-name'>
                      考察分享:《探访英国金融市场，了解金融体系》
                    </div>
                  </div>
                </div>
              </div>
              <div className='content-item-line'></div>
              <div className='b-release'>
                <div className='b-release-title'>
                  03. 更多国际商务考察
                </div>
                <div className='b-release-hot'>
                  <img src={MoreInspect01} alt=""/>
                  <div className='b-release-hot-text'>
                    以色列早已成为全球领先的科技创新之国，虽然人口不足 1000 万，2/3 的国土是沙 漠。但却创造了多项世界奇迹:拥有全世界 22% 的诺贝尔奖获得者;在纳斯达克上 市企业多达 150
                    家，超过整个欧洲;每年创立 500+ 风投公司，创新密度超美国;拥 有 6000 多家科技企业，创业存活率 60%;人均拥有的初创企业数量位列世界第一。
                  </div>
                </div>
                <div className='b-release-hot'>
                  <img src={MoreInspect02} alt=""/>
                  <div className='b-release-hot-text'>
                    德国在基础科学与应用研究方面十分发达，以理学。工程技术而闻名的科研机构和发 达的职业教育支撑了德国的科学技术和经济发展。以汽车和精密机床为代表的高端制
                    造业，也是德国的重要象征，其工业产品以品质精良著称，技术领先，做工细腻，在 世界享有盛誉。德国的宝马，奔驰、大众、博世、西门子等优秀品牌遍布世界各地的 每个家庭，每年都吸引着大批海外人员前往考察。
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='warp-visit-content-carousel'>
          <div className='home-page'>
            <Carousel
              ref={carouselRef}
              dots={false}
              autoplay={true}
              afterChange={onChange}
            >
              {items.map(item => (
                <img className='carousel-img' src={item?.pic} />
              ))}
            </Carousel>
            <div className='flex-center'>
              {items.map((item, i) => (
                <div className={`case ${i === num ? 'case-active' : ''}`} onClick={() => goToSlide(i)}></div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default Visit