import React from 'react'
import { StudyTabs, TabPane} from '@/components/StudyTabs'
import BankList from './BankList'
import ElectricList from './ElectricList'

const Scheme: React.FC = () => {
  
  return (
    <main className='wrap-scheme header-top'>
      <StudyTabs activeKey='1'>
        <TabPane title="银行业" key="1">
          <BankList></BankList>
        </TabPane>
        <TabPane title="电力行业" key="2">
          <ElectricList></ElectricList>
        </TabPane>
      </StudyTabs>
    </main>
  )
}

export default Scheme