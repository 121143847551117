import React, { useState, useEffect } from 'react'
import {Col, Row} from 'antd'
import { useParams } from 'react-router-dom'
import FadeInOut from '@/components/FadeInOut'
import CaseLeft from './CaseLeft'
import CaseRight from './CaseRight'
import Bg01 from '@/assets/images/case/bg01.jpg'
import Bg02 from '@/assets/images/case/bg02.jpg'
import Bg03 from '@/assets/images/case/bg03.jpg'
import Banner02 from '@/assets/images/case/banner02.png'
import Banner03 from '@/assets/images/case/banner03.png'
import Banner04 from '@/assets/images/case/banner04.png'
import Banner05 from '@/assets/images/case/banner05.png'
import Client1 from '@/assets/images/home/client1.jpg'
import Client3 from '@/assets/images/home/client3.jpg'


const Case: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false)
  const [data, setData] = useState([{
    name: '智臻未来-关键人才培养训练营',
    desc: '锚定新生关键人才，打造职业发展助力器',
    banner1: Bg01,
    recommend: {
      title: '公司介绍',
      tips: '企业性质：某大型民营集团化物业公司',
      desc: '该物业公司是中国最早一批接轨国际的物业公司，现正为20多家世界500强企业和50多家跨国公司、数十个写字楼、商务园、科技园、文创园、数字医院项目提供专业化、规范化、特色化的高品质物业管理服务，综合管理面积达500万平方米。'
    },
    backdrop: {
      title: '项目背景',
      desc: '该物业集团在行业内始终关注人才培养与组织梯队建设，人才梯队建设一直是该物业集团发展的关键点和核心。但随着公司规模扩大，众多Z时代员工加入，需要对关键人才进行更加系统化、针对化的培养，才能够充分发挥关键人才的优势，为未来管理晋升做好更强力的人才储备。“智臻未来”关键人才培养训练营在以上背景下开展，全力为企业培养人才，激发组织活力，促进各产品线/各业务线业务价值共生，加速第二曲线创新增长。'
    },
    target: {
      title: '项目目标',
      descents: [
        {
          name: '遵循物业行业发展规律',
          desc: '通过研究物业行业发展趋势、挑战和规律，使项目符合物业行业的内在规律'
        },
        {
          name: '密连物业业务特点及企业文化',
          desc: '通过需求访谈调研了解该物业集团战略及业务重点，分析战略落地的关键瓶颈和障碍，使项目符合公司的战略重点，并符合文化特点'
        },
        {
          name: '契合学员的角色要求和岗位挑战',
          desc: '通过访谈调研，了解各层级关键人才的典型挑战、能力要求和现状，充分掌握目标人群的能力水平，匹配合适的课程内容和教学手段'
        }
      ]
    },
    pattern: {
      title: '项目模式及实施框架',
      desc: '本项目采用分阶段培养，通过课前调研+集中面授+课后研讨+训后实践，共四大项目阶段进行实施，项目为期6个月，培训时长11天。\n' +
        '培训主题分别为：合作共生、优势成长、智慧物业、创新思维、商务表达、创新大赛',
      pic: Client1
    },
    achieve: {
      title: '项目成果及价值评估',
      descents: [
        {
          // name: '拉升业务视野 建立全局观念',
          desc: [
            '集团高管：通过结项课后作业，结项汇报等肯定项目价值',
            '培训对接人：探索项目模式，后续复制',
            '学员：领导力意识提升，加深了不同业务线条的联系和理',
          ]
        },
        // {
        //   name: '提高管理能力 增进团队凝聚',
        //   desc: [
        //     '项目放弃传统的纯讲授模式，频繁的研讨和小组共创，帮助学员消辑认知差异。\n能更顺畅的转换立场，需求共赢。',
        //     '在团队教练带领下，建立起未来协同中的“信任宣言”和“冲突契约”，为今后并肩作战打下坚实基础。',
        //     '关于团队人才管理的学习，帮助学员切实掌握人才供应链及梯队的建设方式，为提升人才厚度做好铺垫。',
        //   ]
        // }
      ],
      pic: Banner05,
      // pic2: Banner05
    }
  },{
    name: '金色传承·希望之星 高管继任项目',
    desc: '提升经营视野，建设继任梯队，加深人才厚度',
    banner1: Bg02,
    recommend: {
      title: '公司介绍',
      tips: '公司性质：北京某大型国有黄金珠宝专营公司',
      desc: '该公司是商务部第一批命名的“中华老字号”企业，作国有控股企业，是西城区国资委的重要子企业。经过几十年的发展，该公司在黄金方面积累了丰富的经验，是北京市场收入规模领先的黄金珠宝企业，也是国内黄金珠宝商品的原料采购、款式设计、连锁销售和品牌运营珠宝行业中直营收入规模领先、单店收入规模领先，线线下全渠道经营的黄金珠宝专业经营公司。'
    },
    backdrop: {
      title: '项目背景',
      desc: '该公司在2022年成功上市，是其60余年企业发展的里程碑，也是企业第一代领导班子的成功写照。在上市后，该公司即将进入新老交替的阶段，面对疫情后多变脆弱的商业环境，以及上市后更为全面严格的企业治理要求，对新一代领导班子提出了更高更系统的能力要求。为了能更高效推进企业在新阶段的持续发展，帮助领导班子成员具备大局视野和经营格局，建设更完善的领导干部梯队，优化加深人才厚度，使企业精神能在传承中延续下去，该公司于2023年度开展系统性的领导干部培养项目，结合公司发展阶段以及企业业务特色，从思维到认知到行动，对领导干部梯队进行了全方位的发展和提升。'
    },
    target: {
      title: '项目目标',
      descents: [
        {
          name: '公司目标',
          desc: '将公司品牌打造成具有国际竞争实力的优秀品牌，通过领军人才的培养，实现集团化公司发展'
        },
        {
          name: '组织目标',
          desc: '为实现公司战略目标提供人才保障，搭建中高层管理人才培养体系，增加人才储备'
        },
        {
          name: '人才目标',
          desc: '通过体系化和多元化的项目设计，实现公司中高层管理干部“能力-态度-认知”的跃迁'
        }
      ]
    },
    pattern: {
      title: '项目模式及实施框架',
      desc: '“人才强企、人才兴企”的人才培养理念，在本项目密结合企业认知 - 能力 - 态度三管齐下，全面提前期深入的调研基础上，从升管理干部的能力水平，建立高管梯队，从战略规划导入，辅以多重业务主题培训，并以行力学习贯穿全程，确保学习效果落地。认凝聚。同时引入团队教练，催化团队凝聚力。',
      pic: Banner02
    },
    achieve: {
      title: '项目成果及价值评估',
      descents: [
        {
          name: '拉升业务视野 建立全局观念',
          desc: [
            '项目以战略规划为起点，持续推动各个学员跳出职能局限，以一个经营者的视角全盘考虑业务发展，帮助学员切实掌握战略规划和解码的工具方法论。',
            '商业与经营主题的研讨，帮助学员俯瞰业务价值链，促成工作上下游的协调联动。',
            '紧贴业务主题的各项学习，帮助学员有了将各项业务方向扎实落地的抓手。',
          ]
        },
        {
          name: '提高管理能力 增进团队凝聚',
          desc: [
            '项目放弃传统的纯讲授模式，频繁的研讨和小组共创，帮助学员消辑认知差异。\n能更顺畅的转换立场，需求共赢。',
            '在团队教练带领下，建立起未来协同中的“信任宣言”和“冲突契约”，为今后并肩作战打下坚实基础。',
            '关于团队人才管理的学习，帮助学员切实掌握人才供应链及梯队的建设方式，为提升人才厚度做好铺垫。',
          ]
        }
      ],
      pic: Banner03
    }
  },{
    name: '鸿鹄-核心人才项目',
    desc: '树立创新管理新格局，实现管理干部能力再造',
    banner1: Bg03,
    recommend: {
      title: '公司介绍',
      tips: '企业性质：国有清洁能源上市企业',
      desc: '该公司创建于1995年，2009年在香港联交所主板上市。2019年11月，公司获山东省属国有企业水发集团有限公司增资控股，成为水发集团布局粤港澳大湾区的国资控股港股上市公司。历经近30年的稳健发展和持续创新，公司逐步发展为专注清洁能源、绿色建筑、新型材料三大产业的高新技术企业集团，拥有8家一级权属公司，资产规模超200亿元人民币，成为行业重要参与者、贡献者、引领者。'
    },
    backdrop: {
      title: '项目背景',
      desc: '随着市场竞争的日益激烈，企业对于人才的需求也日益凸显。该公司作为一家实力雄厚的国有企业，深知人才对于企业发展的重要性。为更好地培养核心管理干部，提高企业整体竞争力，该公司推出了“鸿鹄”核心人才项目。'
    },
    target: {
      title: '项目目标',
      desc: '“鸿鹄”核心人才项目的目标是培养一支素质更高、能力更强、为企业发展发挥更大作用的新时代人才队伍，贡献新时代人才队伍的奋进力量。一是要以创新管理体系为学习的出发点，通过通不断学习来树立创新管理思维，为企业加快建立创新管理体系出点子、定策略，推动建成管理新格局，二要以解决实际问题着力点，三要以服务发展大局为学习的根本点，在学习中思考如何将所学所悟用于发展实践。',
      // descents: [
      //   {
      //     name: '公司目标',
      //     desc: '将公司品牌打造成具有国际竞争实力的优秀品牌，通过领军人才的培养，实现集团化公司发展'
      //   },
      //   {
      //     name: '组织目标',
      //     desc: '为实现公司战略目标提供人才保障，搭建中高层管理人才培养体系，增加人才储备'
      //   },
      //   {
      //     name: '人才目标',
      //     desc: '通过体系化和多元化的项目设计，实现公司中高层管理干部“能力-态度-认知”的跃迁'
      //   }
      // ]
    },
    pattern: {
      title: '项目模式及实施框架',
      desc: '此项目周期为三个月，共完成12天线下集中学习；4次面授，两次同步线上学习；1次拓展团建、1次企业研学、1次沙盘模拟、1次行动学习。\n' +
        '“鸿鹄”核心人才项目，在设计之初充分考虑了国企干部培养的能力模型与成长规律，紧密结合了水发兴业集团对核心管理干部的培训诉求，从政治素养、经营意识、财务敏锐、创新思维、团队领导等多视角出发，采用多元化的学习形式，进行内容设计。项目中充分融合了团队拓展、红色研学、集中授课、名企参访、研讨工作坊等体验式学习方式，犹如一个多元化的学习矩阵，不仅提供了一个全面的知识体系，还通过实践和体验，使学员得以深入理解和掌握知识，进一步提升其综合能力，且有利于培养出一批具有高度责任感和创新能力的管理干部，为企业的持续发展和提升竞争力提供坚实的支持。',
      pic: Client3
    },
    achieve: {
      title: '项目成果及价值评估',
      descents: [
        {
          name: '核心成果',
          desc: [
            '项目整体出勤率高达96%',
            '作业完成率100%',
            '产出《先锋学习成果展示板》报告',
            '落地26份《个人学习计划》',
            '产出5组课题报',
          ]
        },
        {
          name: '价值评估',
          desc: [
            '学员们在有限的时间内积极参与各项活动，努力提升自己的能力和素质。这种高投入度和专注力不仅体现了他们对项目的重视，还为项目的成功实施提供了有力保障。',
            '每期课程结束后，大家均积极高效的完成小组作业和个人作业。透过作业不仅锻炼了学员的实际操作能力，还通过数据分析发现了许多业务问题。这些数据为公司的决策提供了有力的支持，帮助学员更好地理解业务需求和目标。',
            '先锋学习成果报告不仅展示了学员的学习成果，还为公司提供了宝贵的经验和启示，有助于推动业务发展和创新。',
            '计划表不仅体现了学员的个人成长规划，还通过数据对比发现了每个学员的学习特点和优势。这些数据为公司的培训计划和人才发展提供了有益的参考，有助于更好地满足员工个人成长的需求。',
            '课题报告不仅展示了学员在解决实际问题中的能力，还通过分析和案例研究为公司解决实际问题提供了有效的方法和策略，有助于提高业务效率和降低成本。',
          ]
        }
      ]}
  }])
  const [Item, setItem] = useState(data[0])
  const { id } = useParams()

  useEffect(() => {
    setIsVisible(true)
    setItem(data[id || 0])
  }, [])

  const changeData = (newItem) => {
    setItem(newItem)
    setIsVisible(false)
    setTimeout(() => {
      setIsVisible(true)
    }, 100)
  }

  return (
    <main className='wrap-case header-top'>
      <Row className='wrap-case-content home-page'>
        <FadeInOut isVisible={isVisible}>
          <CaseLeft item={Item} />
        </FadeInOut>
        <CaseRight data={data} idx={id || 0} changeData={changeData} />
      </Row>
    </main>
  )
}

export default Case