import React, {useRef, useState} from 'react'
import {Row, Col, Carousel as AntCarousel} from 'antd'
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack'
import QueueAnim from 'rc-queue-anim'
import TweenOne from 'rc-tween-one'
import Children from 'rc-tween-one/lib/plugin/ChildrenPlugin';
import TcLy01 from '@/assets/images/tc_ly01.png'
import TcCgj02 from '@/assets/images/tc_cgj02.png'
import TcXyj03 from '@/assets/images/tc_xyj03.png'
import TcLys04 from '@/assets/images/tc_lys04.png'
import TcTm05 from '@/assets/images/tc_tm05.png'
import ModelVideo from "@/components/ModelVideo";
const chunkSize = 3;

TweenOne.plugins.push(Children);

const Lecturer: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [current, setCurrent] = useState(0)
  const [obj, setObj] = useState(null)
  const [data, setData] = useState([
    {
      name: '李越',
      desc: '复盘百企上市案例，提炼出一套系统实用的公司治理思维框架。曾任东方花旗投行董事及新三板融资并购监管经理。',
      cosKey: 'https://lecturermap-1319224539.cos.ap-beijing.myqcloud.com/cdn/%E5%AE%98%E7%BD%91/%E6%9D%8E%E8%B6%8A.mp4',
      img: TcLy01
    },
    {
      name: '程广见',
      desc: '销售全流程与信用回款研究第一人。《成交心法》作者，得到《跟程广见学做大客户销售》课程主理人，抖音粉丝超500W。',
      cosKey: 'https://lecturermap-1319224539.cos.ap-beijing.myqcloud.com/cdn/%E5%AE%98%E7%BD%91/%E7%A8%8B%E5%B9%BF%E8%A7%81.mp4',
      img: TcCgj02
    },
    {
      name: '解永军',
      desc: '清华大学继续教育学院特聘讲师，对外经贸大学客座教授。原正略钧策合伙人、副总裁、战略营销咨询中心总经理、培训与人才发展中心总经理。',
      cosKey: 'https://lecturermap-1319224539.cos.ap-beijing.myqcloud.com/cdn/%E5%AE%98%E7%BD%91/%E8%A7%A3%E6%B0%B8%E5%86%9B.mp4',
      img: TcXyj03
    },
    {
      name: '刘月松',
      desc: '实战演训教学模式创始人，中国经营管理实战演训教学研究院院长。三十余所知名高校总裁研修班实战演训课程导师。',
      cosKey: '',
      img: TcLys04
    },
    {
      name: '汤敏',
      desc: '原国务院参事，中国经济50人成员。兼任北京大学中国经济研究中心教授，国务院扶贫办友成企业家扶贫基金会常务副理事长。',
      cosKey: '',
      img: TcTm05
    }
  ])
  const carouselRef = useRef(null)

  const goToSlide = (index: number) => {
    if (carouselRef.current) {
      setCurrent(index)
      carouselRef.current.goTo(index)
    }
  };
  const imgAnim: any = false
    ? {
      y: 30,
      opacity: 0,
      delay: 600,
      type: 'from',
      ease: 'easeOutQuad',
    }
    : {
      x: 30,
      opacity: 0,
      type: 'from',
      ease: 'easeOutQuad',
    };

  const handlePlay = (item) => {
    setObj(item)
    setIsModalOpen(true)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const onChange = (currentSlide: number) => {
    setCurrent(currentSlide)
  };

  const onPackChange = (obj) => {
    if(obj.mode === 'leave') {
      setCurrent(0)
    }
  }

  const groups = [];
  for (let i = 0; i < data.length; i += chunkSize) {
    groups.push(data.slice(i, i + chunkSize));
  }
  return (
    <div className='wrap-lecturer home-page'>
      <OverPack playScale={0.3} component={Row} className='wrap-lecturer-box'>
        <QueueAnim
          key="text"
          type="left"
          leaveReverse
          ease={['easeOutQuad', 'easeInQuad']}
        >
          <QueueAnim
            component="ul"
            key="ul"
            type="left"
            ease="easeOutQuad"
          >
            <div className='wrap-lecturer-box-left'>
              <div className='title'>
                311+位实战专家、企业家高管、教授及商业讲师
              </div>
              {/*<div className='to-lecturer'>*/}
              {/*  <span>前往讲师地图</span>*/}
              {/*  <img src={BigArrows} alt=""/>*/}
              {/*</div>*/}
            </div>
          </QueueAnim>
        </QueueAnim>
        <QueueAnim
          key="right"
          leaveReverse
          type="right"
          delay={[0, 100]}
        >
          <TweenOne
            key="img"
            animation={imgAnim}
            component={Col}
          >
            <div className='wrap-lecturer-box-right'>
              <div className='block'>
                <div className='block-title'>优质课程</div>
                <div className='block-num'>
                  <TweenOne
                    key='2'
                    animation={{
                      Children: {
                        value: 961,
                        floatLength: 0,
                        formatMoney: true,
                      },
                      duration: 1000,
                      delay: 300,
                      ease: 'easeInOutCirc',
                    }}
                    component="span"
                  >
                    0
                  </TweenOne>
                  +
                </div>
              </div>
              <div className='block'>
                <div className='block-title'>累计服务企业学员</div>
                <div className='block-num'>
                  <TweenOne
                    key='1'
                    animation={{
                      Children: {
                        value: 20000,
                        floatLength: 0,
                        formatMoney: true,
                      },
                      duration: 1000,
                      delay: 300,
                      ease: 'easeInOutCirc',
                    }}
                    component="span"
                  >
                    0
                  </TweenOne>
                  +
                </div>
              </div>
            </div>
          </TweenOne>
        </QueueAnim>
      </OverPack>
      <OverPack playScale={0.3} onChange={onPackChange}>
        <QueueAnim
          type="bottom"
          key="block"
          delay={[0, 100]}
          className='wrap-lecturer-detail'
        >
          <div key="carousel">
            <AntCarousel
              ref={carouselRef}
              dots={false}
              infinite={false}
              afterChange={onChange}
            >
              {
                groups.map((group, groupIndex) => (
                  <div key={`row-${groupIndex}`}>
                    <QueueAnim
                      type="bottom"
                      component={Row}
                      style={{width: '100%'}}
                    >
                      {
                        group.map((item, i) => (
                          <Col key={i.toString()} md={8}>
                            <div className='item-block'>
                              <div className='item-block-font'>
                                <div className='item-block-font-top'>
                                  <div className='name'>{item.name}</div>
                                  <div className='desc'>{item.desc}</div>
                                </div>
                                {item?.cosKey && <div className='l-img' onClick={() => handlePlay(item)}></div>}
                              </div>
                              <img className='item-block-person' src={item.img} />
                            </div>
                          </Col>
                        ))
                      }
                    </QueueAnim>
                  </div>
                ))
              }
            </AntCarousel>
            <div className='flex-center m-t30'>
              {groups.map((item, i) => (
                <div className={`case ${i === current ? 'case-active' : ''}`} onClick={() => goToSlide(i)}></div>
              ))}
            </div>
          </div>
        </QueueAnim>
      </OverPack>
      <ModelVideo isModalOpen={isModalOpen} handleCancel={handleCancel} data={obj} />
    </div>
  )
}

export default Lecturer