import React, {useState} from 'react'
import { Row, Col } from 'antd'
import IconOrganize from '@/assets/images/product/study/icon-organize.png'
import IconCulture from '@/assets/images/product/study/icon-culture.png'

const Organize: React.FC = () => {
  const [data, setData] = useState([{
    title: '组织',
    content: [{
      name: '组织罗盘',
      desc: '打造自驱型组织、构建高活力团队、绽放企业生命力',
      pic: IconOrganize
    },{
      name: '组织诊断：5工具+7模式',
      desc: '破解组织诊断与组织评估、掌握组织诊断核心工具、7大组织诊断实操落地方式',
      pic: IconOrganize
    },{
      name: '组织变革与组织发展',
      desc: '掌握变革程序，破解阻力挑战，实操关键工具，推动组织变革与发展',
      pic: IconOrganize
    },{
      name: '组织发展与效能提升',
      desc: '构建战略导向组织系统，定制机制解难题，输出变革管理方法，强化组织作战力',
      pic: IconOrganize
    },{
      name: '数字化时代的组织重构与创新',
      desc: '精通组织优化与文化建设，提升设计思维，赋能领导团队战略创新，引领变革跨界合作',
      pic: IconOrganize
    },{
      name: '从战略到组织—打造活力组织的八大引擎',
      desc: '掌握战略管理价值，解码核心路径，激活组织活力，有效授权提效',
      pic: IconOrganize
    },{
      name: '阿里六个盒子',
      desc: '精通阿里六个盒子工具，借鉴落地经验，实操四大场景提升组织诊断力',
      pic: IconOrganize
    },{
      name: '阿米巴合伙人的激励模式',
      desc: '精通阿米巴经营，现场辅导+案例，带走解决方案，1对1突破关键点',
      pic: IconOrganize
    },{
      name: '组织影响力',
      desc: '新经济下组织趋势，五项沟通修炼，三点高效影响，策略制定，模拟复盘',
      pic: IconOrganize
    }]
  },{
    title: '文化',
    content: [{
      name: '引领文化变革',
      desc: '案例研讨价值观落地挑战，跨文化变革，匹配手段周期，规划变革路径',
      pic: IconCulture
    },{
      name: '使命愿景共识工作',
      desc: '建立共同价值观和目标、推动组织变革发展、实现组织的长期发展和可持续成功',
      pic: IconCulture
    },{
      name: '价值观研讨工作坊',
      desc: '共识共创、萃取案例、提炼特质、价值具化',
      pic: IconCulture
    },{
      name: '定义价值观',
      desc: '引导员工形成正确的价值观、提高员工的素质和能力、促进企业的发展和成长',
      pic: IconCulture
    },{
      name: '文化制胜',
      desc: '从“战略流程图”分析文化制胜之理，获取成功的管理公式',
      pic: IconCulture
    },{
      name: '企业文化建设的关键时刻',
      desc: '文化在兴奋点播种，危机中坚守，典型对比彰显文化价值、变动中的文化传承',
      pic: IconCulture
    },{
      name: '文化领导力',
      desc: '结合高频战略域业务场景，管理团队创佳绩，激发团队精气神与组织战斗力',
      pic: IconCulture
    },{
      name: '成为首席文化官',
      desc: '实战学企业文化创造，掌握企业文化建设与建设管理策略，实战智慧保实效',
      pic: IconCulture
    },{
      name: '数字化时代企业文化系统建设与高校落地方法',
      desc: '掌握数字化时代下的企业文化建设新思路，案例模拟实操落地策略',
      pic: IconCulture
    }]
  }])
  return (
    <main className='warp-organize header-top'>
      <div className='warp-organize-bg flex-center'>
        从企业宏观发展出发，帮助企业进行组织现状诊断，助力企业文化在组织 <br />
        内的更新与落地，为企业长久发展打下良好根基。
      </div>
      <div className='warp-organize-content home-page'>
        {
          data.map(item => (
            <>
              <div className='warp-organize-content-title'>
                {item.title}
              </div>
              <Row gutter={[16, 16]} className='warp-organize-content-blocks'>
                {
                  item.content.map((c, index) => (
                    <Col md={8}>
                      <div className='warp-organize-content-block'>
                        <div className='block-top'>
                          <span>0{index + 1}.</span>
                          <img src={c.pic} alt=""/>
                        </div>
                        <div className='block-bottom'>
                          <div className='block-bottom-title'>{c.name}</div>
                          <div className='block-bottom-desc'>{c.desc}</div>
                        </div>
                      </div>
                    </Col>
                  ))
                }
              </Row>
            </>
          ))
        }

      </div>
    </main>
  )
}

export default Organize