import React from 'react'
// @ts-expect-error
import { CSSTransition } from 'react-transition-group'
import './index.less'

const FadeInOut = ({ isVisible, children }) => {
  return (
    <CSSTransition
      in={isVisible}
      timeout={800}
      classNames="fade"
    >
      {children}
    </CSSTransition>
  );
};

export default FadeInOut