import React from 'react';
import Banner from './Banner'
import Lecturer from './Lecturer'
import Dynamic from './Dynamic'
import Consult from './Consult'
import Teams from './Teams'

const Home: React.FC = () => {

  return (
    <main className='wrap-home header-top'>
      <Banner />
      <Lecturer />
      <Consult />
      <Dynamic />
      <Teams />
    </main>
  )
}

export default Home