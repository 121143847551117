import React from 'react'
import { Row, Col } from 'antd'
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack'
import QueueAnim from 'rc-queue-anim'
import TweenOne from 'rc-tween-one'
import Bg01 from '@/assets/images/scheme/theme/bg01.png'
import Bg02 from '@/assets/images/scheme/theme/bg02.png'
import Bg03 from '@/assets/images/scheme/theme/bg03.png'
import Bg04 from '@/assets/images/scheme/theme/bg04.png'
import Bg05 from '@/assets/images/scheme/theme/bg05.png'
import {getAnim} from '@/utils'

const Theme: React.FC = () => {

  const imgAnim: any = getAnim()
  
  return (
    <main className='wrap-theme header-top'>
      <div className='wrap-theme-custom home-page'>
        <div className='title'>
          项目定制
        </div>
        <div className='desc'>
          根据企业战略规划及组织架构，落地到企业员工成长需求，为企业提供定制化项目设计。
        </div>
        <div className='wrap-theme-custom-block'>
          <div className='block-left'>
            <div className='block-left-title'>
              混合OMO <br />
              教学模式
            </div>
            <div className='block-left-desc'>
              通过OMO线上线下相融合的教学模式，充分带动学员的学习积极性，为学员提供全场景、个性化的学习体验。<br /> <br />
              探索未来企业教育创新途径，在企业内部打造学习热潮。
            </div>
          </div>
          <div className='block-right'>
            <div className='block-right-box'>
              <div className='block-right-box-bg '>
                <div className='block-right-box-bg-sub'>测</div>
              </div>
              <div className='block-right-box-line'>
                /
              </div>
              <div className='block-right-box-title'>能力锚定</div>
              <div className='block-right-box-desc'>
                素质模型构建 <br />
                专业能力测评 <br />
                测评报告解读 <br />
                能力现状诊断
              </div>
            </div>
            <div className='block-right-box'>
              <div className='block-right-box-bg '>
                <div className='block-right-box-bg-sub'>训</div>
              </div>
              <div className='block-right-box-line'>
                /
              </div>
              <div className='block-right-box-title'>知识传授</div>
              <div className='block-right-box-desc'>
                情境导入 <br />
                案例教学 <br />
                社群学习 <br />
                带教辅导
              </div>
            </div>
            <div className='block-right-box'>
              <div className='block-right-box-bg '>
                <div className='block-right-box-bg-sub'>练</div>
              </div>
              <div className='block-right-box-line'>
                /
              </div>
              <div className='block-right-box-title'>学以致用</div>
              <div className='block-right-box-desc'>
                沙盘模拟 <br />
                实操演练 <br />
                观察反馈 <br />
                返岗实践
              </div>
            </div>
            <div className='block-right-box'>
              <div className='block-right-box-bg '>
                <div className='block-right-box-bg-sub'>评</div>
              </div>
              <div className='block-right-box-line'>
                /
              </div>
              <div className='block-right-box-title'>效果评估</div>
              <div className='block-right-box-desc'>
                学习自主性评估 <br />
                行为转化评估 <br />
                知识吸收评估 <br />
                学习成果评估
              </div>
            </div>
          </div>
        </div>
      </div>
      <OverPack playScale={0.3} className='wrap-theme-output'>
        <QueueAnim
          key="text"
          type="bottom"
          leaveReverse
          ease={['easeOutQuad', 'easeInQuad']}
          className='home-page'
        >
          <div className='title' key='title'>
            行动课题研究，训战结合，助力学习成果转化为业绩产出
          </div>
          <div key='img'>
            <img src={Bg01} width='1178px' height='403px' />
          </div>
        </QueueAnim>
      </OverPack>

      <OverPack playScale={0.3} className='wrap-theme-leach'>
        <QueueAnim
          key="text"
          type="bottom"
          leaveReverse
          ease={['easeOutQuad', 'easeInQuad']}
          className='home-page'
        >
          <div className='title' key='title'>
            萃取标杆经验，打破数字化转型矛盾，十倍提升人效
          </div>
          <div key='img'>
            <img src={Bg02} width='1200px' height='750px' />
          </div>
        </QueueAnim>
      </OverPack>

      <div className='wrap-theme-vie'>
        <div className='home-page'>
          <div className='title'>
            聚焦于企业发展场景与痛点，上接战略、下接效能，助力企业持续提升核心竞争力！
          </div>
          <OverPack playScale={0.3} component={Row} gutter={20}>
            <QueueAnim
              key="text"
              type="left"
              leaveReverse
              ease={['easeOutQuad', 'easeInQuad']}
              component={Col}
              md={12}
            >
              <QueueAnim
                key="ul"
                type="left"
                ease="easeOutQuad"
              >
                <div className='wrap-theme-vie-block'>
                  <div className='vie-block-header'>
                    <div className='vie-block-header-big'>人才</div>
                    <div className='vie-block-header-line'></div>
                    <div>【培养新生力量加速新员工成长】</div>
                  </div>
                  <div className='vie-block-content'>
                    <div className='vie-block-content-desc m-t26'>
                      <span className='dot'></span>
                      “三融成长模型“，助力新员工倍速成长，驾驭液态职场。
                    </div>
                    <Row gutter={10} className='m-t13'>
                      <Col md={8}>
                        <div className='vie-block-content-box'>
                          <div className='vie-block-content-box-title'>
                            生存需要
                          </div>
                          <div className='m-t8 vie-block-content-box-desc'>
                            新员工初入职场，开始正式获得薪酬，渴望凭借自身力量、获得有品质的美好生活。
                          </div>
                          <div className='vie-block-content-box-sub'>
                            融入企业
                          </div>
                        </div>
                      </Col>
                      <Col md={8}>
                        <div className='vie-block-content-box'>
                          <div className='vie-block-content-box-title'>
                            人际关系需要
                          </div>
                          <div className='m-t8 vie-block-content-box-desc'>
                            新员工面临新环境，渴望存在感，希望获得他人的尊重、认同，并建立新的社交关系。
                          </div>
                          <div className='vie-block-content-box-sub'>
                            融入团队
                          </div>
                        </div>
                      </Col>
                      <Col md={8}>
                        <div className='vie-block-content-box'>
                          <div className='vie-block-content-box-title'>
                            成长发展需要
                          </div>
                          <div className='m-t8 vie-block-content-box-desc'>
                            新员工面临新任务，渴望发展个人潜能，在职场获得自我发展、自我完善和自我实现。
                          </div>
                          <div className='vie-block-content-box-sub'>
                            融入职场
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <div className='vie-block-content-desc m-t20'>
                      <span className='dot'></span>
                      一套新员工人才培养计划，奠定人才梯队基石，增强企业内聚力。
                    </div>
                    <Row gutter={[10, 12]} className='m-t15'>
                      <Col md={12}>
                        <div className='vie-block-content-plan'>
                          <span>1</span>
                          套专属职业规划
                        </div>
                      </Col>
                      <Col md={12}>
                        <div className='vie-block-content-plan'>
                          <span>3</span>
                          大成长维度
                        </div>
                      </Col>
                      <Col md={12}>
                        <div className='vie-block-content-plan'>
                          <span>9</span>
                          大管理场景
                        </div>
                      </Col>
                      <Col md={12}>
                        <div className='vie-block-content-plan'>
                          <span>24</span>
                          个落地工具
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </QueueAnim>
            </QueueAnim>
            <TweenOne
              key="img"
              animation={imgAnim}
              resetStyle
              component={Col}
              md={12}
            >
              <div className='wrap-theme-vie-block' style={{paddingTop: '23px'}}>
                <div className='vie-block-header' style={{paddingBottom: '12px'}}>
                  <div className='vie-block-header-big'>组织</div>
                  <div className='vie-block-header-line'></div>
                  <div className='vie-block-header-desc'>
                    <div className='vie-block-header-desc-top'>【扶摇】关键中层打造计划</div>
                    <div className='vie-block-header-desc-bottom'>让每一个中层都具有高管视野</div>
                  </div>
                </div>
                <Row className='m-t20'>
                  <Col md={12}>
                    <div className='vie-block-content-desc header-desc'>
                      <span className='dot'></span>
                      基于“战略·业务·团队”三位一体的逻辑进行培养旨在为企业打造一支复合型经营人才队伍。
                    </div>
                  </Col>
                  <Col md={12}>
                    <img src={Bg03} width={'242px'} height={'220px'} />
                  </Col>
                </Row>
                <div className='vie-block-content-desc m-t11'>
                  <span className='dot'></span>
                  三大关键阶段，让改变真正发生
                </div>
                <Row gutter={10} className='m-t15'>
                  <Col md={8}>
                    <div className='vie-block-content-box'>
                      <div className='vie-block-content-box-sub'>
                        关键识别
                      </div>
                      <div className='m-t8 vie-block-content-box-desc'>
                        根据公司战略要求，明确关键中层发展标准，通过相关举措识别可发展人才进入培养池。
                      </div>
                    </div>
                  </Col>
                  <Col md={8}>
                    <div className='vie-block-content-box'>
                      <div className='vie-block-content-box-sub'>
                        关键发展
                      </div>
                      <div className='m-t8 vie-block-content-box-desc'>
                        根据关键中层人才培养标准定制培养计划，紧贴其发展路径进行逐层深入辅导提升。
                      </div>
                    </div>
                  </Col>
                  <Col md={8}>
                    <div className='vie-block-content-box'>
                      <div className='vie-block-content-box-sub'>
                        关键评估
                      </div>
                      <div className='m-t8 vie-block-content-box-desc'>
                        建立完善的评估机制与追踪体系，对关键中层进行个性化的全方位评估。
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </TweenOne>
          </OverPack>
          <OverPack playScale={0.3} component={Row} gutter={20} className='m-t30'>
            <QueueAnim
              key="text"
              type="left"
              leaveReverse
              ease={['easeOutQuad', 'easeInQuad']}
              component={Col}
              md={12}
            >
              <QueueAnim
                key="ul"
                type="left"
                ease="easeOutQuad"
              >
                <div className='wrap-theme-vie-block' style={{height: '504px'}}>
                  <div className='vie-block-header'>
                    <div className='vie-block-header-big'>数字化</div>
                    <div className='vie-block-header-line'></div>
                    <div>【培养新生力量加速新员工成长】</div>
                  </div>
                  <div className='vie-block-content-desc header-desc m-t26'>
                    <span className='dot'></span>
                    <div style={{fontWeight: 600}}>
                      <span>聚焦人效提升高性价比构建数字化转型基础。</span>
                      <br />
                      <div style={{fontWeight: 400, fontSize: '14px', marginTop: '5px'}}>
                        熙诚教育投资联合世界五百强标杆工厂，共同研发探寻如何通过“精益+数字化”
                        实现人效和经营效率的十倍增长！
                      </div>
                    </div>
                  </div>
                  <img className='m-l32' src={Bg04} width={'461px'} height={'206px'} />
                  <div className='vie-block-content-desc m-t28'>
                    <span className='dot'></span>
                    提供四项【独家体验】
                  </div>
                  <div className='vie-block-content-tips m-t12 m-l15'>
                    独家萃取 ｜ 独家参访 ｜ 独家体验 ｜ 独家经验
                  </div>
                </div>
              </QueueAnim>
            </QueueAnim>
            <TweenOne
              key="img"
              animation={imgAnim}
              resetStyle
              component={Col}
              md={12}
            >
              <div className='wrap-theme-vie-block' style={{height: '504px', paddingTop: '23px'}}>
                <div className='vie-block-header' style={{paddingBottom: '12px'}}>
                  <div className='vie-block-header-big'>思维</div>
                  <div className='vie-block-header-line'></div>
                  <div className='vie-block-header-desc'>
                    <div className='vie-block-header-desc-top'>【系统思考】复杂问题的分析与决策</div>
                    <div className='vie-block-header-desc-bottom'>荣获CSTD“优质学习产品奖”</div>
                  </div>
                </div>
                <div className='vie-block-content-desc header-desc m-t26'>
                  <span className='dot'></span>
                  <div style={{fontWeight: 600}}>
                    <span>谋全局，聚合力加速个人和组织进化</span>
                    <br />
                    <div className='m-t10' style={{fontWeight: 400, fontSize: '14px'}}>
                      · 识别线性思维陷阱，提升大局观与全局意识 <br />
                      · 掌握系统思维范式，洞见事务本质，找到最佳决策 <br />
                      · 培养闭环工作思维，提升工作计划的完整性 <br />
                      · 培养积极主动意识，以当下小微改变赢得未来复利
                    </div>
                  </div>
                </div>
                <img src={Bg05} width={'393px'} height={'186px'} className='m-l55'/>
                <div className='vie-block-content-desc m-t4'>
                  <span className='dot'></span>
                  掌握以不变应万变的元能力，找出系统最优解决方案
                </div>
                <div className='vie-block-content-tips m-t12 m-l15'>
                  复杂决策 ｜ 战略复盘 ｜ 持续增长 ｜ 总结规划 ｜ 职业规划
                </div>
              </div>
            </TweenOne>
          </OverPack>
        </div>
      </div>
    </main>
  )
}

export default Theme