import React from 'react'
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack'
import QueueAnim from 'rc-queue-anim'
import {Col, Row} from 'antd'

const Duty: React.FC = ({item}) => {
  return (
    <div className='warp-join-detail-duty'>
      <OverPack playScale={0.2} component={Row} className='home-page'>
        <QueueAnim
          key="text"
          type="bottom"
          leaveReverse
          ease={['easeOutQuad', 'easeInQuad']}
          component={Col}
          md={20}
        >
          <div className='title m-t80' key='1'>工作职责</div>
          <div className='desc p-pre-wrap'  key='2'>
            {item?.duty}
          </div>
          <div className='title m-t70' key='3'>任职资格</div>
          <div className='desc p-pre-wrap' key='4'>
            {item?.status}
          </div>
        </QueueAnim>
      </OverPack>
    </div>
  )
}

export default Duty