import React, { useState } from 'react'
import { Row, Col } from 'antd'
import IconTeam from '@/assets/images/product/study/icon-team.png'
import IconWater from '@/assets/images/product/study/icon-water.png'
import IconArt from '@/assets/images/product/study/icon-art.png'
import IconTheme from '@/assets/images/product/study/icon-theme.png'
import IconExercise from '@/assets/images/product/study/icon-exercise.png'
import LeagueBg01 from '@/assets/images/product/study/league-bg01.png'
import LeagueBg02 from '@/assets/images/product/study/league-bg02.png'
import LeagueBg03 from '@/assets/images/product/study/league-bg03.png'
import LeagueBg04 from '@/assets/images/product/study/league-bg04.png'
import LeagueBg05 from '@/assets/images/product/study/league-bg05.png'
import LeagueBg06 from '@/assets/images/product/study/league-bg06.png'
import LeagueBg07 from '@/assets/images/product/study/league-bg07.png'
import LeagueBg08 from '@/assets/images/product/study/league-bg08.png'
import LeagueBg09 from '@/assets/images/product/study/league-bg09.png'
import LeagueBg10 from '@/assets/images/product/study/league-bg10.png'
import LeagueBg11 from '@/assets/images/product/study/league-bg11.png'
import LeagueBg12 from '@/assets/images/product/study/league-bg12.png'
import LeagueBg13 from '@/assets/images/product/study/league-bg13.png'
import LeagueBg14 from '@/assets/images/product/study/league-bg14.png'
import LeagueBg15 from '@/assets/images/product/study/league-bg15.png'
import LeagueBg16 from '@/assets/images/product/study/league-bg16.png'
import LeagueBg17 from '@/assets/images/product/study/league-bg17.png'
import LeagueBg18 from '@/assets/images/product/study/league-bg18.png'
import LeagueBg19 from '@/assets/images/product/study/league-bg19.png'
import LeagueBg20 from '@/assets/images/product/study/league-bg20.png'
import LeagueBg21 from '@/assets/images/product/study/league-bg21.png'
import LeagueBg22 from '@/assets/images/product/study/league-bg22.png'
import LeagueBg23 from '@/assets/images/product/study/league-bg23.png'
import LeagueBg24 from '@/assets/images/product/study/league-bg24.png'
import LeagueBg25 from '@/assets/images/product/study/league-bg25.png'
import LeagueBg26 from '@/assets/images/product/study/league-bg26.png'
import LeagueBg27 from '@/assets/images/product/study/league-bg27.png'


const LeagueList: React.FC = () => {

  const [data, setData] = useState([{
    title: '团队共创',
    desc: '课程产品体系',
    pic: IconTeam,
    contents: [{
      pic: LeagueBg01,
      title: '扬帆·起航',
      desc: [{
        name: '团建形式',
        content: '根据“船”的设计原理，利用大量专用管材，KT 版，瓦楞板等器材,皮尺，画笔颜料等工具，团队去讨论设计、分工协作并组装成一艘属于我们自己的“船”。'
      },{
        name: '团建目标',
        content: '迈克尔·柯维的著作《这是我们的船》和《扬帆起航》完美结合，帮助企业打造自信、强大的团队。一次造船的体验。可以发挥每个团队成员的主动性，遵循强大团队的八项法则，建造一个属于你自己的战无不胜的“船”。'
      }]
    },{
      pic: LeagueBg02,
      title: '炫动·多米诺',
      desc: [{
        name: '团建形式',
        content: '学员按照定稿图纸，一起堆出定制化的图案。在推倒第一张牌后,其余骨牌发生连锁反应，依次倒下，形成一幅新的图案，变化万千，十分酷炫。'
      },{
        name: '团建目标',
        content: '多米诺骨牌是一项能培养人创造能力、增强自信心、品位高雅的娱乐活动，不受时间地点限制，对开发参与者的智力、创造力和想象力训练参与者动手能力、思维能力都有好处，并且它能够培养参与者的意志，最大限度地发扬团队精神。'
      }]
    },{
      pic: LeagueBg03,
      title: '超级·过山车',
      desc: [{
        name: '团建形式',
        content: '学员利用现有的道具，亲手设计并制作出满载企业文化的过山车,将公司发展历程以可视化形式，融入到过山车的各个节点，这是最富想象力的团队协作活动。'
      },{
        name: '团建目标',
        content: '成员在设计专属于自己团队的过山车过程中，能激发出他们的创造性思维。全员参与，能有效凝聚团队力量，提升团队认同感，让企业文化深入人心。'
      }]
    },{
      pic: LeagueBg04,
      title: '向往的生活',
      desc: [{
        name: '团建形式',
        content: '暂别都市紧张的生活节奏，拒绝 24 小时待机候命。日出而作，日落而息，学员一起捕鱼捉鸡、挖菜种花、生火野炊、厨神争霸。这里能带你游在山水里，食在乡野中，找寻每个都市人心中最向往的纯真初心。'
      },{
        name: '团建目标',
        content: '亲近自然的厨艺大比拼!整个活动氛围和谐轻松，能很好地释放工作压力，增进团队协作和沟通，培养团队统筹协作的能力，养成吃苦耐劳，勇敢顽强的品质。'
      }]
    },{
      pic: LeagueBg05,
      title: 'F1赛车',
      desc: [{
        name: '团建形式',
        content: '学员们亲自动手设计并制作出一辆能载人的 F1 赛车，并开展一场精彩的赛车 PK，完美呈现一场充满趣味的企业赛车比赛，决出获胜车队，庆祝荣耀时刻!'
      },{
        name: '团建目标',
        content: '从赛车的设计，到共同制造，再把自己的赛车送上赛道，这是属于团队的完整体验和享受。不仅增强团队合作氛围，在完美呈现项目统筹的全过程，更让学员感受到密切合作和有效沟通的重要性。'
      }]
    },{
      pic: LeagueBg06,
      title: '泡泡龙',
      desc: [{
        name: '团建形式',
        content: '我们要用自己的双手，用五颜六色的气球制作出我们自己的心目中最完美且创意十足的泡泡龙，将自己的团队文化、企业文化的精髓融入设计理念，制作一条泡泡龙，并以此龙参加最后的舞龙比赛。'
      },{
        name: '团建目标',
        content: '通过亲手参与扎龙、绘龙、点睛、舞龙、化龙等整个过程，让舞龙活动变得新奇好玩，同时达到龙人合一，激扬士气。培养团队合作精神，提升企业凝聚力，增强员工对公司的忠诚度、归属感和荣誉感。'
      }]
    },{
      pic: LeagueBg27,
      title: '梦想家园',
      desc: [{
        name: '团建形式',
        content: '一张梦想图纸，一群激情伙伴，一堆原始物料，一座理想家园!学 员相互协作，动手设计并建造出心中理想的城堡，共同见证梦想家 园的诞生，感受彼此间的默契及情感的交织。'
      },{
        name: '团建目标',
        content: '学员亲历团队力量的迸发，在心间汇聚一股强大的企业荣誉感，增 强集体向心力，坚定创造和守卫更绚烂的家园的信念与决心。在未 来的职业生活中，增强集体凝聚的决心和信心。'
      }]
    }]
  },{
    title: '水上团建',
    desc: '课程产品体系',
    pic: IconWater,
    contents: [{
      pic: LeagueBg11,
      title: '皮划艇团建',
      desc: [{
        name: '团建形式',
        content: '船象征企业和团队，船员则是企业员工和团队成员。学员组建好自己的舰队，一起练习皮划艇技能，参与皮划艇竞技赛，赢取团队的胜利果实，庆祝荣耀时刻，共创美好未来。'
      },{
        name: '团建目标',
        content: '皮划艇运动是在水面上进行，因此，为了使自己的皮划艇前进，队员要尽全力去划奖，努力克服困难及恐惧。通过专业的团队皮划艇和精心设计的科目，使成员在活动中体会团队精神，自然而然地形成组织力量和团队协作。'
      }]
    },{
      pic: LeagueBg10,
      title: '龙舟·竞渡',
      desc: [{
        name: '团建形式',
        content: '百前争流，奋程者先!龙舟发源于中国，是中国民间传统水上体育娱乐项目，赛龙舟不仅是一种体育娱乐活动，更体现出我国传统的悠久历史文化继承性和集体主义精神。'
      },{
        name: '团建目标',
        content: '企业的成功，需要掌舵者和奖手的共同努力与合作，学员能体会到团队协作的魅力和征服目标的喜悦，达到共享、共进、共赢的企业愿景。'
      }]
    },{
      pic: LeagueBg09,
      title: '桨板团建',
      desc: [{
        name: '团建形式',
        content: '奖板运动源于美国夏威夷的奖板冲浪，近年早已成为了风靡全球的运动。该项运动使用冲浪板和奖在水面滑行冲浪，考验人的力量和协调性，体验团队竞争、体会团队合力。'
      },{
        name: '团建目标',
        content: '全程在水中进行活动，使参加人员在过程中体会紧张激烈的竞争环境，更有效的达成目标。使全体成员与浆板融为一体，让队员体会团队的力量大于个人的力量。'
      }]
    },{
      pic: LeagueBg08,
      title: '联合舰队',
      desc: [{
        name: '团建形式',
        content: '“联合舰队”是通过专业的团队皮筏艇和精心设计的科目，使所有参加活动的人员在过程中体会紧张激烈的竞争环境，如何与团队成员有效沟通、无间合作。'
      },{
        name: '团建目标',
        content: '感受如何站在他人的角度考虑问题。在大自然中体会、享受挑战与冒险而设计的，在训练过程中实现团队合作、逆境求生、克服困难、勇争胜利。'
      }]
    },{
      pic: LeagueBg07,
      title: '水上大闯关',
      desc: [{
        name: '团建形式',
        content: '目的演变体，让你成为节目中的主角，体验新的角色。水上游戏关卡重重，惊险刺激爆笑升级。“玩”字当头，快乐主宰，乐参与者之所乐。参与者与候场者构成一片快乐的海洋，成为一个全员娱乐的狂欢盛会。'
      },{
        name: '团建目标',
        content: '水上大冲关团建活动，把趣味、互动、娱乐的项目引入其中，调动每个参与者的积极性，不仅达到熔炼团队，增强企业凝聚力，鼓舞全员士气的目标，还能加深员工对企业新目标的了解和认同，展示公司及员工的风采，最大限度地提升活动的效果。'
      }]
    }]
  },{
    title: '艺术创意',
    desc: '课程产品体系',
    pic: IconArt,
    contents: [{
      pic: LeagueBg16,
      title: '油画大师',
      desc: [{
        name: '团建形式',
        content: '每位学员将有一块属于自己的空白画板，正如每个人都是团队中极其重要的一部分。在大家的分工和配合之下，将每一份画板作品,汇聚成一幅油田大作，共同展现出团队的蓝图愿景。'
      },{
        name: '团建目标',
        content: '体验零基础油画绘画。考验员工的动手协调能力、顾全大局、掌握整体效果的价值观，加强学员的交流互动，把艺术气息融入团队用画笔和颜料勾勒团队企业文化愿景，共同构建良好的文化氛围。'
      }]
    },{
      pic: LeagueBg15,
      title: '最佳乐团',
      desc: [{
        name: '团建形式',
        content: '我们将以专业的乐队为支撑，现场体验摇滚乐手，从乐器学习，组队排练，舞台演出的整个过程……重金属、摇滚、民谣、爵士等,乐队风格您来定!'
      },{
        name: '团建目标',
        content: '乐团，是一种理想的团队组织方式。通过有层次的分工体系、清晰的目标绩效考核，让学员充分了解、磨合，深刻感受到有共同目标的团队，可以产生非凡的能量和效果，从而完成团队进化。'
      }]
    },{
      pic: LeagueBg14,
      title: '鼓舞飞扬',
      desc: [{
        name: '团建形式',
        content: '《非洲鼓》是西非曼丁文化代表性乐器，区别于其他打击乐器，它有着不同寻常的音域和意境，呈现出一场激励人心又别致的视听盛宴。学员通过一场关于音乐的穿越之旅，体验非洲鼓带来的快乐。'
      },{
        name: '团建目标',
        content: '让参加训练的员工可以建立一个团队、一个声音、知行合一、合而开同的团队合作精神，升华以主人翁的心态推动和完成本职工作。呈现出一场激励人心又别致的视听盛宴。'
      }]
    },{
      pic: LeagueBg13,
      title: '杯子舞',
      desc: [{
        name: '团建形式',
        content: '室内项目杯子舞是一个非常赞的拓展团建项目，不仅考验每个小队的步调一致，还考验整个团队的分工协作，传统的 cups 是所有参与者均以 cups 演奏者的角色完成演奏，全新 cups 则融合企业元素的一条完整的体验线。'
      },{
        name: '团建目标',
        content: '全新 cups 杯子舞的完美搭配，更加考验团队的整体配合度及协调程度，随着美妙的音乐，学员们席地而坐，利用手中的杯子、通过集体的配合演奏出一段非常美妙的灵魂之乐。促进学员之间的默契值,构建良好的活动氛围。'
      }]
    },{
      pic: LeagueBg12,
      title: '心随所动',
      desc: [{
        name: '团建形式',
        content: '通过创意 FLASH 形式组出一幅幅图案，展现团队的成绩和文化，每个人都是不可或缺的一部分，手拉手共创美好明天。'
      },{
        name: '团建目标',
        content: '个人每次移动的一小步，团队在一起，就变成了不一样的图案，个人的每次努力，都是团队未来的展现，本活动的参与度及兴趣度都达到最高，团队创意无极限。超越个人局限，打破思维定势，完成看似不可能的奇迹展现。'
      }]
    }]
  },{
    title: '主题团建',
    desc: '课程产品体系',
    pic: IconTheme,
    contents: [{
      pic: LeagueBg19,
      title: '国宝密令',
      desc: [{
        name: '团建形式',
        content: '汉唐时期，波斯上供国宝，经此遭遇战乱，大批宝藏流落于民间，皇家即发密令，封赏寻回宝藏者。一时各路人马聚集于此，通过重重考验，寻回遗失的宝藏。团队之间的角逐战，各显神通，这场没有硝烟的战争究竟谁能坐拥宝藏，名垂千史?'
      },{
        name: '团建目标',
        content: '独具特色的古风夺宝情景设计，促进学员以积极态度面对问题、解决问题，激发出团队激情和能量，培养出色的绩效管理和应变能力。'
      }]
    },{
      pic: LeagueBg18,
      title: '团队真人吃鸡',
      desc: [{
        name: '团建形式',
        content: '深度还原 90% 手游，全球首创 GPS+ 北斗双重定位、惯性导航玩法打造移动互联网 + 线下实景炫战。玩家需通过手机在作战区内搜索并拾取各类装备资源，团队策略配合，击败敌军后可抢夺对方资源努力生存到最后。'
      },{
        name: '团建目标',
        content: '用真实体验，增进团队凝聚力，在瞬息万变中，增进沟通。通过团队配合，让资源最大化，在互助友爱中，团队得到放松减压。这是娱乐和培训兼得的新兴时尚运动，娱乐中放松，运动中创新。'
      }]
    },{
      pic: LeagueBg17,
      title: '最强战队',
      desc: [{
        name: '团建形式',
        content: '国内首款手机活动团建操作系统，结合移动互联网、AI 人工智能及GPS 定位模块。任务内容高度契合活动地点的人文地理、民俗风情等元素，团队成员以小组为单位，合作完成各项任务获得积分。'
      },{
        name: '团建目标',
        content: '以热播城市探索类综艺节目“极速前进”为活动开展形式，根据活动时长定制任务数量，利用团队智慧去分析、策划和行动，合作完成各项任务，尽可能多的获取分数。'
      }]
    }]
  },{
    title: '运动竞技',
    desc: '课程产品体系',
    pic: IconExercise,
    contents: [{
      pic: LeagueBg22,
      title: '国宝密令',
      desc: [{
        name: '团建形式',
        content: '旱地冰壶，是由冬奥冰壶项目优化而来。学员以团队为主，分别在平滑地面上进行投掷的竞赛项目。通过让本方冰壶球停在预先设定好的位置，或将对方冰壶球击中，达到致胜的目的。'
      },{
        name: '团建目标',
        content: '旱地冰壶这项新型绅士运动，可以主富团队建设，给企业员工带来时尚、健康的生活和快乐的工作，更进一步提高团队协作、策略共识及理解与接纳!'
      }]
    },{
      pic: LeagueBg21,
      title: '优棒团队',
      desc: [{
        name: '团建形式',
        content: '风靡欧美日韩等国家的棒球运动，是国际上公认的高智商运动。队员之间既强调个人才智发挥，又必须讲究战略战术相互配合，十分类似于企业团队的日常工作场景，让队员们在赛场上见证团队不一样的成长。'
      },{
        name: '团建目标',
        content: '棒球精神是创新、匠心、实干、搞笑。通过棒球运动，可以使学员进一步明确定位与责任、信任与支持，明白个人牺牲与集体保全的亚衡，在未来助力企业扬帆起航。'
      }]
    },{
      pic: LeagueBg20,
      title: '攻防·箭道',
      desc: [{
        name: '团建形式',
        content: '所有学员分为攻防两组，以特制弓箭为道具，配合盾牌等防护装置,在传统模式和经济模式下，手持弓箭射击敌方，双方需要射击更多的敌人，从而占领敌方营地，赢得胜利。'
      },{
        name: '团建目标',
        content: '这是由美国引入的全新竞技类活动，舒缓学员的工作压力，锻炼学员的领导统筹能力，打造一支优秀的攻坚队伍。'
      }]
    },{
      pic: LeagueBg23,
      title: '极限飞盘',
      desc: [{
        name: '团建形式',
        content: '很多人喜欢极限飞盘，它不仅是追求运动的竞技性，更多是因为“飞盘精神”一-相互尊重、相互信任。课程以飞盘运动为载体，真实模拟组织构成，通过角色分工，紧密合作，活力展现团队力量。'
      },{
        name: '团建目标',
        content: '飞盘运动是极好的团队训练项目，这项运动既体现出个人主义、集体主义，自由与平等的冲突，又强调了团队协同作战、内部沟通相互补位、关注目标的团队精神。'
      }]
    },{
      pic: LeagueBg24,
      title: '趣味运动会',
      desc: [{
        name: '团建形式',
        content: '通过多种游戏及任务，分组完成比赛。趣味运动会区别于传统运动会融入了多种欢乐有趣的运动竞技类拓展。是一项非常适合多人团队进行的拓展。'
      },{
        name: '团建目标',
        content: '将游戏融入比赛，锻炼体魄的同时也在活动过程中将娱乐精神发挥出来!竞技分组的形式让每一场游戏也充满了浓浓的“火药味”让员工在团建过程中放松压力，也更加激励大家对于团队精神，集体荣誉感的追求!'
      }]
    },{
      pic: LeagueBg25,
      title: '巨人捕手',
      desc: [{
        name: '团建形式',
        content: '整场活动，充满紧张刺激的竞速环节，在组装戈德堡机械装置以及完成 CrossFit 任务过程中，可以充分激发团队的竞争意识和团队潜能，学习得最有效的达成途径。'
      },{
        name: '团建目标',
        content: '一个高效的团队，必定是通过队员间相互信任，积极协作来完成目标任务。若没有紧密协同性，各自为战，必定在整个活动的前行过程中举步维艰!'
      }]
    },{
      pic: LeagueBg26,
      title: '龙腾四海',
      desc: [{
        name: '团建形式',
        content: '走进中国传统文化，用一种全新的方式，感受团队氛围，腾飞、勇敢智慧、坚韧和力量，将龙的精神无限延伸，使我们向更高的地方飞翔龙 一 象征着一种自强不息、开拓进取的精神，更有着“祈福吉祥之意。'
      },{
        name: '团建目标',
        content: '通过团队有效分工，在龙的制作过程中体验团队目标、沟通协作以及项目管理等，并通过画龙点睛、龙腾盛世以及群龙合符来振奋企业精神、提升团队士气，达成团队共识。'
      }]
    }]
  }])

  return (
    <div className='warp-league-list'>
      <div className='warp-league-list-bg flex-center'>
        为企业量身定制各种特色团建方案，通过各类形式的拓展活动，<br/>
        <div>
          <span className='l-strong'>帮助企业</span><span className='l-color'>增强员工凝聚力、焕发企业活力。</span>
        </div>
      </div>
      <div className='warp-league-list-content'>
        <div className='home-page'>
          {
            data.map((item, index) => (
              <div>
                <div className='wrap-header' key='header' style={{marginTop: `${ index ? '87px' : ''}`}}>
                  <img src={item.pic} alt=""/>
                  <div>
                    <div className='desc'>{item.desc}</div>
                    <div className='title'>{item.title}</div>
                  </div>
                </div>
                {
                  item.contents.map(c => (
                    <div className='wrap-content' key='content'>
                      <Row className='wrap-content-block'>
                        <Col style={{marginRight: '40px'}}>
                          <img src={c.pic} alt=""/>
                        </Col>
                        <Col md={15} className='wrap-content-block-right'>
                          <div className='content-title'>{c.title}</div>
                          {
                            c.desc.map(d => (
                              <div className='content-desc'>
                                <div className='content-desc-t'>{d.name}</div>
                                <div className='content-desc-c'>{d.content}</div>
                              </div>
                            ))
                          }
                        </Col>
                      </Row>
                    </div>
                  ))
                }
              </div>
            ))
          }
        </div>
      </div>
    </div>
  )
}

export default LeagueList