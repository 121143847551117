import React, { useState, useRef } from 'react'
import {Carousel} from 'antd'
import Cube from '@/assets/images/cube.png'
import Bg01 from '@/assets/images/bg01.png'
import Bg02 from '@/assets/images/bg02.png'
import Bg03 from '@/assets/images/bg03.png'
import BigArrows from '@/assets/images/big_arrows.png'
import BannerLeft from '@/assets/images/banner_left.png'
import { useNavigate } from 'react-router-dom'
import ModelRelation from "@/components/ModelRelation";

const Banner: React.FC = () => {
  const [num, setNum] = useState(0)
  const [leftNum, setLeftNum] = useState(0)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [data, setData] = useState([
    {
      title: '企业如何判断需要咨询还是培训',
      // subTitle: '战略层',
      img: Bg01,
      btnTitle: '立即诊断，价值倍增',
      btnSubTitle: '管理咨询 / 企业培训',
      path: 'Toast'
    },
    {
      title: '上接战略·下接效能·持续提升竞争力',
      // subTitle: '组织层',
      img: Bg02,
      btnTitle: '光合企学',
      btnSubTitle: '动态课程体系，多元学习场景',
      path: '/product/enterprise'
    },
    {
      title: '借力资本·放大势能·倍速增长新路径',
      // subTitle: '需要咨询还是培训？',
      img: Bg03,
      btnTitle: '演进1号位',
      btnSubTitle: '增长上市，训战结合',
      path: '/product/mark'
    }
  ])
  const carouselLeftRef = useRef(null)
  const carouselRightRef = useRef(null)
  const navigate = useNavigate()

  const goToRightSlide = (index: number) => {
    if (carouselRightRef.current) {
      setNum(index)
      carouselRightRef.current.goTo(index)
    }
  };
  const goToLeftSlide = (index: number) => {
    if (carouselLeftRef.current) {
      setLeftNum(index)
      carouselLeftRef.current.goTo(index)
    }
  };
  const onLeftChange = (currentSlide: number) => {
    setLeftNum(currentSlide)
  };
  const onRightChange = (currentSlide: number) => {
    setNum(currentSlide)
  };

  const handlePage = (path) => {
    if(!path) return
    if(path === 'Toast') {
      return handlePlay()
    }
    navigate(path)
  }

  const handlePlay = () => {
    setIsModalOpen(true)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  return (
    <div className='wrap-banner'>
      <div className='wrap-banner-left'>
        <img src={BannerLeft} alt=""/>
        <div className='wrap-banner-left-box'>
          <div className='circle-box'>
            <span className={`circle-box-not ${leftNum === 0 ? 'circle-box-active' : ''}`} onClick={() => goToLeftSlide(0)}></span>
            <span className={`circle-box-not ${leftNum === 1 ? 'circle-box-active' : ''}`} onClick={() => goToLeftSlide(1)}></span>
          </div>
          <Carousel
            ref={carouselLeftRef}
            dots={false}
            autoplay={true}
            afterChange={onLeftChange}
          >
            <>
              <div className='title'>打造中国式企业</div>
              <div className='title'>管理典范</div>
              <div className='block'>
                <div className='block-left'>ABOUT US</div>
                <div className='block-right' onClick={() => handlePage('/about')}>
                  <img src={BigArrows}/>
                </div>
              </div>
            </>
            <>
              <div className='title'>科学发展</div>
              <div className='title'>少走弯路</div>
              <div className='block'>
                <div className='block-left'>专业创造价值</div>
                <div className='block-right' onClick={() => handlePage('/scheme')}>
                  <img src={BigArrows}/>
                </div>
              </div>
            </>
          </Carousel>
        </div>
      </div>
      <div className='wrap-banner-right'>
        <img className='cube-img' src={Cube}/>
        <Carousel
          ref={carouselRightRef}
          dots={false}
          autoplay={true}
          afterChange={onRightChange}
        >
          {
            data.map((item, i) =>
              <div className='wrap-banner-block' >
                <img className='banner-bg' src={item.img} />
                <div className='title title-box' onClick={() => handlePage(item?.path)}>
                  <div >{item.title}</div>
                  <div>{item?.subTitle}</div>
                </div>
              </div>
            )
          }
        </Carousel>
        <div className='wrap-banner-footer'>
          <div className='banner-line'></div>
          <div className='banner-footer'>
            {
              data.map((item, i) => (
                <div className={`banner-footer-block ${num === i ? 'active' : ''}`} onClick={() => goToRightSlide(i)}>
                  <div className='num'>0{i + 1}</div>
                  <div className='box'>
                    <div className='box-top'>{item?.btnTitle}</div>
                    <div className='box-bottom'>{item?.btnSubTitle}</div>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </div>
      <ModelRelation isModalOpen={isModalOpen} handleCancel={handleCancel} />
    </div>
  )
}

export default Banner