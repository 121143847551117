import React, { useState } from 'react'
import { Row, Col } from 'antd'
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack'
import QueueAnim from 'rc-queue-anim'
import TweenOne from 'rc-tween-one'
import Trophy from '@/assets/images/about/trophy.png'
import down from '@/assets/images/about/down.png'

const Awards: React.FC = () => {
  const [isMore, setIsMore] = useState(false)
  const [data, setData] = useState([
    {
      title: '2024中国创新品牌100强',
      subTitle: '行业标志品牌',
      desc: '第七届年度经济大会',
    },
    {
      title: '最佳合作潜力奖',
      subTitle: '行业标志品牌',
      desc: '结构思考力研究中心',
    },
    {
      title: '最佳数字化人才赋能供应商',
      subTitle: '行业标志品牌',
      desc: 'CEFE',
    },
    {
      title: '2023年度企业学习',
      subTitle: '行业标志品牌',
      desc: '中国品牌博鳌峰会',
    },
    {
      title: '2022年度企业咨询',
      subTitle: '培训行业创新企业',
      desc: '亚洲经济大会',
    },
    {
      title: '中国最佳企业培训',
      subTitle: '服务机构',
      desc: '博鳌企业论坛',
    },
    {
      title: '2022年度最具',
      subTitle: '成长价值奖',
      desc: 'CFS第十一届财经峰会',
    },
    {
      title: '2022学习与发展',
      subTitle: '供应商价值大奖',
      desc: '智享会',
    },
    {
      title: '2021年度突破创新',
      subTitle: '人力资源服务机构',
      desc: '环球人力资源智库',
    },
    {
      title: '2021中国经济',
      subTitle: '十大创新企业',
      desc: '《环球日报》社',
    },
    {
      title: '全球优选培训产品',
      subTitle: '最佳实践奖',
      desc: '美国培训杂志',
    },
    {
      title: '赋能业务优质',
      subTitle: '学习产品奖',
      desc: 'CSTD',
    },
    {
      title: '2022年度整合营销',
      subTitle: '金案',
      desc: '中国国际广告节',
    }
  ])
  const imgAnim: any = false
    ? {
      y: 30,
      opacity: 0,
      delay: 600,
      type: 'from',
      ease: 'easeOutQuad',
    }
    : {
      x: 30,
      opacity: 0,
      type: 'from',
      ease: 'easeOutQuad',
    };
  const groups = [];
  let startIndex = 0;

  while (startIndex < data.length) {
    if (startIndex < 2 * 2) {
      groups.push(data.slice(startIndex, startIndex + 2));
    } else {
      groups.push(data.slice(startIndex, startIndex + 3));
    }
    startIndex += (startIndex < 2 * 2) ? 2 : 3;
  }

  const handleMore = () => {
    setIsMore(!isMore)
  }

  return (
    <div className='warp-about-awards home-page'>
      <div className='title'>我们的荣誉及奖项</div>
      <OverPack playScale={0.3}>
        <QueueAnim
          key='queue'
          leaveReverse
          type='bottom'
          delay={[0, 100]}>
          {groups.slice(0, 4).map((group, index) => (
            <QueueAnim
              type="bottom"
              component={Row}
              gutter={[30, 30]}
              key={`row-${index}`}
              style={{marginBottom: '18px'}}
            >
              {group.map((item, idx) => (
                (index < 3) ? <Col key={idx.toString()} md={8} className='shallow-bg'>
                    <div className='shallow-block'>
                      <div className='awards-title'>
                        <div>{item.title}</div>
                        <div>{item.subTitle}</div>
                      </div>
                      <div className='shallow-line'></div>
                      <div className='shallow-desc'>{item.desc}</div>
                    </div>
                  </Col> :
                  <Col key={idx.toString()} md={8} className='deep-bg'>
                    <div className='deep-block'>
                      <div className='awards-title'>
                        <div>{item.title}</div>
                        <div>{item.subTitle}</div>
                      </div>
                      <div className='deep-line'></div>
                      <div className='deep-desc'>{item.desc}</div>
                    </div>
                  </Col>
              ))}
            </QueueAnim>
          ))}
          {
            isMore ? <div key='residue' style={{maxHeight: '500px'}}>
              {groups.slice(4).map((group, index) => (
                <QueueAnim
                  type="bottom"
                  component={Row}
                  gutter={[30, 30]}
                  key={`row-${index}`}
                  style={{marginBottom: '18px'}}
                >
                  {group.map((item, idx) => (
                    <Col key={idx.toString()} md={8} className='deep-bg'>
                      <div className='deep-block'>
                        <div className='awards-title'>
                          <div>{item.title}</div>
                          <div>{item.subTitle}</div>
                        </div>
                        <div className='deep-line'></div>
                        <div className='deep-desc'>{item.desc}</div>
                      </div>
                    </Col>
                  ))}
                </QueueAnim>
              ))}
            </div> : <div key='empty'></div>
          }
          <TweenOne
            key="img"
            animation={imgAnim}
            resetStyle
            className='trophy-img'
          >
            <img width='100%' src={Trophy} alt="" />
          </TweenOne>
          <TweenOne
            key="box"
            animation={imgAnim}
            resetStyle
            className='down-box'
            onClick={handleMore}
          >
            <img className={`down-img ${isMore && 'up-img'}`} src={down} />
            <div>{ isMore ? '收起' : '查看更多'}</div>
          </TweenOne>
        </QueueAnim>
      </OverPack>
    </div>
  )
}

export default Awards