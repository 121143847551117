import React from 'react'

const Product: React.FC = () => {

  return (
    <main >
      <div className='warp-product header-top'>
        产品中心
      </div>
    </main>
  )
}

export default Product