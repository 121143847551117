import React from 'react'
import {Row, Col} from 'antd'
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack'
import QueueAnim from 'rc-queue-anim'
import Bg from '@/assets/images/serve/bg.png'
import IconOne from '@/assets/images/serve/icon-one.png'
import IconTwo from '@/assets/images/serve/icon-two.png'
import IconThree from '@/assets/images/serve/icon-three.png'
import IconInte from '@/assets/images/serve/icon-inte.png'
import IconCust from '@/assets/images/serve/icon-cust.png'
import IconPeriod from '@/assets/images/serve/icon-period.png'
import IconQuality from '@/assets/images/serve/icon-quality.png'

const Serve: React.FC = () => {

  return (
    <main className='warp-serve header-top'>
      <div className='warp-serve-header'>
        <div className='title'>熙诚教育投资<span>·专业交付品质保障</span></div>
        <img className='home-page' src={Bg} alt=""/>
      </div>
      <OverPack playScale={0.3}>
        <QueueAnim
          type="bottom"
          key="scheme"
          leaveReverse
          className='warp-serve-scheme home-page'
        >
          <div className='title' key='title'>培训业务<span>解决方案</span></div>
          <div className='desc' key='desc'>光合企学致力于企业人才培养服务，培训服务将围绕以下四个层面展开：</div>
          <div className='box' key='1'>
            <div className='box-tips flex-center'>培养理念与时俱进</div>
            <div className='box-text'>秉承国企优势，紧密结合国家政策，敏锐洞察力新商业环境下的能力新需求，助力企业全方位打造新时代奋进力量；</div>
          </div>
          <div className='box' key='2'>
            <div className='box-tips flex-center'>培训体系科学系统</div>
            <div className='box-text'>根据企业不同类型人才的发展阶段及重点，进行针对性发展计划设计，由0到1的陪伴人才成长；</div>
          </div>
          <div className='box' key='3'>
            <div className='box-tips flex-center'>培养方式层层递进</div>
            <div className='box-text'>锚定学员现实情况，发现能力差距，设定阶段性成长目标及发展方式，推进学员从理解到掌握再到使用，最终实现能力成长；</div>
          </div>
          <div className='box' key='4'>
            <div className='box-tips flex-center'>培养效果直观显著</div>
            <div className='box-text'>注重知识学习与技能实践的链接，将真实工作挑战融合进学习过程之中，在理论之外布置针对性课后实践任务与作业，并周期性进行复盘总结，推挤学员由知到达，让能力提升直接显现为业绩结果。</div>
          </div>

        </QueueAnim>
      </OverPack>
      <OverPack playScale={0.3} className='warp-serve-foster'>
        <QueueAnim
          type="bottom"
          key="foster"
          leaveReverse
          className='home-page'
        >
          <div className='title' key='title'>人才培养<span>四大突出优势</span></div>
          <QueueAnim
            type="bottom"
            key="blocks"
            leaveReverse
            component={Row}
            gutter={[13]}
            className='foster-blocks'
          >
            <Col md={6} key='1'>
              <div className='foster-block'>
                <img src={IconInte} alt=""/>
                <div className='foster-block-title'>集成化学习场景</div>
                <div className='foster-block-desc'>集成线上线下课程、工作坊、行动学习、研学等多元学习场景，助力培训效果落地。</div>
              </div>
            </Col>
            <Col md={6} key='2'>
              <div className='foster-block'>
                <img src={IconCust} alt=""/>
                <div className='foster-block-title'>定制化学习体系</div>
                <div className='foster-block-desc'>根据企业所处的不同发展阶段，从战略、组织、文化及人才等方面提供课程资源。</div>
              </div>
            </Col>
            <Col md={6} key='3'>
              <div className='foster-block'>
                <img src={IconPeriod} alt=""/>
                <div className='foster-block-title'>全周期服务模式</div>
                <div className='foster-block-desc'>上接战略，下接效能，全流程参与企业业务和管理，形成理论学习到绩效产出的服务闭环。</div>
              </div>
            </Col>
            <Col md={6} key='4'>
              <div className='foster-block'>
                <img src={IconQuality} alt=""/>
                <div className='foster-block-title'>高质量教研团队</div>
                <div className='foster-block-desc'>汇集顶尖的教研团队和国际化讲师团队，讲师均有深厚理论支撑及多年企业管理经验。</div>
              </div>
            </Col>
          </QueueAnim>
        </QueueAnim>
      </OverPack>
      <OverPack playScale={0.3}>
        <QueueAnim
          type="bottom"
          key="message"
          leaveReverse
          className='warp-serve-message home-page'
        >
          <div className='title' key='title'>项目管理 <span>· 全周期服务保障</span></div>
          <QueueAnim
            type="bottom"
            key="blocks"
            leaveReverse
            component={Row}
            gutter={[15]}
            className='message-blocks'
          >
            <Col md={8} key='1'>
              <div className='message-block'>
                <div className='message-block-up'>
                  <div>
                    <div className='up-title'>项目管控</div>
                    <div className='up-desc'>全方位项目管控倡导者</div>
                  </div>
                  <img width='107px' src={IconOne} alt=""/>
                </div>
                <div className='message-block-down'>
                  <div className='b-desc'>
                    <div className='dot'></div>
                    <div className='text'>
                      学习内容设计
                    </div>
                  </div>
                  <div className='b-desc'>
                    <div className='dot'></div>
                    <div className='text'>
                      运营活动设计
                    </div>
                  </div>
                  <div className='b-desc'>
                    <div className='dot'></div>
                    <div className='text'>
                      学习场域设计
                    </div>
                  </div>
                  <div className='b-desc'>
                    <div className='dot'></div>
                    <div className='text'>
                      品牌宣传设计
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={8} key='2'>
              <div className='message-block'>
                <div className='message-block-up'>
                  <div>
                    <div className='up-title'>项目管控</div>
                    <div className='up-desc'>全方位项目管控倡导者</div>
                  </div>
                  <img width='129px' src={IconTwo} alt=""/>
                </div>
                <div className='message-block-down'>
                  <div className='b-desc'>
                    <div className='dot'></div>
                    <div className='text'>
                      内容大咖协同
                    </div>
                  </div>
                  <div className='b-desc'>
                    <div className='dot'></div>
                    <div className='text'>
                      专家讲师授课
                    </div>
                  </div>
                  <div className='b-desc'>
                    <div className='dot'></div>
                    <div className='text'>
                      专业助教服务
                    </div>
                  </div>
                  <div className='b-desc'>
                    <div className='dot'></div>
                    <div className='text'>
                      教练专项辅导
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={8} key='3'>
              <div className='message-block'>
                <div className='message-block-up'>
                  <div>
                    <div className='up-title'>项目运营</div>
                    <div className='up-desc'>一体化运营服务实践者</div>
                  </div>
                  <img width='131px' src={IconThree} alt=""/>
                </div>
                <div className='message-block-down'>
                  <div className='b-desc'>
                    <div className='dot'></div>
                    <div className='text'>
                      学习全程托管
                    </div>
                  </div>
                  <div className='b-desc'>
                    <div className='dot'></div>
                    <div className='text'>
                      培训安排提醒
                    </div>
                  </div>
                  <div className='b-desc'>
                    <div className='dot'></div>
                    <div className='text'>
                      打造社群氛围
                    </div>
                  </div>
                  <div className='b-desc'>
                    <div className='dot'></div>
                    <div className='text'>
                      输出培训成果
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </QueueAnim>
        </QueueAnim>
      </OverPack>
    </main>
  )
}

export default Serve