import React, {useState} from 'react'
import { Col, Row } from 'antd'
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack'
import QueueAnim from 'rc-queue-anim'
import TweenOne from 'rc-tween-one'
import { getAnim } from '@/utils'
import HistoryBg from '@/assets/images/product/study/history-bg.png'
import Bg01 from '@/assets/images/product/study/bg01.png'
import Bg02 from '@/assets/images/product/study/bg02.png'
import Bg03 from '@/assets/images/product/study/bg03.png'
import Bg04 from '@/assets/images/product/study/bg04.png'
import Bg05 from '@/assets/images/product/study/bg05.png'
import Bg06 from '@/assets/images/product/study/bg06.png'

const StudyList: React.FC = () => {
  const [data, setData] = useState([
    {
      name: '红色研学·江西',
      title: '实事求是、敢闯新路弘扬井冈\n山精神',
      desc: '井冈山是中国革命的摇篮，我们要结合新的时代条件，坚定执着追理想、实事求是闯新路、让井冈山精神放出新的时代光芒。',
      url: Bg02
    },
    {
      name: '红色研学·陕西',
      title: '梦回延安\n光辉历程',
      desc: '延安精神是中国共产党人彻底的革命精神，作为一种道德情操和精神风貌，是贯穿于党的理论与思想中的精神气质。',
      url: Bg01
    },
    {
      name: '红色研学·安徽',
      title: '挺进大别山\n探索红军路',
      desc: '在实现全面建设小康社会奋斗目标征程中，我们应该继承和弘扬刘邓大军挺进大别山的革命精神。',
      url: Bg03
    },
    {
      name: '红色研学·贵州',
      title: '实事求是、务实担当遵义赤水\n精神',
      desc: '遵义会议是我党历史上一次具有伟大转折意义的重要会议，我们要运用好遵义会议历史经验，让遵义会议精神永放光芒。',
      url: Bg04
    },
    {
      name: '红色研学·河北',
      title: '求真务实、真抓实干弘扬西柏\n坡精神',
      desc: '西柏坡精神是我党的宝贵精神财富，本质是巩固加强共产党的执政地位，不断地把社会主义事业推向前进。',
      url: Bg05
    },
    {
      name: '红色研学·山西',
      title: '太行精神\n光耀千秋',
      desc: '太行精神是不怕牺牲、不畏艰险的革命英雄主义精神，是在极其艰苦的条件下展现的百折不挠、艰苦奋斗精神。',
      url: Bg06
    }
  ])

  const imgAnim: any = getAnim()

  return (
    <div className='warp-study-list'>
      <OverPack playScale={0.3} component={Row} className='warp-study-list-top home-page'>
        <QueueAnim
          key="text"
          type="left"
          leaveReverse
          ease={['easeOutQuad', 'easeInQuad']}
          component={Col}
          md={11}
        >
          <QueueAnim
            component="ul"
            key="ul"
            type="left"
            ease="easeOutQuad"
          >
            <div className='title'>传承红色基因·提升党建实效</div>
            <div className='desc'>
              红色基因是党的生命之魂、生存之根、发展之本，形成于20世纪以来中华民族伟大复兴的血与火的斗争中，贯穿了革命、建设、改革各个历史时代。传承红色基因是国有企业强根铸魂的根本，是保持国有企业本色的必然要求，是国有企业发展壮大的必由之路。
            </div>
          </QueueAnim>
        </QueueAnim>
        <TweenOne
          key="img"
          animation={imgAnim}
          resetStyle
          component={Col}
          md={10}
        >
          <img className='history-bg' src={HistoryBg}  width="100%" alt="" />
        </TweenOne>
      </OverPack>
      <OverPack playScale={0.3} className='warp-study-list-bottom'>
        <QueueAnim
          key="queue"
          leaveReverse
          type='bottom'
          delay={[0, 100]}
          component={Row}
          className='home-page'
        >
          {
            data.map((item, i) => (
              <Col key={i.toString()} md={8}>
                <div className='warp-study-list-block'>
                  <img src={item?.url} alt=""/>
                  <div className='list-name'>{item.name}</div>
                  <div className='list-title p-pre-wrap'>{item.title}</div>
                  <div className='list-desc'>{item.desc}</div>
                </div>
              </Col>
            ))
          }
        </QueueAnim>
      </OverPack>
    </div>
  )
}

export default StudyList