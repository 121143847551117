import React from 'react'
import Banner from './Banner'
import Work from './Work'
import Detail from './Detail'

const Join: React.FC = () => {

  return (
    <main className='warp-join header-top'>
      <Banner />
      <Work />
      <Detail />
    </main>
  )
}

export default Join