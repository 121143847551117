import React, {useState, useEffect} from "react"
import {Popover} from 'antd'
import {handleScrollToTop, handleOpenPage} from '@/utils'
import ConcernedIcon from '@/assets/images/concerned-icon.gif'
import PhoneIcon from '@/assets/images/phone-icon.png'
import ReferIcon from '@/assets/images/refer-icon.png'
import ReferGif from '@/assets/images/refer-icon.gif'
import TopIcon from '@/assets/images/top-icon.png'
import ModelRelation from "@/components/ModelRelation";

const content = () => {
  return (
    <div style={{display: "flex", justifyContent: 'center'}}>
      <img style={{width: '145px', height: '145px',}}
           src='https://lecturermap-1319224539.cos.ap-beijing.myqcloud.com/cdn/%E5%AE%98%E7%BD%91/code_ghqx.jpg'></img>
    </div>
  )
}

const Sidebar: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isButtonVisible, setIsButtonVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const screenHeight = window.innerHeight;
      if (window.scrollY > screenHeight) {
        setIsButtonVisible(true);
      } else {
        setIsButtonVisible(false);
      }
    };
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handlePlay = () => {
    setIsModalOpen(true)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const IconTop = [isButtonVisible ? 'fade-in' : 'fade-out'].join(' ');

  return (
    <div className='sidebar-wrap'>
      <img src={ReferGif}  onClick={handlePlay} />
      <Popover placement="left"
               content={<div>立即咨询</div>}
      >
        <img src={ReferIcon} onClick={handleOpenPage}/>
      </Popover>

      <Popover placement="left"
               title={<span className='popover_title'>热线电话</span>}
               content={<div className='popover_content'>4006996160</div>}>
        <img src={PhoneIcon}/>
      </Popover>
      <Popover placement="left"
               title={<div className='popover_title' style={{textAlign: 'center'}}>关注我们</div>}
               content={content}>
        <img src={ConcernedIcon}/>
      </Popover>
      <img className={IconTop} src={TopIcon} onClick={handleScrollToTop} />
      <ModelRelation isModalOpen={isModalOpen} handleCancel={handleCancel} />
    </div>
  )
}

export default Sidebar