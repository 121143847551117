import React from 'react'
import {Col, Row} from 'antd'
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack'
import QueueAnim from 'rc-queue-anim'
import TweenOne from 'rc-tween-one'
import {getAnim} from '@/utils'
import ApplyCode from '@/assets/images/product/apply-code.png'


const Invite = () => {

  const imgAnim: any = getAnim()

  return (
    <div className='wrap-invite home-page'>
      <OverPack playScale={0.3} component={Row} className='wrap-invite-content'>
        <QueueAnim
          key="text"
          type="left"
          leaveReverse
          ease={['easeOutQuad', 'easeInQuad']}
          component={Col}
          md={17}
        >
          <QueueAnim
            key="ul"
            type="left"
            ease="easeOutQuad"
          >
            <div className='title m-t'>
              诚邀对象
            </div>
            <div className='desc'>
              ◎ 公司年营收5000万以上 <br />
              ◎ 5年内有上市规划的企业实际控制人或实际控制人（公司创始人、董事长、CEO）
            </div>
          </QueueAnim>
        </QueueAnim>
        <TweenOne
          key="img"
          animation={imgAnim}
          resetStyle
          component={Col}
          md={7}
          className='wrap-invite-code'
        >
          <img src={ApplyCode} alt=""/>
          <div className='wrap-invite-code-text'>扫码报名参与</div>
        </TweenOne>
      </OverPack>
    </div>
  )
}

export default Invite