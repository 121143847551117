import React, { useState } from 'react'
import Place from './Place'
import Banner from '@/assets/images/about/banner-contact.png'

const Contact: React.FC = () => {
  const [data, setData] = useState([{
    id: 'container1',
    title: '熙诚教育投资在北京',
    address: '北京市丰台区丽泽SOHO南塔25层',
    center: [116.33,39.87]
  },{
    id: 'container2',
    title: '熙诚教育投资在上海',
    address: '上海市虹口区东大名路501号白玉兰广场9层B03',
    center: [121.50,31.25]
  },{
    id: 'container3',
    title: '熙诚教育投资在成都',
    address: '四川省成都市武侯区安格斯国际中心2406',
    center: [104.06,30.60]
  }])

  return (
    <main className='warp-contact header-top'>
      <div className='title home-page'>
        欢迎加入熙诚教育投资
      </div>
      <img className='banner-img' src={Banner} alt=""/>
      <Place data={data[0]} />
      <Place data={data[1]} />
      <Place data={data[2]} />
    </main>
  )
}

export default Contact