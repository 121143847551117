import React from 'react'
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack'
import QueueAnim from 'rc-queue-anim'
import TweenOne from 'rc-tween-one'
import {Col, Row} from "antd";

const Work: React.FC = () => {
  const imgAnim: any = false
    ? {
      y: 30,
      opacity: 0,
      delay: 600,
      type: 'from',
      ease: 'easeOutQuad',
    }
    : {
      x: 30,
      opacity: 0,
      type: 'from',
      ease: 'easeOutQuad',
    };

  return (
    <div className='warp-join-work home-page'>
      <div className='title'>在熙城教育投资工作</div>
      <OverPack playScale={0.2} component={Row} style={{display: 'flex', justifyContent: 'space-between'}}>
        <QueueAnim
          key="text"
          type="left"
          leaveReverse
          ease={['easeOutQuad', 'easeInQuad']}
          component={Col}
          md={11}
        >
          <QueueAnim
            component="ul"
            key="ul"
            type="left"
            ease="easeOutQuad"
          >
            <div className='desc'>
              熙诚教育投资成立于2017年，是金融街资本旗下国有全资公司。作为中国咨询培训行业国企先锋，我们聚焦于企业管理咨询和企业学习，将先进理论与企业管理实践相结合，帮助企业形成清晰的发展战略、找到战略落地路径、提高战略落地效率，不断提升组织效能。
            </div>
          </QueueAnim>
        </QueueAnim>
        <TweenOne
          key="message"
          animation={imgAnim}
          resetStyle
          component={Col}
          md={10}
        >
          <Row>
            <Col className='message-block'>
              <div className='message-block-title'>
                可应聘职位
              </div>
              <div className='message-block-desc'>
                5
              </div>
            </Col>
            <Col className='message-block'>
              <div className='message-block-title'>
                公司地点
              </div>
              <div className='message-block-desc'>
                北京、上海、成都、深圳
              </div>
            </Col>
          </Row>
          {/*<Row>*/}
          {/*  <Col className='message-block'>*/}
          {/*    <div className='message-block-title'>*/}
          {/*      可应聘职位*/}
          {/*    </div>*/}
          {/*    <div className='message-block-desc'>*/}
          {/*      200-300*/}
          {/*    </div>*/}
          {/*  </Col>*/}
          {/*  <Col className='message-block'>*/}
          {/*    <div className='message-block-title'>*/}
          {/*      其他指标（需要看下有什么数据）*/}
          {/*    </div>*/}
          {/*    <div className='message-block-desc'>*/}
          {/*      XXXX*/}
          {/*    </div>*/}
          {/*  </Col>*/}
          {/*</Row>*/}
        </TweenOne>
      </OverPack>
    </div>
  )
}

export default Work