import React, {useState} from 'react'
import { Row, Col, Dropdown } from 'antd'
import { useNavigate } from 'react-router-dom'

const SchemeModel: React.FC = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false)
  const navigate = useNavigate();
  const onOpenChange = (open) => {
    setIsOpen(open)
  }

  const handlePage = (path) => {
    navigate(path)
    setIsOpen(false)
  }
  return (
    <Dropdown
      placement="bottomLeft"
      open={isOpen}
      onOpenChange={onOpenChange}
      dropdownRender={() =>
        <div className='__dropdown-nav dropdown_pd'>
          <Row className='nav-bar' gutter={1}>
            <Col md={24} className='nav-col'>
              <div className='nav-list'>
                <div className={`nav-list-block ${location.pathname === '/scheme' ? 'active' : ''}`} onClick={() => handlePage('/scheme')}>行业解决方案</div>
                <div className={`nav-list-block ${location.pathname === '/scheme/theme' ? 'active' : ''}`} onClick={() => handlePage('/scheme/theme')}>主题解决方案</div>
              </div>
            </Col>
          </Row>
        </div>
      }
    >
      {children}
    </Dropdown>
  )
}

export default SchemeModel