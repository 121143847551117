import React, {useState} from 'react'
import {Row, Col} from 'antd'
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack'
import QueueAnim from 'rc-queue-anim'
import RateIcon from '@/assets/images/rate-icon.png'
import ContendIcon from '@/assets/images/contend-icon.png'
import TensionIcon from '@/assets/images/tension-icon.png'
import AppearIcon from '@/assets/images/appear-icon.png'
import StockBg from '@/assets/images/about/stock-bg.png'
import SchoolBg from '@/assets/images/about/school-bg.png'
import CoolBg from '@/assets/images/about/cool-bg.png'

const Structure: React.FC = () => {
  const [data, setData] = useState([
    {
      name: '政府及协会资源',
      desc: '与多地政府和行业协会等\n' +
        '保持深度合作和良好的关系\n' +
        '重点国家级行业协会\n' +
        '长期保持深度合作与交流',
      pic: RateIcon
    },
    {
      name: '专家资源',
      desc: '政府机构\n' +
        '大型协会\n' +
        '全国著名高校\n' +
        '全球咨询机构\n' +
        '各大研究说和研究机构',
      pic: ContendIcon
    },
    {
      name: '资本资源',
      desc: '北交所、金融街资本、金长川资本\n' +
        '中信银行、北京银行、汇添富\n' +
        '新犁资本、水木资本、园丰资本\n' +
        '鼎翔资本\n' +
        '……',
      pic: TensionIcon
    },
    {
      name: '标杆企业',
      desc: '华为、腾讯、字节跳动\n' +
        '京东、阿里、小米\n' +
        '……',
      pic: AppearIcon
    },
  ])
  return (
    <div className='warp-about-structure'>
      <div className='home-page'>
        <div className='title'>熙诚教育投资生态建设</div>
        <div className='desc'>资源赋能｜打通产、学、研、用、融 多维资源矩阵</div>
        <OverPack playScale={0.2} >
          <QueueAnim
            type="bottom"
            key="block"
            leaveReverse
            component={Row}
            className='warp-about-structure-items'
          >
            {
              data.map((item, i) => (
                <Col md={6} key={i}>
                  <img src={item.pic} alt=""/>
                  <div className='title-img'>{item.name}</div>
                  <div className='desc-img p-pre-wrap'>{item.desc}</div>
                </Col>
              ))
            }
            <div className='line-items' key='line'></div>
          </QueueAnim>
        </OverPack>
        <OverPack playScale={0.2} >
          <QueueAnim
            type="bottom"
            key="block"
            leaveReverse
            component={Row}
            className='warp-about-structure-blocks'
            gutter={[16, 16]}
          >
            <Col md={8} key='block-1' >
              <div className='structure-block'>
                <img className='stock-img' src={StockBg} alt=""/>
                <div className='stock-desc'>
                  助力北交所打造服务中小企业主阵地 <br />
                  赋能中小企业全面实现价值增长
                </div>
              </div>
            </Col>
            <Col md={8} key='block-2'>
              <div className='structure-block'>
                <img className='school-img' src={SchoolBg} alt=""/>
                <div className='stock-desc'>
                  全球视野和科创精神 <br />
                  链接产业、科研、资本，助力企业升级
                </div>
              </div>
            </Col>
            <Col md={8} key='block-3' >
              <div className='structure-block'>
                <img className='cool-img' src={CoolBg} alt=""/>
                <div className='stock-desc'>
                  打造新创业价值生态 <br />
                  助力首都打造高水平人才高地
                </div>
              </div>
            </Col>
          </QueueAnim>
        </OverPack>
      </div>
    </div>
  )
}

export default Structure