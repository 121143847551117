import React, {useEffect} from 'react'
import {NavLink, useLocation, useNavigate} from 'react-router-dom'
import NavbarModel from '@/components/NavbarModel'
import SchemeModel from '@/components/NavbarModel/Scheme'
import { handleOpenPage } from '@/utils'
import Logo from '@/assets/images/logo.png'
import Refer from '@/assets/images/refer.gif'


function useScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
}

const Header: React.FC = () => {
  const navigate = useNavigate()
  useScrollToTop()

  const handlePage =() => {
    navigate('/')
  }
  return (
    <header className="header">
      <div className='logo'>
        <img src={Logo} alt="" onClick={handlePage} />
      </div>
      <div className='header-right'>
        <nav>
          <ul>
            <li>
              <NavLink to="/">首页</NavLink>
            </li>
            <NavbarModel>
              <li>
                <a className={location.pathname.includes('/product') ? 'active' : ''}>产品中心</a>
              </li>
            </NavbarModel>
            <SchemeModel>
              <li>
                <a className={location.pathname.includes('/scheme') ? 'active' : ''}>解决方案</a>
                {/*<NavLink to="/scheme">解决方案</NavLink>*/}
              </li>
            </SchemeModel>
            <li>
              <NavLink to="/case" >客户案例</NavLink>
            </li>
            <li>
              <NavLink to="/serve">服务支持</NavLink>
            </li>
            <li>
              <NavLink to="/about">关于我们</NavLink>
            </li>
          </ul>
        </nav>
        <div className='online-refer' onClick={handleOpenPage}>
          <div className='refer'>
            <img src={Refer} alt=""/>
          </div>
          <span>在线咨询</span>
        </div>
      </div>
    </header>
  )
}

export default Header
