import React from 'react'
import {Routes, Route, BrowserRouter as Router, Navigate} from 'react-router-dom'
import {Spin, ConfigProvider} from 'antd'
import zhCN from 'antd/es/locale/zh_CN'
import TransitionComponent from '@/components/Transition'
import { FooterDataSource} from './data.source'

import Header from './components/Header'
import Footer from './components/Footer'
import Sidebar from './components/Sidebar'
import Home from './pages/Home'
import About from './pages/About'
import Join from './pages/About/Join'
import Contact from './pages/About/Contact'
import JoinDetail from './pages/About/JoinDetail'
import News from './pages/About/News'
import NewsDetail from './pages/About/NewsDetail'
import Product from './pages/Product'
import Zxkc from './pages/Product/Zxkc'
import Xygpx from './pages/Product/Xygpx'
import Xspx from './pages/Product/Xspx'
import Szhzx from './pages/Product/Szhzx'
import Nhhy from './pages/Product/Nhhy'
import Gkk from './pages/Product/Gkk'

import Mark from './pages/Product/OneMark'
import Wealth from './pages/Product/Wealth'
import Study from './pages/Product/Study'
import Consult from './pages/Product/Consult'
import Enterprise from './pages/Product/Enterprise'
import Organize from './pages/Product/Organize'
import Tactic from './pages/Product/Tactic'
import Talents from './pages/Product/Talents'
import Visit from './pages/Product/Visit'
import Scheme from './pages/Scheme'
import Theme from './pages/Scheme/Theme'
import Case from './pages/Case'
import Serve from './pages/Serve'
import './index.less'

const App: React.FC = () => {
  return (
    <Router>
      <ConfigProvider
        locale={zhCN}
        theme={{
          token: {
            colorPrimary: '#C30D23'
          },
        }}
      >
        <React.Suspense fallback={<div className='s-loading-box'><Spin className='loading-span flex-center'/></div>}>
          <Header />
          <Routes location={location}>
            <Route index path="/" element={
              <TransitionComponent>
                <Home />
              </TransitionComponent>
            } />
            <Route exact path="/product" element={
              <TransitionComponent>
                <Product />
              </TransitionComponent>
            } />
            {/*自定义产品-开始*/}
            <Route exact path="/product/zxkc" element={
              <TransitionComponent>
                <Zxkc />
              </TransitionComponent>
            } />
            <Route exact path="/product/xygpx" element={
              <TransitionComponent>
                <Xygpx />
              </TransitionComponent>
            } />
            <Route exact path="/product/xspx" element={
              <TransitionComponent>
                <Xspx />
              </TransitionComponent>
            } />
            <Route exact path="/product/szhzx" element={
              <TransitionComponent>
                <Szhzx />
              </TransitionComponent>
            } />
            <Route exact path="/product/nhhy" element={
              <TransitionComponent>
                <Nhhy />
              </TransitionComponent>
            } />
            <Route exact path="/product/gkk" element={
              <TransitionComponent>
                <Gkk />
              </TransitionComponent>
            } />
            {/*自定义产品-结束*/}
            <Route exact path="/product/mark" element={
              <TransitionComponent>
                <Mark />
              </TransitionComponent>
            } />
            <Route exact path="/product/study/:id?" element={
              <TransitionComponent>
                <Study />
              </TransitionComponent>
            } />
            <Route exact path="/product/wealth" element={
              <TransitionComponent>
                <Wealth />
              </TransitionComponent>
            } />
            <Route exact path="/product/consult" element={
              <TransitionComponent>
                <Consult />
              </TransitionComponent>
            } />
            <Route exact path="/product/enterprise" element={
              <TransitionComponent>
                <Enterprise />
              </TransitionComponent>
            } />
            <Route exact path="/product/organize" element={
              <TransitionComponent>
                <Organize />
              </TransitionComponent>
            } />
            <Route exact path="/product/tactic" element={
              <TransitionComponent>
                <Tactic />
              </TransitionComponent>
            } />
            <Route exact path="/product/talents" element={
              <TransitionComponent>
                <Talents />
              </TransitionComponent>
            } />
            <Route exact path="/product/visit" element={
              <TransitionComponent>
                <Visit />
              </TransitionComponent>
            } />
            <Route exact path="/scheme" element={
              <TransitionComponent>
                <Scheme />
              </TransitionComponent>
            } />
            <Route exact path="/scheme/theme" element={
              <TransitionComponent>
                <Theme />
              </TransitionComponent>
            } />
            <Route exact path="/case/:id?" element={
              <TransitionComponent>
                <Case />
              </TransitionComponent>
            } />
            <Route exact path="/serve" element={
              <TransitionComponent>
                <Serve />
              </TransitionComponent>
            } />
            <Route exact path="/about" element={
              <TransitionComponent>
                <About />
              </TransitionComponent>
            } />
            <Route exact path="/about/contact" element={
              <TransitionComponent>
                <Contact />
              </TransitionComponent>
            } />
            <Route exact path="/about/join" element={
              <TransitionComponent>
                <Join />
              </TransitionComponent>
            } />
            <Route exact path="/about/joinDetail/:id?" element={
              <TransitionComponent>
                <JoinDetail />
              </TransitionComponent>
            } />
            <Route exact path="/about/news" element={
              <TransitionComponent>
                <News />
              </TransitionComponent>
            } />
            <Route exact path="/about/newsDetail/:source?/:id?" element={
              <TransitionComponent>
                <NewsDetail />
              </TransitionComponent>
            } />
            <Route path="*" element={<Navigate to="/" />}></Route>
          </Routes>
          <Sidebar />
          <Footer dataSource={FooterDataSource}/>
        </React.Suspense>
      </ConfigProvider>
    </Router>
  )
}

export default App
