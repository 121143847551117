import React from 'react'
import {Col} from 'antd'
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack'
import QueueAnim from 'rc-queue-anim'

const CaseLeft = (props) => {
  const item = props.item
  return (
    <Col className='case-left'>
      <div className='title'>{item?.name}</div>
      <div className='desc'>{item?.desc}</div>
      <img className='banner1' src={item.banner1} alt=""/>
      <OverPack playScale={0.2}>
        <QueueAnim
          type="bottom"
          key="block"
          leaveReverse
          className='case-left-block'
        >
          <div className='title-block' key='title-block'>
            {item.recommend.title}
          </div>
          <div className='content-bg' key='content-bg'>
            <div className='img-bg'>
              {item.recommend.tips}
            </div>
            {item.recommend.desc}
          </div>
        </QueueAnim>
      </OverPack>

      <OverPack playScale={0.3}>
        <QueueAnim
          type="bottom"
          key="block"
          leaveReverse
          className='case-left-block'
        >
          <div className='title-block' key='title-block'>
            {item.backdrop.title}
          </div>
          <div className='content' key='content'>
            {item.backdrop.desc}
          </div>
        </QueueAnim>
      </OverPack>

      <OverPack playScale={0.3}>
        <QueueAnim
          type="bottom"
          key="block"
          leaveReverse
          className='case-left-block target-block m-r86'
        >
          <div className='title-block' key='title-block'>
            {item.target.title}
          </div>
          {
            item?.pattern?.desc &&
            <div className='content p-pre-wrap' key='content'>
              {item.pattern.desc}
            </div>
          }
          {
            item?.target?.descents && <div className='content-block' key='content-block'>
              {
                item.target.descents.map(t => <>
                  <div className='target-title flex-center'>{t.name}</div>
                  <div className='target-desc'>{t.desc}</div>
                </>)
              }
            </div>
          }
        </QueueAnim>
      </OverPack>

      <OverPack playScale={0.3}>
        <QueueAnim
          type="bottom"
          key="block"
          leaveReverse
          className='case-left-block project-block'
        >
          <div className='title-block' key='title-block' >
            {item.pattern.title}
          </div>
          <div className='content p-pre-wrap' key='content'>
            {item.pattern.desc}
          </div>
          <img className='banner02' src={item.pattern.pic} key='img'/>
        </QueueAnim>
      </OverPack>

      <OverPack playScale={0.3}>
        <QueueAnim
          type="bottom"
          key="block"
          leaveReverse
          className='case-left-block assess-block'
        >
          <div className='title-block' key='title-block'>
            {item.achieve.title}
          </div>
          <div className='content-assess' key='content-assess'>
            {
              item?.achieve?.descents && item.achieve.descents.map((d, index) => <>
                {d.name && <div className={`assess-title ${index ? 'm-t40' : ''}`}>{d.name}</div>}
                <div className='assess-desc' style={{marginTop: `${d.name ? '20px' : '0'}`}}>
                  {
                    d.desc.map(i => (
                      <div className='assess-desc-block'>
                        <div className='dot'></div>
                        <p className='text p-pre-wrap'>{i}</p>
                      </div>
                    ))
                  }
                </div>
              </>)
            }
          </div>
          <img className='banner03' src={item.achieve.pic} key='img'/>
          {/*{item?.achieve?.pic2 && <img className='banner03' src={item.achieve.pic2} key='img2' />}*/}
        </QueueAnim>
      </OverPack>
    </Col>
  )
}

export default CaseLeft