import React from 'react'
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack'
import QueueAnim from 'rc-queue-anim'
import {Col, Row, Popover} from 'antd'
import TweenOne from "rc-tween-one"
import MailArrows from '@/assets/images/mail_arrows.png'

const Contacts: React.FC = () => {
  const imgAnim: any = false
    ? {
      y: 30,
      opacity: 0,
      delay: 600,
      type: 'from',
      ease: 'easeOutQuad',
    }
    : {
      x: 30,
      opacity: 0,
      type: 'from',
      ease: 'easeOutQuad',
    };
  return (
    <div className='warp-join-detail-contacts'>
      <OverPack playScale={0.2} component={Row} style={{display: 'flex', justifyContent: 'space-between'}} className='home-page'>
        <QueueAnim
          key="text"
          type="left"
          leaveReverse
          ease={['easeOutQuad', 'easeInQuad']}
          component={Col}
          md={11}
        >
          <QueueAnim
            component="ul"
            key="ul"
            type="left"
            ease="easeOutQuad"
          >
            <div className='title m-t80'>
              HR联系人 <br />
              金老师
            </div>
          </QueueAnim>
        </QueueAnim>
        <TweenOne
          key="message"
          animation={imgAnim}
          resetStyle
          component={Col}
          md={10}
        >
          <div>
            <div className='email-text m-t80'>
              您还可以直接发送简历至公司邮箱， <br />
              我们将在收到简历后，第一时间与您取得联系～
            </div>
            <Popover placement="top"
                     title={<div>jinbozhao@xichengedu.com</div>}
            >
              <a href="mailto:jinbozhao@xichengedu.com" className='email-btn flex-center'>
                发送邮件
                <img src={MailArrows} alt=""/>
              </a>
            </Popover>
          </div>
        </TweenOne>
      </OverPack>
    </div>
  )
}

export default Contacts