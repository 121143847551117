import React, {useState} from 'react'
import { Transition } from 'react-transition-group'
import UnIcon from '@/assets/images/un-icon.png'
import UpIcon from '@/assets/images/up-icon.png'
import './index.less'

const ExpandCollapse = ({ children }) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const toggleExpand = () => {
    setIsExpanded(!isExpanded)
  }

  const onEnter = (el) => {
    el.style.transition = '0.3s max-height ease'
    el.style.maxHeight = '0px'
    el.style.overflow = 'hidden'
  };

  const onEntering = (el) => {
    el.style.maxHeight = el.scrollHeight + 'px'
  };

  const onEntered = (el) => {
    el.style.transition = ''
    el.style.maxHeight = ''
  };

  const onExit = (el) => {
    el.style.overflow = 'hidden'
    el.style.maxHeight = el.scrollHeight + 'px'
  };

  const onExiting = (el) => {
    if (el.scrollHeight !== 0) {
      el.style.transition = '0.3s max-height ease'
      el.style.maxHeight = '0px'
    }
  };

  const onExited = (el) => {
    el.style.transition = ''
    el.style.maxHeight = ''
  };

  return (
    <div className='wrap-expand' key='content'>
      <Transition in={isExpanded}
                  timeout={300}
                  unmountOnExit
                  onEnter={onEnter}
                  onEntering={onEntering}
                  onEntered={onEntered}
                  onExit={onExit}
                  onExiting={onExiting}
                  onExited={onExited}
      >
        <div>
          {children}
        </div>
      </Transition>
      <div className='expand-toggle' onClick={toggleExpand} >
        {isExpanded ? <img src={UpIcon} alt=""/> : <img src={UnIcon} alt=""/>}
      </div>
    </div>
  )
}

export default ExpandCollapse