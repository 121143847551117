import React from 'react'
import { Row, Col } from 'antd'
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack'
import QueueAnim from 'rc-queue-anim'
import IconTrade from '@/assets/images/scheme/icon-trade.png'
import DataBgLeft from '@/assets/images/scheme/data-bg-left.png'
import DataBgRight from '@/assets/images/scheme/data-bg-right.png'
import Bg01 from '@/assets/images/scheme/bg01.png'
import Bg02 from '@/assets/images/scheme/bg02.png'
import Bg03 from '@/assets/images/scheme/bg03.png'
import Bg04 from '@/assets/images/scheme/bg04.png'
import Bg05 from '@/assets/images/scheme/bg05.png'
import Bg06 from '@/assets/images/scheme/bg06.png'
import Bg07 from '@/assets/images/scheme/bg07.png'
import Bg08 from '@/assets/images/scheme/bg08.png'
import Bg09 from '@/assets/images/scheme/bg09.png'

const BankList = () => {
  return (
    <div className='wrap-bank-list'>
      <div className='wrap-bank-list-header'>
        <div className='wrap-bank-list-header-box home-page'>
          <img src={IconTrade} alt=""/>
          <div className='wrap-title'>行业挑战与解决方案</div>
        </div>
      </div>
      <div className='wrap-bank-list-summary'>
        <div className='home-page'>
          <>
            <div className='title'>
              助力宏观经济增长，服务实体经济和国家战略 <br/>
              <span>银行“负重”前行</span>
            </div>
            <div className='desc'>
              2022年以来，银行业金融机构坚决贯彻落实党中央各项重大决策部署，深刻理解并全面践行金融工作的政治性、人民性，积 极扶持实体经济，不断优化信贷投放结构，资产总额保持较快增长，资产质量总体保持稳定。并将持续“优资产、控负债、提 中收、防风险、补资本”，以更稳健的力度和节奏，支持经济修复和提质增效。
            </div>
            <div className='summary-data'>
              <div className='summary-data-block'>
                <img src={DataBgLeft} alt=""/>
                <div  className='summary-data-block-text'>
                  2023年上半年，我国商业银行资产保持较快增长，截至6月末资产合计230.63万亿元，较上年末增加18.27万亿元，增幅平均值为6.96%
                </div>
              </div>
              <div className='summary-data-block'>
                <img src={DataBgRight} alt=""/>
                <div className='summary-data-block-text' style={{width: '500px'}}>
                  2023年上半年，为支持实体经济高质量发展，各家银行信贷供给保持持续增长，贷款增速平均值为6.39%;对公贷款增量占比显著增加，体现银行责任担当
                </div>
              </div>
            </div>
          </>
          <OverPack playScale={0.3}>
            <QueueAnim
              key="text"
              type="bottom"
              leaveReverse
              ease={['easeOutQuad', 'easeInQuad']}
              style={{minHeight: '549px'}}
            >
              <div className='title' style={{margin: '60px 0 76px'}} key='title'>
                高质量发展道路上<span>遇到的挑战</span>
              </div>
              <img src={Bg01} alt="" width='1164px' height='422px' key='img' />
            </QueueAnim>
          </OverPack>
          <OverPack playScale={0.3}>
            <QueueAnim
              key="text"
              type="bottom"
              leaveReverse
              ease={['easeOutQuad', 'easeInQuad']}
              style={{minHeight: '607px'}}
            >
              <div className='title' style={{margin: '101px 0 60px'}} key='title'>
                银行高质量发展<span>四大维度</span>
              </div>
              <img src={Bg02} alt="" width='1075px' height='497px' style={{marginLeft: '53px'}} key='img' />
            </QueueAnim>
          </OverPack>
          <OverPack playScale={0.3}>
            <QueueAnim
              key="text"
              type="bottom"
              leaveReverse
              ease={['easeOutQuad', 'easeInQuad']}
              style={{minHeight: '691px'}}
            >
              <div className='title' style={{margin: '100px 0 60px'}} key='title'>
                <span>以客户为中心，向专业服务转型</span> <br />
                助力银行高质量发展战略落地
              </div>
              <img src={Bg03} alt="" width='1200px' height='536px' key='img' />
            </QueueAnim>
          </OverPack>

          <OverPack playScale={0.3}>
            <QueueAnim
              key="text"
              type="bottom"
              leaveReverse
              ease={['easeOutQuad', 'easeInQuad']}
              style={{minHeight: '646px'}}
            >
              <div className='title' style={{margin: '100px 0 0'}}  key='title'>
                <span>强化一线网点转型支撑</span> <br />
                助力银行高质量发展战略落地
              </div>
              <div className='desc' style={{margin: '24px 0 40px'}}  key='desc'>
                一线网点作为客户的关键触点，是将银行战略落地实施的核心环节，其经营质量优劣将直接影响高质量发展推进速度，因此 <br />
                熙诚教育投资将关注重点聚焦网点经营改善，基于网点不同岗位的核心职能，系统化推进转型效率。
              </div>
              <img src={Bg04} alt="" width='1200px' height='431px' key='img' />
            </QueueAnim>
          </OverPack>
          <OverPack playScale={0.3}>
            <QueueAnim
              key="text"
              type="bottom"
              leaveReverse
              ease={['easeOutQuad', 'easeInQuad']}
              style={{minHeight: '604px'}}
            >
              <div className='title' style={{margin: '100px 0 30px'}} key='title'>
                <span>熙诚教育服务产品矩阵</span>
              </div>
              <div className='summary-matrix flex-center' key='img'>
                <img src={Bg05} alt="" width='1118px' height='448px' />
              </div>
            </QueueAnim>
          </OverPack>
          <OverPack playScale={0.3}>
            <QueueAnim
              key="text"
              type="bottom"
              leaveReverse
              ease={['easeOutQuad', 'easeInQuad']}
              style={{minHeight: '188px'}}
            >
              <div className='wrap-drill-title' key='title'>
                产品：<span>支行长经营提升训练营</span>
              </div>
            </QueueAnim>
          </OverPack>
        </div>
      </div>
      <div className='wrap-bank-list-drill'>
        <div className='home-page'>
          <OverPack playScale={0.3}>
            <QueueAnim
              key="text"
              type="bottom"
              leaveReverse
              ease={['easeOutQuad', 'easeInQuad']}
              style={{minHeight: '468px'}}
            >
              <div className='title' key='title'>
                <span>支行网点经营面临的现实挑战</span>
              </div>
              <div className='desc' key='desc'>
                在当前息差持续收窄，营收增长承压的大环境下，支行网点是银行业绩达成的一线堡垒，正面临着更具体和艰巨的挑战，网点作为银行最昂贵的渠道资源，如何持续激发这一基本经营单元的活力、强化网点在银行获客留客中的作用、提高网点市场竞争力，是支行长需解决的核心课题。
              </div>
              <div className='block-drill' key='1'>
                <div className='block-drill-title'>同质化竞争应对</div>
                <div className='block-drill-line' style={{marginLeft: '42px'}}></div>
                <div className='block-drill-text'>
                  不同网点的功能布局、业务流程、产品服务、客户体验等业务要素呈现高度同质化，如何建立自身的差异化竞争优势
                </div>
              </div>
              <div className='block-drill' key='2'>
                <div className='block-drill-title'>金融科技发展赋能</div>
                <div className='block-drill-line' style={{marginLeft: '22px'}}></div>
                <div className='block-drill-text'>
                  随着金融科技发展，标准化服务逐步向线上渠道发展，如何更高效得运用线下资源、加强线上线下一体化建设、强化网 点个性化服务优势
                </div>
              </div>
              <div className='block-drill' key='3'>
                <div className='block-drill-title'>经营效能要求提高</div>
                <div className='block-drill-line' style={{marginLeft: '22px'}}></div>
                <div className='block-drill-text'>
                  营收压力增大，各网点降本增效要求随之提高，逐步从规模速度型粗放增长转向质量效率型集约增长，如何向精细化经营转型
                </div>
              </div>
            </QueueAnim>
          </OverPack>
          <OverPack playScale={0.3}>
            <QueueAnim
              key="text"
              type="bottom"
              leaveReverse
              ease={['easeOutQuad', 'easeInQuad']}
              style={{minHeight: '705px'}}
            >
              <div className='title' style={{margin: '100px 0 60px'}} key='title'>
                <span>支行网点在竞争中的破局点</span> <br />
                立足区域特色，开展特色化经营
              </div>
              <div className='desc' style={{margin: '20px 0 47px'}}  key='desc'>2023年10月举行的中央金融工作会议为金融机构高质量发展举旗定向，对完善机构差异化定位提出新的要求; 吸引优质资产，优化负债结构，提升非息业务占比等推动营收的关键方法，也对更精准的客户需求把握、更优质 的客户体验、更专业的产品服务等提出了更高要求。谁在特色化道路上占得先机，将直接决定未来的生存处境。</div>
              <img src={Bg06} alt="" width='1200px' height='447px' key='img' />
            </QueueAnim>
          </OverPack>
        </div>
      </div>
      <div className='wrap-bank-list-branch'>
        <div className='home-page'>
          <OverPack playScale={0.3}>
            <QueueAnim
              key="text"
              type="bottom"
              leaveReverse
              ease={['easeOutQuad', 'easeInQuad']}
              style={{minHeight: '624px'}}
            >
              <div className='title' key='title'>
                <span>特色化经营对支行长的核心能力要求</span>
              </div>
              <div className='desc' style={{margin: '20px 0 30px'}} key='desc'>
                特色化经营策略来源于支行网点对区域性市场及客户的覆盖既了解，如何提升网点专业化、精细化、差异化的金融服务及客户体验的综合能力，则是支行长进行网点经营时的核心重点。基于此，结合熙诚教育投资丰富的行业经验，重新定义在推进网点特色化经营的过程，支行长需具备的核心能力:
              </div>
              <div className='branch-block flex-center' key='img'>
                <img src={Bg07} alt="" width='1046px' height='369px' />
              </div>
            </QueueAnim>
          </OverPack>
          <OverPack playScale={0.3}>
            <QueueAnim
              key="text"
              type="bottom"
              leaveReverse
              ease={['easeOutQuad', 'easeInQuad']}
              style={{minHeight: '682px'}}
            >
              <div className='title' style={{marginTop: '60px'}} key='title'>
                <span>支行长经营提升训练营核心收益</span>
              </div>
              <div className='desc' style={{margin: '15px 0 28px', width: '1218px'}} key='desc'>
                区别于传统培训项目对于支行长能力的全维度培养，经营提升训练营聚焦于协同支行长确定特色化经营策略及落地计划，通过推动支行网点转型，助力银行高质量发展推进:
              </div>
              <img src={Bg08} alt="" width='1200px' height='561px' key='img'/>
            </QueueAnim>
          </OverPack>
          <OverPack playScale={0.3}>
            <QueueAnim
              key="text"
              type="bottom"
              leaveReverse
              ease={['easeOutQuad', 'easeInQuad']}
              style={{minHeight: '745px'}}
            >
              <div className='title' style={{margin: '80px 0 30px'}}  key='title'>
                <span>支行长经营提升训练营项目框架</span>
              </div>
              <img src={Bg09} alt="" width='1200px' height='665px' key='img'/>
            </QueueAnim>
          </OverPack>
        </div>
      </div>
    </div>
  )
}

export default BankList