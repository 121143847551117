import React from 'react'
import classNames from 'classnames'
// @ts-expect-error
import {SwitchTransition, CSSTransition} from 'react-transition-group'
import {handleScrollToTop, handleOpenPage} from '@/utils'
import './index.less'

type IProps = {
  activeKey?: string
  title: string
  children: React.ReactNode
}

export class StudyTabs extends React.PureComponent<IProps> {
  constructor(props) {
    super(props)
    this.state = {
      activeKey: this.props.activeKey,
      isVisible: true
    }
  }

  componentDidUpdate(prevProps: Readonly<{}>, prevState: Readonly<{}>, snapshot?: any) {
    if (prevProps.activeKey !== this.props.activeKey) {
      this.setState({
        activeKey: this.props.activeKey
      })
    }
  }

  renderHeader = () => {
    return React.Children.map(this.props.children, (element, index) => {
      const activeStyle = element.key === this.state.activeKey ? "activeTitle" : null;
      return (
        <span
          onClick={() => {
            handleScrollToTop()
            this.setState({
              activeKey: element.key,
              isVisible: !this.state.isVisible
            })
          }}
          className={classNames("s-title", activeStyle)}
        >
          {element.props.title}
        </span>
      )
    })
  }

  renderContent = () => {
    return React.Children.map(this.props.children, (element, index) => {
      if (element.key === this.state.activeKey) {
        return (<>{element.props.children}</>)
      }
    })
  }

  render() {
    return (
      <div className="wrapper-study-tabs">
        <div className='wrapper-title'>
          <div className="title-container home-page">
            {this.renderHeader()}
          </div>
        </div>
        <SwitchTransition>
          <CSSTransition
            key={this.state.isVisible}
            timeout={400}
            classNames="panel"
          >
            <div className="content-container">{this.renderContent()}</div>
          </CSSTransition>
        </SwitchTransition>
      </div>
    )
  }
}

export class TabPane extends React.PureComponent<IProps> {
  render() {
    return (
      <div>
        <div>{this.props.title}</div>
        <div>{this.props.children}</div>
      </div>
    )
  }
}

