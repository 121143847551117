import React, {useRef} from 'react'
import Banner from './Banner'
import Investor from './Investor'
import Structure from './Structure'
import Vision from './Vision'
import Contrib from './Contrib'
import Awards from './Awards'

const About: React.FC = () => {

  return (
    <main >
      <div className='warp-about header-top'>
        <Banner />
        <Investor />
        <Structure />
        <Vision />
        <Contrib />
        <Awards />
      </div>
    </main>
  )
}

export default About