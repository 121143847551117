import React from 'react'
import {Col, Row} from 'antd'
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack'
import QueueAnim from 'rc-queue-anim'
import TweenOne from 'rc-tween-one'
import { getAnim } from '@/utils'
import InvestorBg from '@/assets/images/about/investor-bg.png'


const Investor: React.FC = () => {
  const imgAnim: any = getAnim()

  return (
    <div className='home-page'>
      <OverPack playScale={0.3} component={Row} className='warp-about-investor'>
        <QueueAnim
          key="text"
          type="left"
          leaveReverse
          ease={['easeOutQuad', 'easeInQuad']}
          component={Col}
          md={11}
          className='warp-about-investor-left'
        >
          <QueueAnim
            component="ul"
            key="ul"
            type="left"
            ease="easeOutQuad"
          >
            <div className='title'>关于熙诚教育投资</div>
            <div className='desc'>
              北京熙诚教育投资有限公司(简称“熙诚教育投资”)成立于2017年，是北京金融街资本运营集团有限公司(简称“金融街资本”)旗下的国有全资公司。作为中国咨询培训行业的国企先锋，熙诚教育投资聚焦于企业管理咨询和企业学习,将先进理论、成功经验与企业管理实践相结合，帮助企业形成清晰的发展战略、找到战略落地路径、提高战略落地效率，并实现增长突破和业务创新，帮助其不断提升组织效能。
            </div>
          </QueueAnim>
        </QueueAnim>
        <TweenOne
          key="img"
          animation={imgAnim}
          resetStyle
          component={Col}
          md={10}
        >
          <img src={InvestorBg}  width="100%" alt="" />
        </TweenOne>
      </OverPack>
    </div>
  )
}

export default Investor