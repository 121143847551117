import React, {useState} from 'react'
import {Row, Col} from 'antd'
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack'
import QueueAnim from 'rc-queue-anim'
import TweenOne from 'rc-tween-one'
import Logo01 from '@/assets/images/home/logo1-01.png'
import Logo02 from '@/assets/images/home/logo1-02.png'
import Logo03 from '@/assets/images/home/logo1-03.png'
import Logo04 from '@/assets/images/home/logo1-04.png'
import Logo05 from '@/assets/images/home/logo1-05.png'
import Logo06 from '@/assets/images/home/logo1-06.png'
import Logo07 from '@/assets/images/home/logo1-07.png'
import Logo08 from '@/assets/images/home/logo1-08.png'
import Logo09 from '@/assets/images/home/logo1-09.png'
import Logo10 from '@/assets/images/home/logo1-10.png'
import Logo11 from '@/assets/images/home/logo1-11.png'
import Logo12 from '@/assets/images/home/logo1-12.png'
import Logo13 from '@/assets/images/home/logo1-13.png'
import Logo14 from '@/assets/images/home/logo1-14.png'
import Logo15 from '@/assets/images/home/logo1-15.png'
import Logo16 from '@/assets/images/home/logo1-16.png'
import Logo17 from '@/assets/images/home/logo1-17.png'
import Logo18 from '@/assets/images/home/logo1-18.png'
import Logo19 from '@/assets/images/home/logo1-19.png'
import Logo20 from '@/assets/images/home/logo1-20.png'
import Logo21 from '@/assets/images/home/logo1-21.png'
import Logo22 from '@/assets/images/home/logo1-22.png'
import Logo23 from '@/assets/images/home/logo1-23.png'
import Logo24 from '@/assets/images/home/logo1-24.png'
import Logo25 from '@/assets/images/home/logo1-25.png'
import Logo26 from '@/assets/images/home/logo1-26.png'
import Logo27 from '@/assets/images/home/logo1-27.png'
import Logo28 from '@/assets/images/home/logo1-28.png'
import Logo29 from '@/assets/images/home/logo1-29.png'
import Logo30 from '@/assets/images/home/logo1-30.png'
import Logo31 from '@/assets/images/home/logo1-31.png'
import Logo32 from '@/assets/images/home/logo1-32.png'
import Logo33 from '@/assets/images/home/logo1-33.png'
import Logo34 from '@/assets/images/home/logo1-34.png'
import Logo35 from '@/assets/images/home/logo1-35.png'
import Logo36 from '@/assets/images/home/logo1-36.png'
import Logo37 from '@/assets/images/home/logo1-37.png'
import Logo38 from '@/assets/images/home/logo1-38.png'
import Logo39 from '@/assets/images/home/logo1-39.png'
import Logo40 from '@/assets/images/home/logo1-40.png'

const Teams = () => {
  const [data, setData] = useState([
    {
      url: Logo01
    },
    {
      url: Logo02
    },
    {
      url: Logo03
    },
    {
      url: Logo04
    },
    {
      url: Logo05
    },
    {
      url: Logo06
    },
    {
      url: Logo07
    },
    {
      url: Logo08
    },
    {
      url: Logo09
    },
    {
      url: Logo10
    },
    {
      url: Logo11
    },
    {
      url: Logo12
    },
    {
      url: Logo13
    },
    {
      url: Logo14
    },
    {
      url: Logo15
    },
    {
      url: Logo16
    },
    {
      url: Logo17
    },
    {
      url: Logo18
    },
    {
      url: Logo19
    },
    {
      url: Logo20
    },
    {
      url: Logo21
    },
    {
      url: Logo22
    },
    {
      url: Logo23
    },
    {
      url: Logo24
    },
    {
      url: Logo25
    },
    {
      url: Logo26
    },
    {
      url: Logo27
    },
    {
      url: Logo28
    },
    {
      url: Logo29
    },
    {
      url: Logo30
    },
    {
      url: Logo31
    },
    {
      url: Logo32
    },
    {
      url: Logo33
    },
    {
      url: Logo34
    },
    {
      url: Logo35
    },
    {
      url: Logo36
    },
    {
      url: Logo37
    },
    {
      url: Logo38
    },
    {
      url: Logo39
    },
    {
      url: Logo40
    },

  ])
  const getDelay = (e, b) => (e % b) * 100 + Math.floor(e / b) * 100 + b * 100;

  const getBlockChildren = (item, i) => {
    const md = 5
    const delay = false ? i * 50 : getDelay(i, 24 / md);
    const liAnim: any = {
      y: 30,
      opacity: 0,
      type: 'from',
      ease: 'easeOutQuad',
      delay,
    };
    return (
      <TweenOne component={Col} animation={liAnim} key={i.toString()} >
        <div className="image-wrapper" >
          <img className='image-item' src={item.url} />
        </div>
      </TweenOne>
    );
  };

  return (
    <div className='wrap-teams'>
      <div className='home-page'>
        <div key="title" className='title'>
          <div>助力企业取得成功</div>
          <div>为企业和社会创造更多价值</div>
        </div>
        <OverPack playScale={0.3}>
          <QueueAnim
            type="bottom"
            key="img"
          >
            <Row className='content-wrapper' key="img">
              {data.map((item, i) => getBlockChildren(item, i))}
            </Row>
          </QueueAnim>
        </OverPack>
      </div>
    </div>
  )
}

export default Teams