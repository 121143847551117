import React, {useEffect, useState} from 'react'
import {Col, Row} from 'antd'
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack'
import QueueAnim from 'rc-queue-anim'
import TweenOne from 'rc-tween-one'
import { getAnim } from '@/utils'
import Ruler01 from '@/assets/images/about/ruler01.png'
import Ruler02 from '@/assets/images/about/ruler02.png'
import Ruler03 from '@/assets/images/about/ruler03.png'
import Ruler04 from '@/assets/images/about/ruler04.png'
import Ruler05 from '@/assets/images/about/ruler05.png'

const Contrib: React.FC = () => {
  const [data, setData] = useState([
    {
      year: '2017-2019',
      url: Ruler01,
      title: '注资6亿元打造教育产业投资集团',
      desc: '为响应北京市西城区政府及股东北京市金融街资本运营集团有限公司的战略部署，抓住教育产业发展机遇，北京熙诚教育投资有限公司应运而生，注册资本6亿元人民币，旨在打造西城区教育板块投资平台。',
      tips: '◎ 教育产业基金投资\n◎ 民办学校投资'
    },
    {
      year: '2020',
      url: Ruler02,
      title: '精准定位于企业管理培训领域',
      desc: '熙诚教育投资明确战略方向，瞄向企业管理培训赛道开始市场化经营，秉承“根植本地，立足全球，为企业提供人才培养一站式解决方案”服务理念，与多家国内外顶尖的知名学府和咨询机构建立了战略合作关系，共同研发优质课程。主营业务收入实现3倍增长，迈出规模增长第一步。',
      tips: '◎ 哥伦比亚大学\n◎ 巴黎HEC商学院\n◎ 长江商学院\n◎ 清华大学\n◎ 北京大学'
    },
    {
      year: '2021',
      url: Ruler03,
      title: '聚合生态资源，全方位打造产品体系',
      desc: '聚焦企业管理和企业发展核心问题，熙诚教育投资搭建企业内训、公开课、素质研学、团建拓展、红色党建、管理咨询、知识共享、企业家训练营等九大产品体系，并从战略、文化、组织、人才四个维度自主研发构建“场域”模型课程体系，全方位打造产品体系，与企业共成长，实现高质量可持续发展。',
      tips: '◎ 自主研发“场域”模型课程体系\n◎ 服务覆盖金融、科技、医药等十大领域头部企业\n◎ 200+位高管教练、知名企业家及高等学府知名教授学者组成的师资团队\n◎ 200+聚焦于企业发展问题的精品课程'
    },
    {
      year: '2022',
      url: Ruler04,
      title: '坚持产品聚焦+差异化策略，提升核心竞争力',
      desc: '熙诚教育投资在原有产品体系基础之上，构建多元产品矩阵，全面覆盖企业不同发展阶段的需求，重点聚焦在企业家培养、数字化解决方案建设、企业战略咨询和企业人才发展解决方案建设，在定制化产品和服务的方向打造公司核心竞争力。',
      tips: '◎ 与北交所达成战略合作，助力中小企业高质量发展\n◎ 《演进1号位》六大系列产品矩阵推出'
    },
    {
      year: '2023',
      url: Ruler05,
      title: '强化数据沉淀，打造中国式企业管理新范式',
      desc: '熙诚教育投资在领导力培养、系统思考、高潜人才培养、财务团队赋能、企业数字化转型等细分领域重点发力，推出多款自研产品，并重点打造讲师地图，为企业健康发展创造有效路径。熙诚教育投资累计服务超过500家企业，学员人数超过2万，围绕“为世界打造中国式企业管理典范”这一长期目标不断奋斗。',
      tips: '◎ 上海、深圳、成都三地设立办事处，全国范围内开展业务\n◎ 与上海春禾青少年发展中心达成战略合作，共同推动核心素养教育的发展，助力乡村教育振兴\n◎ 与雄安新区达成战略合作，双方将以培育企业在雄安自贸试验区发展兴业为导向，优势互补，协同发展'
    },
  ])
  const [item, setItem] = useState<any>({})
  const [idx, setIdx] = useState(data?.length - 1)

  useEffect(() => {
    setItem(data[idx])
  }, [])

  const imgAnim: any = getAnim()

  const handleMouseEnter = (item, index) => {
    setIdx(index)
    setItem(item)
  }

  const handleMouseLeave = () => {

  }
  return (
    <div className='warp-about-contrib'>
      <div className='home-page'>
        <div className='title'>
          征途中的里程碑
        </div>
        <OverPack playScale={0.3} component={Row}>
          <QueueAnim
            key="text"
            type="left"
            leaveReverse
            ease={['easeOutQuad', 'easeInQuad']}
            component={Col}
            md={6}
          >
            <QueueAnim
              component="ul"
              key="ul"
              type="left"
              ease="easeOutQuad"
              style={{display: 'flex', justifyContent: 'flex-end', marginRight: '13px'}}
            >
              <div className='contrib-box'>
                {
                  data.map((item, index) => (
                    <div className={`contrib-box-0${index + 1} ${index === idx && 'active'}`}
                         onMouseEnter={() => handleMouseEnter(item, index)}
                         onMouseLeave={handleMouseLeave}
                    >{item.year}</div>
                  ))
                }
              </div>
              <div className='contrib-img'>
                <img width='100%' src={item?.url} alt="" />
              </div>
            </QueueAnim>
          </QueueAnim>
          <TweenOne
            key="img"
            animation={imgAnim}
            resetStyle
            component={Col}
            md={18}
          >
            <div className='contrib-block'>
              <div>
                <div className='contrib-block-title'>{item?.title}</div>
                <div className='contrib-block-desc'>
                  {item?.desc}
                </div>
              </div>
              <div className='contrib-block-copy p-pre-wrap'>
                {item?.tips}
              </div>
            </div>
          </TweenOne>
        </OverPack>
      </div>
    </div>
  )
}

export default Contrib