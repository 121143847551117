import React from 'react'
import './index.less'

const Nhhy: React.FC = () => {

  return (
    <main className='header-top ' style={{padding: '60px 0'}}>
      <div className='home-page'>
        <img style={{width: '100%'}} src="https://lecturermap-1319224539.cos.ap-beijing.myqcloud.com/cdn/%E5%AE%98%E7%BD%91/nhhy1.jpeg" alt=""/>
        <img style={{width: '100%'}} src="https://lecturermap-1319224539.cos.ap-beijing.myqcloud.com/cdn/%E5%AE%98%E7%BD%91/nhhy2.jpeg" alt=""/>
      </div>
    </main>
  )
}

export default Nhhy