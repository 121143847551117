import React from 'react'
import './index.less'

const Zxkc: React.FC = () => {

  return (
    <main className='home-page flex-center' style={{padding: '60px 0'}}>
      <img className='header-top' src="https://lecturermap-1319224539.cos.ap-beijing.myqcloud.com/cdn/%E5%AE%98%E7%BD%91/zxkc1.jpg" alt=""/>
    </main>
  )
}

export default Zxkc