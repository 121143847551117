import React from 'react';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import { useLocation } from 'react-router-dom';

const TransitionComponent = ({ children }) => {
  const location = useLocation();

  return (
    <SwitchTransition>
      <CSSTransition
        key={location.pathname}
        timeout={400}
        classNames="panel"
      >
        <>
          {children}
        </>
      </CSSTransition>
    </SwitchTransition>
  );
};

export default TransitionComponent;