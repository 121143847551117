import React, {useState, useRef} from 'react'
import {Row, Col, Carousel as AntCarousel} from 'antd'
import {useNavigate} from 'react-router-dom'
import {handleScrollToTop} from '@/utils'
import QueueAnim from 'rc-queue-anim'
import DisableLeft from '@/assets/images/disable_left.png'
import DisableRight from '@/assets/images/disable_right.png'

import './index.less'

const chunkSize = 9;

const List = (props) => {
  const [current, setCurrent] = useState(0)

  const carouselRef = useRef(null)
  const navigate = useNavigate()

  const handleDetail = (i) => {
    navigate(`/about/newsDetail/news/${i}`)
  }

  const goToSlide = (index: number) => {
    if (carouselRef.current) {
      setCurrent(index)
      carouselRef.current.goTo(index)
      handleScrollToTop()
    }
  };

  const onChangePack = ({mode}) => {
    if (mode === 'leave') {
      setCurrent(0)
    }
  }

  const groups = [];
  for (let i = 0; i < props.data.length; i += chunkSize) {
    groups.push(props.data.slice(i, i + chunkSize));
  }

  return (
    <div className='wrap-list'>
      <QueueAnim
        key="queue"
        leaveReverse
        type='bottom'
        delay={[0, 100]}>
        <div key="carousel">
          <AntCarousel
            ref={carouselRef}
            dots={false}
            infinite={false}
          >
            {
              groups.map((group, groupIndex) => (
                <div key={`row-${groupIndex}`}>
                  <QueueAnim
                    type="left"
                    component={Row}
                    delay={[0, 100]}
                  >
                    {
                      group.map((item, i) => (
                        <Col key={i.toString()} md={8}>
                          <div className='wrap-list-block' onClick={() => handleDetail(item.index)}>
                            <img src={item?.img} alt=""/>
                            <div className='list-date'>{item.date}</div>
                            <div className='list-title'>{item.title}</div>
                            <div className='list-desc'>{item.desc}</div>
                          </div>
                        </Col>
                      ))
                    }
                  </QueueAnim>
                  <div className='direction'>
                    {
                      current === 0 ? <img className='img-font m-r' src={DisableLeft} alt=""/> :
                        <div className='img-font l-img m-r' onClick={() => goToSlide(current - 1)}></div>
                    }
                    {
                      groups.length - 1 === current ? <img className='img-font' src={DisableRight} alt=""/> :
                        <div className='img-font r-img' onClick={() => goToSlide(current + 1)}></div>
                    }
                  </div>
                </div>
              ))
            }
          </AntCarousel>
        </div>
      </QueueAnim>
    </div>
  )
}

export default List

