import React from 'react'
import {Col, Row} from 'antd'
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack'
import QueueAnim from 'rc-queue-anim'
import TweenOne from 'rc-tween-one'
import AMap from '@/components/AMap'

const Place = (props) => {
  const { data } = props
  const imgAnim: any = false
    ? {
      y: 30,
      opacity: 0,
      delay: 600,
      type: 'from',
      ease: 'easeOutQuad',
    }
    : {
      x: 30,
      opacity: 0,
      type: 'from',
      ease: 'easeOutQuad',
    };

  return (
    <div className='wrap-place'>
      <OverPack playScale={0.3} component={Row} className='wrap-place-block home-page'>
        <QueueAnim
          key="text"
          type="left"
          leaveReverse
          ease={['easeOutQuad', 'easeInQuad']}
          component={Col}
          md={10}
        >
          <QueueAnim
            component="ul"
            key="ul"
            type="left"
            ease="easeOutQuad"
          >
            <div className='place-title'>{data.title}</div>
            <div className='place-desc'>
              公司地址：{data.address} <br />
              联系邮箱：marketing@xichengedu.com <br />
              联系电话：4006996160
            </div>
          </QueueAnim>
        </QueueAnim>
        <TweenOne
          key="img"
          animation={imgAnim}
          resetStyle
          component={Col}
        >
          <AMap id={data.id} center={data.center}/>
        </TweenOne>
      </OverPack>
    </div>
  )
}

export default Place