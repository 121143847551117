import React, { useRef, useEffect } from 'react';
import AMapLoader from '@amap/amap-jsapi-loader'
import Marker from '@/assets/images/marker.png'
import './index.less'

const AMap: React.FC = (props) => {
  const { id, center } = props
  let map = null;

  useEffect(() => {
    window._AMapSecurityConfig = {
      securityJsCode: "1bfa3f607c3d19f801c206209d0b8c7c",
    };
    AMapLoader.load({
      key: "2517f1f6cf352a09c838f2e6fb17e01b",
      version: "2.0",
    })
      .then((AMap) => {
        map = new AMap.Map(id, {
          // 设置地图容器id
          zoom: 16, // 初始化地图级别
          center: center,
        });

        new AMap.Marker({
          position: center,
          icon: Marker,
          map: map
        });

      })
      .catch((e) => {
        console.log(e);
      });

    return () => {
      map?.destroy();
    };
  }, []);

  return <div
    id={id}
    className='a-map'
  ></div>
};

export default AMap