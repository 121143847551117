import React, {useState, useRef} from 'react'
import {Row, Col, Carousel as AntCarousel} from 'antd'
import {useNavigate} from 'react-router-dom'
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack'
import QueueAnim from 'rc-queue-anim'
import DisableLeft from '@/assets/images/disable_left.png'
import DisableRight from '@/assets/images/disable_right.png'
import Data from '@/utils/dynamic'

const chunkSize = 3;

const Dynamic = () => {
  const [current, setCurrent] = useState(0)
  const navigate = useNavigate()
  const [data, setData] = useState(Data)
  const carouselRef = useRef(null)

  const goToSlide = (index: number) => {
    if (carouselRef.current) {
      setCurrent(index)
      carouselRef.current.goTo(index)
    }
  };

  const handleDetail = (i) => {
    navigate(`/about/newsDetail/home/${i}`)
  }

  const onChangePack = ({mode}) => {
    if(mode === 'leave') {
      setCurrent(0)
    }
  }

  const groups = [];
  for (let i = 0; i < data.length; i += chunkSize) {
    groups.push(data.slice(i, i + chunkSize));
  }

  return (
    <div className='wrap-dynamic home-page'>
      <div className='wrap-dynamic-wrap'>
        <div className='title'>
          公司动态
        </div>
        <div className='direction'>
          {
            current === 0 ? <img className='img-font m-r' src={DisableLeft} alt="" /> :
              <div className='img-font l-img m-r' onClick={() => goToSlide(current - 1)}></div>
          }
          {
            groups.length - 1 === current ? <img className='img-font' src={DisableRight} alt="" /> :
              <div className='img-font r-img' onClick={() => goToSlide(current + 1)}></div>
          }
        </div>
      </div>
      <OverPack playScale={0.3} onChange={onChangePack}>
        <QueueAnim
          key="queue"
          leaveReverse
          type='bottom'
          delay={[0, 100]}>
          <div key="carousel">
            <AntCarousel
              ref={carouselRef}
              dots={false}
              infinite={false}
            >
              {
                groups.map((group, groupIndex) => (
                  <div key={`row-${groupIndex}`}>
                    <QueueAnim
                      type="bottom"
                      component={Row}
                    >
                      {
                        group.map((item, i) => (
                          <Col key={i.toString()} md={8} >
                            <div className='wrap-dynamic-block' onClick={() => handleDetail(item.index)}>
                              <img src={item?.img} alt="" />
                              <div className='dynamic-date'>{item.date}</div>
                              <div className='dynamic-title'>{item.title}</div>
                              <div className='dynamic-desc'>{item.desc}</div>
                            </div>
                          </Col>
                        ))
                      }
                    </QueueAnim>
                  </div>
                ))
              }
            </AntCarousel>
          </div>
        </QueueAnim>
      </OverPack>
    </div>
  )
}

export default Dynamic

