import React, { useState } from 'react'
import {Row, Col} from 'antd'
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack'
import QueueAnim from 'rc-queue-anim'
import Lqm from '@/assets/images/product/mark/lqm.png'
import Lxx from '@/assets/images/product/mark/lxx.png'
import Zl from '@/assets/images/product/mark/zl.png'
import Wxm from '@/assets/images/product/mark/wxm.png'
import Hyh from '@/assets/images/product/mark/hyh.png'
import Ly from '@/assets/images/product/mark/ly.png'

const Tutor = () => {

  return (
    <div className='wrap-tutor home-page'>
      <div className='title'>项目导师团</div>
      <OverPack playScale={0.3} >
        <QueueAnim
          type="bottom"
          key="block"
          leaveReverse
          component={Row}
          className='wrap-tutor-items'
        >
          <Col md={8} key='1'>
            <div className='tutor-item'>
              <img src={Lqm} alt=""/>
              <div className='item-title'>刘启明 训练营战略导师</div>
              <div className='item-desc'>
                曾任盖洛普(中国)咨询有限公司首席咨询顾问.副总裁; <br />
                领导并完成过超过100家企业的战略咨询及落地。
              </div>
            </div>
          </Col>
          <Col md={8} key='2'>
            <div className='tutor-item'>
              <img src={Lxx} alt=""/>
              <div className='item-title'>李小雄 训练营产品导师</div>
              <div className='item-desc'>
                前小米副总经理，前三星，OPPO，步步高等多家世界五百强企业高管； <br />
                万亿销售规模市场的实际操盘经营。
              </div>
            </div>
          </Col>
          <Col md={8} key='3'>
            <div className='tutor-item'>
              <img src={Zl} alt=""/>
              <div className='item-title'>张黎 训练营客户体验与营销导师</div>
              <div className='item-desc'>
                北大国家发展研究院管理学教授；<br />
                中国商业联合会专家委员会委员；<br />
                中国品牌研究中心顾问。
              </div>
            </div>
          </Col>
          <Col md={8} key='4'>
            <div className='tutor-item'>
              <img src={Wxm} alt=""/>
              <div className='item-title'>王秀梅 训练营组织与人才发展导师</div>
              <div className='item-desc'>
                原平安陆金所人力资源负责人；<br />
                原平安资产管理公司人力资源&企划财务负责人，帮助企业实现 从0到1的突破，成为行业的领头羊。
              </div>
            </div>
          </Col>
          <Col md={8} key='5'>
            <div className='tutor-item'>
              <img src={Hyh} alt=""/>
              <div className='item-title'>何怡宏 训练营上市财务导师</div>
              <div className='item-desc'>
                曾任美国蒙莫斯大学商学院终身教授；<br />
                北京大学，中欧国际工商管理学院特聘教授；<br />
                上海证券交易所特聘讲师。
              </div>
            </div>
          </Col>
          <Col md={8} key='6'>
            <div className='tutor-item'>
              <img src={Ly} alt=""/>
              <div className='item-title'>李越 训练营上市规划导师</div>
              <div className='item-desc'>
                现任北京熙诚教育投资有限公司总经理； <br />
                曾任东方花旗证券有限公司投资银行部董事 <br />
                曾任全国中小企业股份转让系统融资并购部监管经理。
              </div>
            </div>
          </Col>
        </QueueAnim>
      </OverPack>
    </div>
  )
}

export default Tutor