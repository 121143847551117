import React from 'react'
import { getAnim } from '@/utils'
import {Col, Row} from 'antd'
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack'
import QueueAnim from 'rc-queue-anim'
import TweenOne from 'rc-tween-one'
import UserIcon from '@/assets/images/product/user-icon.png'


const DrillTutor = () => {
  const imgAnim: any = getAnim()

  return (
    <div className='wrap-drill-tutor'>
      <div className='home-page'>
        <OverPack playScale={0.3} component={Row} >
          <QueueAnim
            key="text"
            type="left"
            leaveReverse
            ease={['easeOutQuad', 'easeInQuad']}
            component={Col}
            md={9}
          >
            <QueueAnim
              key="ul"
              type="left"
              ease="easeOutQuad"
            >
              <div className='title m-r'>
                训练营 <br />
                投资人导师团
              </div>
              <div className='desc'>(部分)</div>
            </QueueAnim>
          </QueueAnim>
          <TweenOne
            key="queue"
            animation={imgAnim}
            resetStyle
            component={Col}
            md={15}
          >
            <Row className='drill-tutor-right'  gutter={[20, 20]}>
              <Col >
                <div className='drill-tutor-right-block'>
                  <img src={UserIcon} alt=""/>
                  <div>
                    <div className='title-right'>
                      李绪富
                    </div>
                    <div className='desc-right'>
                      新犁资本创始人、京东核心投资人
                    </div>
                  </div>
                </div>
              </Col>
              <Col  >
                <div className='drill-tutor-right-block'>
                  <img src={UserIcon} alt=""/>
                  <div>
                    <div className='title-right'>
                      刘平安
                    </div>
                    <div className='desc-right'>
                      北京金长川资本管理有限公司董事长兼总裁
                    </div>
                  </div>
                </div>
              </Col>
              <Col >
                <div className='drill-tutor-right-block'>
                  <img src={UserIcon} alt=""/>
                  <div>
                    <div className='title-right'>
                      吴琼
                    </div>
                    <div className='desc-right'>
                      鼎翔资本创始合伙人
                    </div>
                  </div>
                </div>
              </Col>
              <Col >
                <div className='drill-tutor-right-block'>
                  <img src={UserIcon} alt=""/>
                  <div>
                    <div className='title-right'>
                      张敬来
                    </div>
                    <div className='desc-right'>
                      熙诚金睿股权投资基金管理有限公司董事
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </TweenOne>
        </OverPack>
      </div>
    </div>
  )
}

export default DrillTutor