import React from 'react'
import {Col, Row} from 'antd'
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack'
import QueueAnim from 'rc-queue-anim'
import TweenOne from 'rc-tween-one'
import { getAnim } from '@/utils'
import Project01 from '@/assets/images/product/project01.png'
import Project02 from '@/assets/images/product/project02.png'
import Project03 from '@/assets/images/product/project03.png'
import circleRight from '@/assets/images/product/circle-right.png'
import circleLeft from '@/assets/images/product/circle-left.png'


const Merit = () => {
  const imgAnim: any = getAnim()

  return (
    <div className='wrap-merit'>
      <div className='home-page'>
        <div className='title'>项目优势</div>
        <OverPack playScale={0.3} component={Row} className='wrap-merit-block m-t01'>
          <QueueAnim
            key="text"
            type="left"
            leaveReverse
            ease={['easeOutQuad', 'easeInQuad']}
            component={Col}
            md={11}
          >
            <QueueAnim
              component="ul"
              key="ul"
              type="left"
              ease="easeOutQuad"
            >
              <div className='title m-b-title'>国企背景 值得信赖</div>
              <div className='desc'>
                ◎ 金融街资本旗下全资子公司，专注优质中小企业培育与融资上市辅导 <br />
                ◎ 2022年Hicool创业大赛战略合作伙伴，总决赛评委
              </div>
            </QueueAnim>
          </QueueAnim>
          <TweenOne
            key="img"
            animation={imgAnim}
            resetStyle
            component={Col}
          >
            <img className='project-img' src={Project01}  width="100%" alt="" />
          </TweenOne>
        </OverPack>
        <OverPack playScale={0.3} component={Row} className='wrap-merit-block m-t01'>
          <QueueAnim
            key="text"
            type="left"
            leaveReverse
            ease={['easeOutQuad', 'easeInQuad']}
            component={Col}
            md={12}
          >
            <QueueAnim
              component="ul"
              key="ul"
              type="left"
              ease="easeOutQuad"
            >
              <img className='project-img' src={Project02}  width="100%" alt="" />
            </QueueAnim>
          </QueueAnim>
          <TweenOne
            key="img"
            animation={imgAnim}
            resetStyle
            component={Col}
            md={11}
            style={{paddingLeft: '20px'}}
          >
            <div className='title m-b-title'>资源丰富 值得托付</div>
            <div className='desc'>
              ◎ 链接20+投资人机会，根据学员企业背景定向邀约 <br />
              ◎ 走进中国3大交易所参访与交流机会 <br />
              ◎ 对接国家及地方产业扶持政策，1V1 咨询 <br />
              ◎ 链接超过50位优秀企业家:智慧激荡，共同成长
            </div>

          </TweenOne>
        </OverPack>
        <OverPack playScale={0.3} component={Row} className='wrap-merit-block m-t01'>
          <QueueAnim
            key="text"
            type="left"
            leaveReverse
            ease={['easeOutQuad', 'easeInQuad']}
            component={Col}
            md={12}
          >
            <QueueAnim
              component="ul"
              key="ul"
              type="left"
              ease="easeOutQuad"
            >
              <div className='title'>
                陪跑式服务 <br />
                一站解决增长+融资+上市问题
              </div>
              <div className='desc' style={{marginTop: '21px'}}>
                成立以来服务超过500家企业人才培养、业务问题解决及投后成长。
              </div>
              <Row  style={{marginTop: '14px'}}>
                <Col md={10} className='desc'>
                  ◎ 投资路演会 <br />
                  ◎ 标杆企业家交流 <br />
                  ◎ 企业参访 合作链接 <br />
                </Col>
                <Col md={12} className='desc'>
                  ◎ FA服务 <br />
                  ◎ 上市辅导 <br />
                  ◎ 增长陪跑、咨询 <br />
                </Col>
              </Row>
            </QueueAnim>
          </QueueAnim>
          <TweenOne
            key="img"
            animation={imgAnim}
            resetStyle
            component={Col}
          >
            <img className='project-img' src={Project03}  width="100%" alt="" />
          </TweenOne>
        </OverPack>
      </div>
      <img className='circle01' src={circleRight} alt=""/>
      <img className='circle02' src={circleLeft} alt=""/>
      <img className='circle03' src={circleRight} alt=""/>
    </div>
  )
}

export default Merit