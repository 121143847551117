import React, {useState} from 'react'
import { useNavigate } from 'react-router-dom'
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack'
import QueueAnim from 'rc-queue-anim'
import { Row, Col } from 'antd'
import SmallArrows from '@/assets/images/small_arrows.png'
import JoinBg from '@/assets/images/about/join-bg.png'


const Detail: React.FC = () => {
  const [data, setData] = useState([
    {
      name: '销售总监（北京）',
      url: JoinBg
    },
    {
      name: '销售经理（大客户经理-北京）',
      url: JoinBg
    },
    {
      name: '销售总监（上海）',
      url: JoinBg
    },
    {
      name: '销售总监（深圳）',
      url: JoinBg
    },
    {
      name: '销售经理（大客户经理-成都）',
      url: JoinBg
    }
  ])
  const navigate = useNavigate();

  const handleDetail = (i) => {
    navigate(`/about/joinDetail/${i}`)
  }
  return (
    <div className='warp-join-detail home-page'>
      <div className='title'>查看你感兴趣的岗位</div>
      <div className='join-desc'>熙诚教育投资以“传递前沿思想，引领中国奋进英才，成就企业走向世界中心”为使命，以“用创新与智慧引领行业变革，让每一个客户因我们而精进，做企业成长道路上最值得信赖的伙伴”为愿景，朝着“为世界打造中国式企业管理典范”这一长期目标奋勇前进！</div>
      <OverPack playScale={0.2}>
        <QueueAnim
          key="queue"
          leaveReverse
          type='bottom'
          delay={[0, 100]}
          component={Row}
        >
          {
            data.map((item, i) => (
              <Col key={i.toString()} md={8}>
                <div className='wrap-join-block' onClick={() => handleDetail(i)}>
                  <img className='join-img' src={item?.url} alt=""/>
                  <div className='join-title' >
                    <span>{item.name}</span>
                    <img className='arrows-img' src={SmallArrows} alt=""/>
                  </div>
                </div>
              </Col>
            ))
          }
        </QueueAnim>
      </OverPack>
    </div>
  )
}

export default Detail