import React from 'react'
import {Row, Col} from 'antd'
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack'
import QueueAnim from 'rc-queue-anim'

const DrillCol = () => {
  return (
    <div className='wrap-drill-col home-page'>
      <div className='title' style={{marginBottom: '64px'}}>
        训练营日程（12天深度学习）
      </div>
      {/*<div className='desc'>*/}
      {/*  ◎ 第一模块开营时间：2023年12月21日-23日 <br />*/}
      {/*  ◎ 项目时间：2023年12月-2024年7月，4次集中学习*/}
      {/*</div>*/}
      <OverPack playScale={0.3} >
        <QueueAnim
          type="bottom"
          key="block"
          leaveReverse
          component={Row}
          gutter={[10, 10]}
          className='wrap-drill-col-items'
        >
          <Col md={12} key='1'>
            <div className='col-item'>
              <div className='item-title'>
                第一模块 <br/>
                战略思维领航（3天）
              </div>
              <div className='item-desc'>
                ◎ 开营仪式及破冰活动 <br/>
                ◎ VUCA时代的战略调整与变革 <br/>
                ◎ [晚间思享会]企业家面对面 <br/>
              </div>
            </div>
          </Col>
          <Col md={12} key='2'>
            <div className='col-item'>
              <div className='item-title'>
                第二模块 <br/>
                产品营销破局（3天）
              </div>
              <div className='item-desc'>
                ◎ 爆款产品创新之道 <br/>
                ◎ 营销破局倍增之道 <br/>
                ◎ 企业参访学习:小米/元气森林/博世工厂/施耐德工厂 (待定) <br/>
                ◎ [晚间思享会]企业家面对面
              </div>
            </div>
          </Col>
          <Col md={12} key='3'>
            <div className='col-item'>
              <div className='item-title'>
                第三模块 <br/>
                组织系统支持与数字化转型（3天）
              </div>
              <div className='item-desc'>
                ◎ 组织能力解码与设计 <br/>
                ◎ 中小企业数字化转型之道 <br/>
                ◎ [独家辅导] 投资路演会报名及专项辅导
              </div>
            </div>
          </Col>
          <Col md={12} key='4'>
            <div className='col-item'>
              <div className='item-title'>
                第四模块 <br/>
                资本放大优势（3天）
              </div>
              <div className='item-desc'>
                ◎ 公司治理与股权架构 <br/>
                ◎ 上市融资财务智慧 <br/>
                ◎ 上市路径规划 <br/>
                ◎ [1对1 BP指导] 试讲及针对性指导 <br/>
                ◎ [投资人晚餐会] 了解产业发展趋势，洞察投资决策要点 <br/>
                ◎ [梦想之旅] 交易所参访及1对1交流 <br/>
                ◎ [投资路演会] 邀请投资人及金融机构到场指导点评，链接融资机会 <br/>
                ◎ 结营仪式及晚宴
              </div>
            </div>
          </Col>
        </QueueAnim>
      </OverPack>
    </div>
  )
}

export default DrillCol