import React from 'react'
import { Row, Col } from 'antd'
import Logo from '@/assets/images/logo.png'
import Stock01 from '@/assets/images/product/01stock.png'
import Stock02 from '@/assets/images/product/02stock.png'
import Bfs from '@/assets/images/product/03bfs.png'
import Coin from '@/assets/images/product/04coin.png'
import Watere from '@/assets/images/product/05watere.png'
import Xinli from '@/assets/images/product/06xinli.png'
import Ggr from '@/assets/images/product/07ggr.png'
import Htf from '@/assets/images/product/08htf.png'
import Capital from '@/assets/images/product/09capital.png'
import Zhongrui from '@/assets/images/product/10zhongrui.png'


const Partner = () => {

  return (
    <div className='home-page partner'>
      <Row className='partner-top'>
        <Col className='partner-top-host'>
          <span className='partner-text host-title'>主办单位</span>
          <img src={Logo} alt=""/>
        </Col>
        <Col className='partner-top-rest'>
          <span className='partner-text rest-title'>合作机构</span>
          <img className='stock01' src={Stock01} alt=""/>
          <div className='partner-line'></div>
          <img className='stock02' src={Stock02} alt=""/>
        </Col>
      </Row>
      <Row className='partner-bottom'>
        <Col className='partner-text partner-bottom-title'>
          合作金融机构
        </Col>
        <Col>
          <Row className='partner-bottom-block'>
            <img src={Bfs} alt=""/>
            <div className='partner-line'></div>
            <img src={Coin} alt=""/>
            <div className='partner-line'></div>
            <img src={Watere} alt=""/>
            <div className='partner-line'></div>
            <img src={Xinli} alt=""/>
          </Row>
          <Row className='partner-bottom-block'>
            <img src={Ggr} alt=""/>
            <div className='partner-line'></div>
            <img src={Htf} alt=""/>
            <div className='partner-line'></div>
            <img src={Capital} alt=""/>
            <div className='partner-line'></div>
            <img src={Zhongrui} alt=""/>
          </Row>
        </Col>
      </Row>
    </div>
  )
}

export default Partner