import React from 'react'
import {Row, Col} from 'antd'
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack'
import QueueAnim from 'rc-queue-anim'
import TweenOne from 'rc-tween-one'
import Tutor01 from '@/assets/images/product/wealth/tutor01.png'
import Tutor02 from '@/assets/images/product/wealth/tutor02.png'
import Tutor03 from '@/assets/images/product/wealth/tutor03.png'
import Tutor04 from '@/assets/images/product/wealth/tutor04.png'
import Tutor05 from '@/assets/images/product/wealth/tutor05.png'
import Tutor06 from '@/assets/images/product/wealth/tutor06.png'
import Tutor07 from '@/assets/images/product/wealth/tutor07.png'
import Tutor08 from '@/assets/images/product/wealth/tutor08.png'
import IconRisk from '@/assets/images/product/wealth/icon-risk.png'
import IconTheme from '@/assets/images/product/wealth/icon-theme.png'
import IconCapital from '@/assets/images/product/wealth/icon-capital.png'
import Project01 from "@/assets/images/product/project01.png";
import {getAnim} from "@/utils";


const Wealth: React.FC = () => {

  const imgAnim: any = getAnim()

  return (
    <main className='warp-wealth header-top'>
      <div className='warp-wealth-bg flex-center'>
        财赋+以在资本运作、企业上市辅导和财务管理领域内丰富的服务经验，<br/>
        为30人内财务团队的企业搭建以数据为核心的经营管理体系， <br/>
        大幅度提升经营决策水平。
      </div>
      <div className='warp-wealth-bright home-page'>
        <div className='title m-b40'>课程亮点</div>
        <OverPack playScale={0.3}>
          <QueueAnim
            type="bottom"
            key="block"
            leaveReverse
            component={Row}
            gutter={[20, 45]}
          >
            <Col md={8} key='1'>
              <div className='bright-col bright-bg01'>
                <div className='bright-col-title'>
                  内训课的 <br />
                  听课体验
                </div>
                <div className='bright-col-desc'>
                  将听课学员控制在40人以内；根据到场学员背景灵活定制课程内容；预留充足时间便于学员与讲师深度交流。
                </div>
              </div>
            </Col>
            <Col md={8}  key='2'>
              <div className='bright-col bright-bg02'>
                <div className='bright-col-title'>
                  浓厚的 <br />
                  学习氛围
                </div>
                <div className='bright-col-desc'>
                  丰富的课程互动内容设计，便于学员吸收课程知识；专业的学习群运营，搭建财税知识沟通讨论的平台。
                </div>
              </div>
            </Col>
            <Col md={8}  key='3'>
              <div className='bright-col bright-bg03'>
                <div className='bright-col-title'>
                  与众不同的 <br />
                  活动感受
                </div>
                <div className='bright-col-desc'>
                  可用会员积分兑换北交所参访、宫玉振老师分享会等高品质的参访及研讨活动。
                </div>
              </div>
            </Col>
            <Col md={8} key='4'>
              <div className='bright-col bright-bg04'>
                <div className='bright-col-title'>
                  专业的 <br />
                  答疑服务
                </div>
                <div className='bright-col-desc'>
                  “四大”背景财税专家提供答疑服务，解决日常工作遇到的难题。
                </div>
              </div>
            </Col>
            <Col md={8} key='5'>
              <div className='bright-col bright-bg05'>
                <div className='bright-col-title'>
                  贴心的 <br />
                  职称考试服务
                </div>
                <div className='bright-col-desc'>
                  提供注册会计师、中级会计师等财务相关职称考试的教培服务，助力个体职业发展。
                </div>
              </div>
            </Col>
            <Col md={8} key='6'>
              <div className='bright-col bright-bg06'>
                <div className='bright-col-title'>
                  便捷的 <br />
                  继续教育服务
                </div>
                <div className='bright-col-desc'>
                  提供会计人员继续教育线上培训服务，满足财务人员的继续教育需求。
                </div>
              </div>
            </Col>
          </QueueAnim>
        </OverPack>
      </div>
      <div className='warp-wealth-expert'>
        <div className='home-page'>
          <div className='title'>专家团队</div>
          <OverPack playScale={0.3}>
            <QueueAnim
              type="bottom"
              key="block"
              leaveReverse
              component={Row}
              className='wrap-wealth-items'
            >
              <Col md={6} key='1'>
                <div className='wealth-item'>
                  <div className='wealth-item-circle flex-center'>
                    <img src={Tutor01}/>
                  </div>
                  <div className='wealth-item-title'>夏倩</div>
                  <div className='wealth-item-desc'>
                    管理学博士、中国注册会计师、高级会计师、中国注册评估师、大型金融投资集团财务总监、500强集团财务负责人。
                  </div>
                </div>
              </Col>
              <Col md={6} key='2'>
                <div className='wealth-item'>
                  <div className='wealth-item-circle flex-center'>
                    <img src={Tutor02}/>
                  </div>
                  <div className='wealth-item-title'>徐京</div>
                  <div className='wealth-item-desc'>
                    北京大学、清华大学EMBA、MBA特聘讲师；中国企业实战派投融资及股权设计专家、董事会教练。
                  </div>
                </div>
              </Col>
              <Col md={6} key='3'>
                <div className='wealth-item'>
                  <div className='wealth-item-circle flex-center'>
                    <img src={Tutor03}/>
                  </div>
                  <div className='wealth-item-title'>陈立文</div>
                  <div className='wealth-item-desc'>
                    国内资深财税实务咨询与辅导专家；拥有注册会计师、高级会计师、税务师等任职资格。
                  </div>
                </div>
              </Col>
              <Col md={6} key='4'>
                <div className='wealth-item'>
                  <div className='wealth-item-circle flex-center'>
                    <img src={Tutor04}/>
                  </div>
                  <div className='wealth-item-title'>肖舒月</div>
                  <div className='wealth-item-desc'>
                    中央财经大学工商管理硕士；高级会计/CMA/IPA/IFA/CIA；港股IPO上市公司CFO。
                  </div>
                </div>
              </Col>
              <Col md={6} key='5'>
                <div className='wealth-item'>
                  <div className='wealth-item-circle flex-center'>
                    <img src={Tutor05}/>
                  </div>
                  <div className='wealth-item-title'>李可书</div>
                  <div className='wealth-item-desc'>
                    法学博士、经济学博士后、资深律师；股权激励“三步法”理论创始人；曾辅导过多家企业成功上市、并购重组、公司治理及实施股权激励项目。
                  </div>
                </div>
              </Col>
              <Col md={6} key='6'>
                <div className='wealth-item'>
                  <div className='wealth-item-circle flex-center'>
                    <img src={Tutor06}/>
                  </div>
                  <div className='wealth-item-title'>李越</div>
                  <div className='wealth-item-desc'>
                    曾任东方花旗证券有限公司投资银行部董事；曾任全国中小企业股份转让系统公司监管经历，并购重组审查员，负责企业融资并购审查、合规运行及交易监管。
                  </div>
                </div>
              </Col>
              <Col md={6} key='7'>
                <div className='wealth-item'>
                  <div className='wealth-item-circle flex-center'>
                    <img src={Tutor07}/>
                  </div>
                  <div className='wealth-item-title'>林墨岩</div>
                  <div className='wealth-item-desc'>
                    中国注册会计师、法律职业资格；四大会计师事务所财务咨询及审计经验、风险投资机构风控经验。
                  </div>
                </div>
              </Col>
              <Col md={6} key='8'>
                <div className='wealth-item'>
                  <div className='wealth-item-circle flex-center'>
                    <img src={Tutor08}/>
                  </div>
                  <div className='wealth-item-title'>徐海越</div>
                  <div className='wealth-item-desc'>
                    国家专精特新资讯专家；数字化转型与人工智能（DTAI）产业人才基地建设中心运营部主、数字化转型专家委员会副秘书长。
                  </div>
                </div>
              </Col>
            </QueueAnim>
          </OverPack>
        </div>
      </div>
      <div className='warp-wealth-system home-page'>
        <div className='title'>课程体系</div>
        <div className='m-t42'>
          <div className='warp-wealth-system-header'>
            <img src={IconTheme} alt="" />
            <div className='system-header-title'>经营主题</div>
          </div>
          <div className='warp-wealth-system-grey'>
            预算管理
          </div>
          <OverPack playScale={0.2} component={Row}>
            <QueueAnim
              key="text"
              type="left"
              leaveReverse
              ease={['easeOutQuad', 'easeInQuad']}
              component={Col}
              md={12}
            >
              <QueueAnim
                key="ul"
                type="left"
                ease="easeOutQuad"
              >
                <div className='wealth-server'>
                  <div className='wealth-server-title m-t20'>咨询服务</div>
                  <div className='wealth-server-box m-t20'>
                    <div className='wealth-server-box-left flex-center'>
                      预算管理 <br />
                      体系设计
                    </div>
                    <div className='wealth-server-box-line'></div>
                    <div className='wealth-server-box-right'>
                      通过对接公司年度业务计划，确定公司年度预算目标，帮助公司设计符合公司业务模式及战略规划的预算模型。
                    </div>
                  </div>
                </div>
              </QueueAnim>
            </QueueAnim>
            <TweenOne
              key="right"
              animation={imgAnim}
              resetStyle
              component={Col}
              md={12}
            >
              <div className='wealth-server'>
                <div className='wealth-server-title m-t20'>培训服务</div>
                <div className='wealth-server-box m-t20'>
                  <div className='wealth-server-box-left wealth-server-box-active flex-center'>
                    战略预算 <br />
                    落地工作坊
                  </div>
                  <div className='wealth-server-box-line'></div>
                  <div className='wealth-server-box-right'>
                    通过公司经营管理层共创的形式，根据公司的年度经营计划书，借助预算管理工具与方法论，输出公司的战略预算执行方案。
                  </div>
                </div>
              </div>
            </TweenOne>
          </OverPack>
          <div className='warp-wealth-system-grey m-t30'>
            经营分析
          </div>
          <OverPack playScale={0.2} component={Row}>
            <QueueAnim
              key="text"
              type="left"
              leaveReverse
              ease={['easeOutQuad', 'easeInQuad']}
              component={Col}
              md={12}
            >
              <QueueAnim
                key="ul"
                type="left"
                ease="easeOutQuad"
              >
                <div className='wealth-server'>
                  <div className='wealth-server-title m-t20'>咨询服务</div>
                  <div className='wealth-server-box m-t20'>
                    <div className='wealth-server-box-left flex-center'>
                      经营指标 <br />
                      体系设计
                    </div>
                    <div className='wealth-server-box-line'></div>
                    <div className='wealth-server-box-right'>
                      通过对接公司的年度业务计划与预算目标，帮助公司设计符合公司业务模式及战略目标的经营指标体系，搭建经营看板。
                    </div>
                  </div>
                </div>
                <div className='wealth-server'>
                  <div className='wealth-server-box m-t30'>
                    <div className='wealth-server-box-left flex-center'>
                      核算手册 <br />
                      优化
                    </div>
                    <div className='wealth-server-box-line'></div>
                    <div className='wealth-server-box-right'>
                      根据公司业务定制化编写会计政策及操作指引，为财务人员提供核算指引，提升核算效率及标准化水平。
                    </div>
                  </div>
                </div>
                <div className='wealth-server'>
                  <div className='wealth-server-box m-t30'>
                    <div className='wealth-server-box-left flex-center'>
                      会计科目 <br />
                      体系标准化
                    </div>
                    <div className='wealth-server-box-line'></div>
                    <div className='wealth-server-box-right'>
                      根据公司的经营分析及核算需求，结合会计准则的规定，定制化设计公司的会计科目体系，包括各级科目明细及辅助核算维度。
                    </div>
                  </div>
                </div>
              </QueueAnim>
            </QueueAnim>
            <TweenOne
              key="right"
              animation={imgAnim}
              resetStyle
              component={Col}
              md={12}
            >
              <div className='wealth-server'>
                <div className='wealth-server-title m-t20'>培训服务</div>
                <div className='wealth-server-box m-t20'>
                  <div className='wealth-server-box-left wealth-server-box-active flex-center'>
                    经营分析 <br />
                    人才训练营
                  </div>
                  <div className='wealth-server-box-line'></div>
                  <div className='wealth-server-box-right'>
                    通过系统性知识、实践方法及场景化案例分析，帮助学员充分掌握经营分析各工作场景下的实战能力，为企业培养经营分析人才。
                  </div>
                </div>
              </div>
              <div className='wealth-server'>
                <div className='wealth-server-box m-t30'>
                  <div className='wealth-server-box-left wealth-server-box-active flex-center'>
                    降本增效 <br />
                    实战工作坊
                  </div>
                  <div className='wealth-server-box-line'></div>
                  <div className='wealth-server-box-right'>
                    通过经营管理层共创的形式，将降本增效目标进行拆解，借助成本、人工及财务管理分析工具与方法论，输出降本增效执行方案。
                  </div>
                </div>
              </div>
              <div className='wealth-server'>
                <div className='wealth-server-box m-t30'>
                  <div className='wealth-server-box-left wealth-server-box-active flex-center'>
                    经营管理者 <br />
                    非财必修课
                  </div>
                  <div className='wealth-server-box-line'></div>
                  <div className='wealth-server-box-right'>
                    通过财务思维以及各岗位必备财务知识的培训，帮助各层级经营人员掌握经营分析及财务的必要知识，保障经营分析体系的运转。
                  </div>
                </div>
              </div>
            </TweenOne>
          </OverPack>
        </div>
        <div className='m-t70'>
          <div className='warp-wealth-system-header'>
            <img src={IconCapital} alt="" />
            <div className='system-header-title'>资本运作主题</div>
          </div>
          <div className='warp-wealth-system-grey'>
            投资
          </div>
          <OverPack playScale={0.2} component={Row}>
            <QueueAnim
              key="text"
              type="left"
              leaveReverse
              ease={['easeOutQuad', 'easeInQuad']}
              component={Col}
              md={12}
            >
              <QueueAnim
                key="ul"
                type="left"
                ease="easeOutQuad"
              >
                <div className='wealth-server'>
                  <div className='wealth-server-title m-t20'>咨询服务</div>
                  <div className='wealth-server-box m-t20'>
                    <div className='wealth-server-box-left flex-center'>
                      尽职调查
                    </div>
                    <div className='wealth-server-box-line'></div>
                    <div className='wealth-server-box-right'>
                      对企业拟投资标的进行尽职调查，发现标的公司在经营、财务、法律等领域的风险，评估风险的影响，为企业投资决策做参考。
                    </div>
                  </div>
                </div>
                <div className='wealth-server'>
                  <div className='wealth-server-box m-t30'>
                    <div className='wealth-server-box-left flex-center'>
                      投资顾问
                    </div>
                    <div className='wealth-server-box-line'></div>
                    <div className='wealth-server-box-right'>
                      根据企业的投资意向，进行投资规划与建议，利用行业资源帮助企业寻找合适的投资标的，辅助企业进行投资谈判，帮助企业争取最大利益。
                    </div>
                  </div>
                </div>
              </QueueAnim>
            </QueueAnim>
            <TweenOne
              key="right"
              animation={imgAnim}
              resetStyle
              component={Col}
              md={12}
            >
              <div className='wealth-server'>
                <div className='wealth-server-title m-t20'>培训服务</div>
                <div className='wealth-server-box m-t20'>
                  <div className='wealth-server-box-left wealth-server-box-active flex-center'>
                    投资实战 <br />
                    训练营
                  </div>
                  <div className='wealth-server-box-line'></div>
                  <div className='wealth-server-box-right'>
                    通过投资规划、尽职调查、法律风险防范等领域的内容，帮助企业中负责投资业务的人员快速掌握投资业务所必备的技能。
                  </div>
                </div>
              </div>
            </TweenOne>
          </OverPack>
          <div className='warp-wealth-system-grey m-t30'>
            融资
          </div>
          <OverPack playScale={0.2} component={Row}>
            <QueueAnim
              key="text"
              type="left"
              leaveReverse
              ease={['easeOutQuad', 'easeInQuad']}
              component={Col}
              md={12}
            >
              <QueueAnim
                key="ul"
                type="left"
                ease="easeOutQuad"
              >
                <div className='wealth-server'>
                  <div className='wealth-server-title m-t20'>咨询服务</div>
                  <div className='wealth-server-box m-t20'>
                    <div className='wealth-server-box-left flex-center'>
                      融资顾问
                    </div>
                    <div className='wealth-server-box-line'></div>
                    <div className='wealth-server-box-right'>
                      根据企业的实际经营情况，提出适合企业发展的融资方案，帮助企业对接融资渠道，参与企业的融资谈判，为企业争取最大利益。
                    </div>
                  </div>
                </div>
                <div className='wealth-server'>
                  <div className='wealth-server-box m-t30'>
                    <div className='wealth-server-box-left flex-center'>
                      股权架构与 <br />
                      股权激励设计
                    </div>
                    <div className='wealth-server-box-line'></div>
                    <div className='wealth-server-box-right'>
                      根据企业的经营情况及架构、股权激励需求，综合考虑法律要求及公司长远发展，设计最符合公司需求的股份架构或股权激励方案。
                    </div>
                  </div>
                </div>
              </QueueAnim>
            </QueueAnim>
            <TweenOne
              key="right"
              animation={imgAnim}
              resetStyle
              component={Col}
              md={12}
            >
              <div className='wealth-server'>
                <div className='wealth-server-title m-t20'>培训服务</div>
                <div className='wealth-server-box m-t20'>
                  <div className='wealth-server-box-left wealth-server-box-active flex-center'>
                    融资选择 <br />
                    实战训练营
                  </div>
                  <div className='wealth-server-box-line'></div>
                  <div className='wealth-server-box-right'>
                    通过融资途径、资本结构、融资时机选择等领域的内容，帮助企业内融资业务人员快速掌握融资业务所必备的技能，找到合适的融资路径。
                  </div>
                </div>
              </div>
            </TweenOne>
          </OverPack>
          <div className='warp-wealth-system-grey m-t30'>
            IPO上市
          </div>
          <OverPack playScale={0.2} component={Row}>
            <QueueAnim
              key="text"
              type="left"
              leaveReverse
              ease={['easeOutQuad', 'easeInQuad']}
              component={Col}
              md={12}
            >
              <QueueAnim
                key="ul"
                type="left"
                ease="easeOutQuad"
              >
                <div className='wealth-server'>
                  <div className='wealth-server-title m-t20'>咨询服务</div>
                  <div className='wealth-server-box m-t20'>
                    <div className='wealth-server-box-left flex-center'>
                      公司治理优化
                    </div>
                    <div className='wealth-server-box-line'></div>
                    <div className='wealth-server-box-right'>
                      依照上市要求、帮助企业合理设置董事会、监视会及管理层，完善公司章程及规章制度，帮助企业节约中介机构入场后的督导时间及费用。
                    </div>
                  </div>
                </div>
                <div className='wealth-server'>
                  <div className='wealth-server-box m-t30'>
                    <div className='wealth-server-box-left flex-center'>
                      财务健康检查
                    </div>
                    <div className='wealth-server-box-line'></div>
                    <div className='wealth-server-box-right'>
                      依照上市要求，对企业的财务合规情况进行健康检查，提出整改建议，在中介机构进场前完成财务情况摸底及整改。
                    </div>
                  </div>
                </div>
                <div className='wealth-server'>
                  <div className='wealth-server-box m-t30'>
                    <div className='wealth-server-box-left flex-center'>
                      上市辅导
                    </div>
                    <div className='wealth-server-box-line'></div>
                    <div className='wealth-server-box-right'>
                      由原交易所审查专家亲自为企业进行上市路径规划，企业合规检查及上市进程建议。参与企业和中介机构的谈判，为企业争取最大利益。
                    </div>
                  </div>
                </div>
              </QueueAnim>
            </QueueAnim>
            <TweenOne
              key="right"
              animation={imgAnim}
              resetStyle
              component={Col}
              md={12}
            >
              <div className='wealth-server'>
                <div className='wealth-server-title m-t20'>培训服务</div>
                <div className='wealth-server-box m-t20'>
                  <div className='wealth-server-box-left wealth-server-box-active flex-center'>
                    IPO <br />
                    全流程模拟 <br />
                    训练营
                  </div>
                  <div className='wealth-server-box-line'></div>
                  <div className='wealth-server-box-right'>
                    从上市路径规划、公司治理提升到财务审查要点，体系化梳理上市全流程，帮助企业更有针对性地完成IPO上市准备工作。
                  </div>
                </div>
              </div>
            </TweenOne>
          </OverPack>
        </div>
        <div className='m-t70'>
          <div className='warp-wealth-system-header'>
            <img src={IconRisk} alt="" />
            <div className='system-header-title'>风险管理、内部控制与税务管理主题</div>
          </div>
          <div className='warp-wealth-system-grey'>
            风险管理与内部控制
          </div>
          <OverPack playScale={0.2} component={Row}>
            <QueueAnim
              key="text"
              type="left"
              leaveReverse
              ease={['easeOutQuad', 'easeInQuad']}
              component={Col}
              md={12}
            >
              <QueueAnim
                key="ul"
                type="left"
                ease="easeOutQuad"
              >
                <div className='wealth-server'>
                  <div className='wealth-server-title m-t20'>咨询服务</div>
                  <div className='wealth-server-box m-t20'>
                    <div className='wealth-server-box-left flex-center'>
                      内控流程 <br />
                      设计与优化
                    </div>
                    <div className='wealth-server-box-line'></div>
                    <div className='wealth-server-box-right'>
                      梳理企业的各项业务流程，识别业务的风险点与控制点，设计内控流程，撰写各业务流程的内控手册，防范经营风险。
                    </div>
                  </div>
                </div>
              </QueueAnim>
            </QueueAnim>
            <TweenOne
              key="right"
              animation={imgAnim}
              resetStyle
              component={Col}
              md={12}
            >
              <div className='wealth-server'>
                <div className='wealth-server-title m-t20'>培训服务</div>
                <div className='wealth-server-box m-t20'>
                  <div className='wealth-server-box-left wealth-server-box-active flex-center'>
                    风险管理与 <br />
                    内控实战工 <br />
                    作坊
                  </div>
                  <div className='wealth-server-box-line'></div>
                  <div className='wealth-server-box-right'>
                    通过全面风险管理、内控体系建设、风险预警指标体系搭建、内控流程设计等内容，帮助企业培养风险管理与内控领域的专业人才。
                  </div>
                </div>
              </div>
            </TweenOne>
          </OverPack>
          <div className='warp-wealth-system-grey m-t30'>
            税务管理
          </div>
          <OverPack playScale={0.2} component={Row}>
            <QueueAnim
              key="text"
              type="left"
              leaveReverse
              ease={['easeOutQuad', 'easeInQuad']}
              component={Col}
              md={12}
            >
              <QueueAnim
                key="ul"
                type="left"
                ease="easeOutQuad"
              >
                <div className='wealth-server'>
                  <div className='wealth-server-title m-t20'>咨询服务</div>
                  <div className='wealth-server-box m-t20'>
                    <div className='wealth-server-box-left flex-center'>
                      税务健康检查
                    </div>
                    <div className='wealth-server-box-line'></div>
                    <div className='wealth-server-box-right'>
                      对企业说设计全税种进行风险排查，识别税务风险，并提出整改建议，帮助企业健全税务流程，加强重点环节的把控，提升税务合规性。
                    </div>
                  </div>
                </div>
                <div className='wealth-server'>
                  <div className='wealth-server-box m-t30'>
                    <div className='wealth-server-box-left flex-center'>
                      常年税务顾问
                    </div>
                    <div className='wealth-server-box-line'></div>
                    <div className='wealth-server-box-right'>
                      向企业提供日常涉税业务咨询与答疑服务，进行最新政策的解读，评估政策对企业的影响，指导订立合同涉税条款，防范税务风险。
                    </div>
                  </div>
                </div>
              </QueueAnim>
            </QueueAnim>
            <TweenOne
              key="right"
              animation={imgAnim}
              resetStyle
              component={Col}
              md={12}
            >
              <div className='wealth-server'>
                <div className='wealth-server-title m-t20'>培训服务</div>
                <div className='wealth-server-box m-t20'>
                  <div className='wealth-server-box-left wealth-server-box-active flex-center'>
                    税务规划与 <br />
                    分析训练营
                  </div>
                  <div className='wealth-server-box-line'></div>
                  <div className='wealth-server-box-right'>
                    针对企业投资并购、股权架构变革、重组等重大事项，进行税务专题研讨，生成税务规划方案。
                  </div>
                </div>
              </div>
              <div className='wealth-server'>
                <div className='wealth-server-box m-t30'>
                  <div className='wealth-server-box-left wealth-server-box-active flex-center'>
                    税务风险 <br />
                    应对训练营
                  </div>
                  <div className='wealth-server-box-line'></div>
                  <div className='wealth-server-box-right'>
                    通过税务稽查应对、税务政策梳理、涉税实操处理等内容，提升税务人才的专业能力，提高企业对税务风险的应对水平，避免税务风险。
                  </div>
                </div>
              </div>
            </TweenOne>
          </OverPack>
        </div>
      </div>
      <div className='warp-wealth-member'>
        <OverPack playScale={0.3}>
          <QueueAnim
            type="bottom"
            key="block"
            leaveReverse
            className='home-page'
          >
            <div className='title' key='title'>会员权益</div>
            <div className='warp-wealth-member-table m-t35' key='member'>
              <Row className='warp-wealth-member-table-row p-b32px'>
                <Col md={6} className='member-col-title m-t20'>会员服务价格(含税)</Col>
                <Col md={6}>
                  <div className='member-col-title-strong'>财智精进卡</div>
                  <div className='member-col-title-red'>¥ 29,800<span> /年</span></div>
                </Col>
                <Col md={6}>
                  <div className='member-col-title-strong'>财智赋能卡</div>
                  <div className='member-col-title-red'>¥ 59,800<span> /年</span></div>
                </Col>
                <Col md={6}>
                  <div className='member-col-title-strong'>财智领航卡</div>
                  <div className='member-col-title-red'>¥ 99,800<span> /年</span></div>
                </Col>
              </Row>
              <Row className='warp-wealth-member-table-row row-height'>
                <Col md={6} className='member-col-title'>精品小班财务公开课</Col>
                <Col md={6}>
                  <div className='member-col-low flex-center'>25人次*</div>
                </Col>
                <Col md={6}>
                  <div className='member-col-centre flex-center'>25人次*</div>
                </Col>
                <Col md={6}>
                  <div className='member-col-tall flex-center'>25人次*</div>
                </Col>
              </Row>
              <Row className='warp-wealth-member-table-row row-height'>
                <Col md={6} className='member-col-title'> “四大”财税专家答疑</Col>
                <Col md={6}>
                  <div className='member-col-low flex-center'>会员专享</div>
                </Col>
                <Col md={6}>
                  <div className='member-col-centre flex-center'>会员专享</div>
                </Col>
                <Col md={6}>
                  <div className='member-col-tall flex-center'>会员专享</div>
                </Col>
              </Row>
              <Row className='warp-wealth-member-table-row row-height'>
                <Col md={6} className='member-col-title'>高价值会员积分</Col>
                <Col md={6}>
                  <div className='member-col-low flex-center'>2,980分</div>
                </Col>
                <Col md={6}>
                  <div className='member-col-centre flex-center'>5,980分</div>
                </Col>
                <Col md={6}>
                  <div className='member-col-tall flex-center'>9,980分</div>
                </Col>
              </Row>
              <Row className='warp-wealth-member-table-row row-height'>
                <Col md={6} className='member-col-title'>会员专属学习群</Col>
                <Col md={6}>
                  <div className='member-col-low flex-center'>会员专享</div>
                </Col>
                <Col md={6}>
                  <div className='member-col-centre flex-center'>会员专享</div>
                </Col>
                <Col md={6}>
                  <div className='member-col-tall flex-center'>会员专享</div>
                </Col>
              </Row>
              <Row className='warp-wealth-member-table-row row-height'>
                <Col md={6} className='member-col-title'>通用能力公开课</Col>
                <Col md={6}>
                  <div className='member-col-low flex-center'>1 人次</div>
                </Col>
                <Col md={6}>
                  <div className='member-col-centre flex-center'>2 人次</div>
                </Col>
                <Col md={6}>
                  <div className='member-col-tall flex-center'>3 人次</div>
                </Col>
              </Row>
              <Row className='warp-wealth-member-table-row row-height'>
                <Col md={6} className='member-col-title'>职称考试培训</Col>
                <Col md={6}>
                  <div className='member-col-low flex-center'>任选 3 科考试</div>
                </Col>
                <Col md={6}>
                  <div className='member-col-centre flex-center'>任选 6 科考试</div>
                </Col>
                <Col md={6}>
                  <div className='member-col-tall flex-center'>任选 9 科考试</div>
                </Col>
              </Row>
              <Row className='warp-wealth-member-table-row row-height'>
                <Col md={6} className='member-col-title'>继续教育</Col>
                <Col md={6}>
                  <div className='member-col-low flex-center'>10 人次</div>
                </Col>
                <Col md={6}>
                  <div className='member-col-centre flex-center'>20 人次</div>
                </Col>
                <Col md={6}>
                  <div className='member-col-tall flex-center'>20 人次</div>
                </Col>
              </Row>
              <Row className='warp-wealth-member-table-row row-height'>
                <Col md={6} className='member-col-title'>财务健康检查</Col>
                <Col md={6}>
                  <div className='member-col-low member-col-not flex-center'>无</div>
                </Col>
                <Col md={6}>
                  <div className='member-col-centre member-col-not flex-center'>无</div>
                </Col>
                <Col md={6}>
                  <div className='member-col-tall flex-center'>1 家公司</div>
                </Col>
              </Row>
              <Row className='warp-wealth-member-table-row row-height' style={{borderBottom: 'none'}}>
                <Col md={6} className='member-col-title'>经营提升实战工作坊</Col>
                <Col md={6}>
                  <div className='member-col-low member-col-not flex-center'>无</div>
                </Col>
                <Col md={6}>
                  <div className='member-col-centre flex-center'>1 天课程</div>
                </Col>
                <Col md={6}>
                  <div className='member-col-tall flex-center'>1 天课程</div>
                </Col>
              </Row>
            </div>
          </QueueAnim>
        </OverPack>
      </div>
    </main>
  )
}

export default Wealth